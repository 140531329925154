const state = {
  AdminData: {
    '_id': '',
    'name': '',
    'email': '',
    'timestamp': ''
  }
}

export default state
