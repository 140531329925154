import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import Deposits from './Tabs/deposits'
import Withdrawals from './Tabs/withdrawals'
import Insights from './Tabs/insights'
import * as Page from './styles'

const Transactions = ({ transactions, inputs, handleSelected }) => {
  // const { name, profilePicture, email, analytics } = user
  return (
    <Page.Details style={{ margin: '0 auto' }}>

      {/*
          ==========================================================================
          Userprofile-Content
          ==========================================================================
        */}
      <Page.ContainerAlt style={{ alignItems: 'flex-start' }}>
        <Tabs
          defaultActiveKey='deposits'
          onSelect={link => handleSelected(link)}
        >
          <Tab eventKey='deposits' title='Deposits'>
            <Deposits data={transactions.deposits} inputs={inputs} />
          </Tab>
          <Tab eventKey='withdrawals' title='Withdrawals'>
            <Withdrawals data={transactions.withdrawals} inputs={inputs} />
          </Tab>
          <Tab eventKey='insights' title='Insights '>
            <Insights data={transactions.insights} />
          </Tab>

        </Tabs>
         </Page.ContainerAlt>
    </Page.Details>
  )
}

export default withRouter(Transactions)
