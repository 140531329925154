import styled from 'styled-components'

export const Container = styled.div`
    background-color: ${props => props.backgroundColor ? props.backgroundColor : props.theme.white};
    box-shadow: ${props => props.boxShadow};
    width: ${props => props.width};
    height: ${props => props.height};
    color: ${props => props.color ? props.color : props.theme.white};
    border-radius:${props => props.borderRadius ? props.borderRadius : '4px'};
    display: flex;
    flex-wrap:  ${props => props.flexWrap ? props.flexWrap : 'wrap'};
    flex-direction: ${props => props.direction ? props.direction : 'column'};
    justify-content:${props => props.justify};
    align-items:${props => props.align};
    align-content:${props => props.content};
    margin: ${props => props.margin};
    padding:${props => props.padding};
`
Container.defaultProps ={
  width: '195px',
  height:'140px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1);'
}

export const FixContainer = styled.div`
display: flex;
justify-content: flex-start;
align-items:center;
background:${props => props.theme.transparent};
width: 100%;
height: 100% ;
flex-wrap: wrap;
`

export const FixContainerAlt = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items:flex-start;
background:${props => props.theme.transparent};
width: 100%;
padding: 8px 10px;
max-height:120px;
flex-wrap: nowrap;
border:none;
`
export const MainWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items:center;
flex-direction:column;
`

export const Wrapper = styled(MainWrapper)`
flex-direction:row;
`
export const Line = styled.hr`
border:1px solid ${props => props.theme.background};
margin:0;
`
export const SpanCircle = styled.span`
border-radius: 10px;
height:20px;
width:20px;
`
export const UserDetailsLine = styled.hr`
border: .5px solid ${props => props.borderColor ? props.borderColor : 'rgba(128, 128, 128, 0.3)'};
width:80%
margin:10px auto 20px 0;
`
export const percentageSpan = styled.span`
display:flex;
justify-content:center;
align-items:center;
text-align:center;
font-size: 14px;
line-height: 18px;
border-radius: 50%;
height:40px;
width:40px;
color:${props => props.alt ? props.theme.red : props.theme.green};
background-color:${props => props.alt ? props.theme.faintRed : props.theme.faintGreen};
`