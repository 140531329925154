import React from 'react'
import { Col } from 'react-bootstrap'
import BarChart from '../../Charts/BarChart'
import LineChart from '../../Charts/LineChart'

import { SelectAlt } from '../../Forms'
const Overview = ({ inputs, state, handleShowChart }) => {
  return (
    <div id='overview'>
      <Col xs={12} md={12} lg={12}>
        <div className='details-card'>
          <SelectAlt
            margin='0 0 24px'
            changed={handleShowChart}
          />

          <div className='body'>
            <div className='chart' id='chart'>
              {state.showBarChart ? <BarChart data={inputs.barData} /> : <LineChart data={inputs.lineData} />}
            </div>
          </div>
        </div>
      </Col>
    </div>
  )
}

export default Overview
