import React from 'react'
import { FixContainerAlt, Wrapper, MainWrapper, SpanCircle, UserDetailsLine } from './styles'
import { CardText, CardSubText, Label, SubHeader } from '../../theme/style/typeface'

/**
 * this the component that holds the profile picture, username and rating for the user Card
 */
export const CardInformation = ({ text, subText, textAlt, subTextAlt, altColor, textMargin, subTextMargin, ...props }) => {
  return (
    <FixContainerAlt>
      <CardSubText
        alt={subTextAlt}
        margin={subTextMargin}
        altColor={altColor}
        {...props}
      >
        {subText}
      </CardSubText>
      <CardText
        alt={textAlt}
        margin={textMargin}
        altColor={altColor}
        {...props}
      >
        {text}
      </CardText>
    </FixContainerAlt>
  )
}
export const Details = ({ color, label, number, ...props }) => (
  <Wrapper>
    <SpanCircle style={{ background: color }} />
    <div style={{ margin: '0 10px' }}>
      <Label>
        {label || 'Compulsory deposits' }
      </Label>
      <CardText>
          &#8358; {number || '600,000'}
      </CardText>
    </div>
  </Wrapper>
)
export const UserDetails = ({ label, info, ...props }) => (
  <MainWrapper style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', margin: '0 70px 0 0' }}>
    <SubHeader alt='true' margin='0 0 8px'>
      {label || 'Detail' }
    </SubHeader>
    <SubHeader textTransform='inherit'>
      {info || 'nil'}
    </SubHeader>
  </MainWrapper>
)

export const UserDetailsRow = ({
  labelOne,
  labelTwo,
  labelThree,
  infoOne,
  infoTwo,
  infoThree,
  hideColThree,
  hideColTwo,
  hideline }) => {
  let one
  let two
  one = (
    <UserDetails label={labelTwo}
      info={infoTwo} />
  )
  two = (
    <UserDetails label={labelThree}
      info={infoThree} />
  )
  if (hideColTwo) {
    one = null
  }
  if (hideColThree) {
    two = null
  }
  return (
    <MainWrapper style={{ width: '750px', alignItems: 'flex-start', justifyContent:'space-evenly' }} >
      <Wrapper style={{ width: '100%', alignItems: 'center', justifyContent:'flex-start' }}>
        <UserDetails
          label={labelOne}
          info={infoOne} />
        {one}
        {two}
      </Wrapper>
      {hideline ? null : <UserDetailsLine /> }
    </MainWrapper>
  )
}
