import Insights from '../../Pages/Insight'
import { connect } from 'react-redux'
import { handleFetchAllGeneralInsights, handleFetchAllHousesInsights, handleFetchAllLocationInsights, handleFetchAllFinanceInsights } from '../../Store/Actions'

/**
 * here we handle passing redux to our component and export
 */
const mapStateToProps = state => ({
  insights: state.Admin.insights
})

const mapDispatchToProps = dispatch => ({
  handleFetchAllGeneralInsights () {
    return dispatch(handleFetchAllGeneralInsights())
  },
  handleFetchAllHousesInsights () {
    return dispatch(handleFetchAllHousesInsights())
  },
  handleFetchAllLocationInsights () {
    return dispatch(handleFetchAllLocationInsights())
  },
  handleFetchAllFinanceInsights () {
    return dispatch(handleFetchAllFinanceInsights())
  },
 
})

export default connect(mapStateToProps, mapDispatchToProps)(Insights)
