import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { UserTable } from '../Table'



const Users = props => {
  const users = props.users
  const [pages, setPages] = useState({
    firstPage: 1,
    currentPage: 1,
    usersPerPage: 9
  })

  const handlePagnationUp = (e) => {
    e.target.blur()
    if (pages.currentPage >= Math.ceil(users.length / 9)) {
      console.log('limit!!!')
      return null
    }
    if (pages.currentPage >= pages.firstPage) {
      // console.log(Math.ceil(users.length / 9))
      return setPages(pages => ({ ...pages, currentPage: pages.currentPage + 1 }))
    }  
  }
  const handlePagnationDown = (e) => {
    e.target.blur()
    return setPages(pages => ({ ...pages, currentPage: pages.currentPage - 1 }))
  }

  const indexOfLastUser = pages.currentPage * pages.usersPerPage
  const indexOfFirstUser = indexOfLastUser - pages.usersPerPage
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser)
  const allUsers = users.length
  let newindexOfFirstUser = indexOfFirstUser + 1
  let pageUsers = currentUsers.length + indexOfFirstUser
  return (
    <UserTable
      data={users}
      currentUsers={currentUsers}
      pageInfo={pages}
      setPagination
      allUsers={allUsers}
      pageUsers={pageUsers}
      newindexOfFirstUser={newindexOfFirstUser}
      handlePagnationUp={handlePagnationUp}
      handlePagnationDown={handlePagnationDown}
      history={props.history}
      handleGetSelectUser={props.handleGetSelectUser}
      title=' '
    />
  )
}

export default withRouter(Users)
