import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Wrapper, SubWrapperAlt } from '../../theme/style/styles'
import Tabhead from '../../Components/TabHead'
import GalleryList from '../../Components/Gallery/GalleryList'
import GalleryUpload from '../../Components/Gallery/GalleryUpload'
import swal from 'sweetalert'

import Button from '../../Components/Button'

export default class index extends Component {

  state = {
    images:[],
    loading:false,
    uploaded:false,
    showUploadImages:false
  }
 

  handleSetImages = images =>{
    this.setState({images})
  }
  handleUploadImage =()=>{
    let picture = this.state.images
    if(!picture || !picture.length){
      swal('No images', 'Please select a picture', 'error')
      return
    }
    if(picture && picture.length > 6){
      swal('Limit exceeded', 'you can only upload six(6) files', 'warning')
      return
    }

    this.setState({loading: true})
    this.props.handleGalleryUploadImage(picture)
      .then(res=>{
        if (res !== undefined) {
          swal('Success', res.data.message, 'success')
          this.props.handleFetchAllImages()
          .then(res=>{
            this.setState({loading: false, images:[], uploaded:true})
          })
        }
        this.setState({loading: false})
      })
    // setTimeout(() => {
    //   this.setState({loading: false, uploaded:true})
    // }, 4000);
  }
  handleDeleteImage = id => {
    this.props.handleDeleteImage(id)
    .then(res=>{
      if(res !== undefined){
        this.props.handleFetchAllImages()
      }
    })
  }
handleShowUploadImages = e =>{
let showUploadImages = !this.state.showUploadImages
this.setState({showUploadImages, images:[]})
}
ResetUploaded = () => {
  this.setState({ uploaded:false })
}
  componentDidMount () {
    this.props.handleFetchAllImages()
  }
  render () {

    let display =(
      <GalleryList images={this.props.Gallery} handleDeleteImage={this.handleDeleteImage} >
      <Button margin='24px 0 24px auto' content='Upload image' fontSize='14px'clicked={this.handleShowUploadImages} />
    </GalleryList>
    )
    if (this.state.showUploadImages){
      display=(
        <GalleryUpload 
          setImages={this.handleSetImages} 
          handleUploadImage={this.handleUploadImage} 
          inputs={this.state} 
          handleShowUploadImages={this.handleShowUploadImages}
          ResetUploaded={this.ResetUploaded}
          />
      )
    }

    return (
      <Wrapper>
        {/*
         ==========================================================================
         React-Helmet
         ==========================================================================
        */}
        <Helmet>
          <meta charSet='utf-8' />
          <title> Gallery || Admin Dashboard </title>
        </Helmet>
        <SubWrapperAlt>
          <Tabhead
            title='Gallery'
          />
        
         {display}
          
        </SubWrapperAlt>
      </Wrapper>
    )
  }
}
