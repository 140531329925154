import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import * as PageTable from './styles'
import { TableHead } from './TableParts'
import { UsersList, UsersDepositList, UsersWithdrawalList, UsersBonusList, TransactionDepositList, TransactionWithdrawalsList } from './TableList'
import { CardHeader, Header } from '../../theme/style/typeface'
import Pagination from '../Tools/Pagination'
export { PlatformHousesTable, PlatformBlogTable, PlatformLocationTable, PlatformBonusTable } from './PlatformTables'
export { AdminsTable } from './AdminTables'
export { UserInfoTable } from './UserTable'



export const UserTable = ({
  title,
  data,
  pageInfo,
  setPagination,
  allUsers,
  pageUsers,
  newindexOfFirstUser,
  handlePagnationUp,
  handlePagnationDown,
  currentUsers,
  history,
  handleGetSelectUser }) => {
  let header
  if (title) {
    header = (
      <CardHeader>
        <p>{title}</p>
      </CardHeader>
    )
  }
  return (
    <PageTable.Wrapper
      padding='0'
    >
      <PageTable.Container>
        {header}
        <Table >
          <TableHead>
            <th>name</th>
            <th>email</th>
            <th>date of birth</th>
            <th>user number</th>
            <th>location</th>
          </TableHead>
          <tbody>
            <UsersList history={history} handleGetSelectUser={handleGetSelectUser} users={currentUsers} />
          </tbody>

        </Table>
        { setPagination ? <Pagination
          data={data}
          pages={pageInfo}
          allUsers={allUsers}
          pageUsers={pageUsers}
          newindexOfFirstUser={newindexOfFirstUser}
          handlePagnationUp={handlePagnationUp}
          handlePagnationDown={handlePagnationDown}
        /> : null }
      </PageTable.Container>
    </PageTable.Wrapper>
  )
}
export const UserDepositTableSmall = ({ title, data }) => {
  let header
  if (title) {
    header = (
      <CardHeader
        padding='15px 56px'>
        {title}
      </CardHeader>
    )
  }
  const [pages, setPages] = useState({
    firstPage: 1,
    currentPage: 1,
    usersPerPage: 4,
    setPagination: false
  })

  const handlePagnationUp = (e) => {
    e.target.blur()
    if (pages.currentPage >= Math.ceil(data.length / pages.usersPerPage)) {
      return null
    }
    if (pages.currentPage >= pages.firstPage) {
      return setPages(pages => ({ ...pages, currentPage: pages.currentPage + 1 }))
    }
  }
  const handlePagnationDown = (e) => {
    e.target.blur()
    return setPages(pages => ({ ...pages, currentPage: pages.currentPage - 1 }))
  }
  const handleShowPagination = e => {
    e.target.blur()
    return setPages(pages => ({ ...pages, currentPage: 1, setPagination: !pages.setPagination, usersPerPage: 5 }))
  }
  const indexOfLastUser = pages.currentPage * pages.usersPerPage
  const indexOfFirstUser = indexOfLastUser - pages.usersPerPage
  const currentUsers = data.slice(indexOfFirstUser, indexOfLastUser)
  const allUsers = data.length
  let newindexOfFirstUser = indexOfFirstUser + 1
  let pageUsers = currentUsers.length + indexOfFirstUser
  return (
    <PageTable.Wrapper
      padding='20px 0 0'
    >
      <PageTable.Container>
        {header}
        <Table >
          <TableHead>
            <th>amount (NGN)</th>
            <th>card</th>
            <th>deposit type</th>
            <th>date</th>
            <th>time</th>
          </TableHead>
          <tbody>
            <UsersDepositList deposits={currentUsers} />
          </tbody>
        </Table>
        { pages.setPagination
          ? <div>
            <Pagination
              data={data}
              pages={pages}
              allUsers={allUsers}
              pageUsers={pageUsers}
              newindexOfFirstUser={newindexOfFirstUser}
              handlePagnationUp={handlePagnationUp}
              handlePagnationDown={handlePagnationDown}
            />
            <CardHeader style={{ cursor: 'pointer' }}>
              <Header onClick={handleShowPagination} small margin='0  0 0 auto'>
                  see less
              </Header>
            </CardHeader>
          </div>
          : <CardHeader style={{ cursor: 'pointer' }} onClick={handleShowPagination}>
            <Header small margin='0  0 0 auto'>
              see more
            </Header>
          </CardHeader>
        }
      </PageTable.Container>
    </PageTable.Wrapper>
  )
}
export const UserWithdrawalTableSmall = ({ title, data }) => {
  let header
  if (title) {
    header = (
      <CardHeader
        padding='15px 56px'>
        {title}
      </CardHeader>
    )
  }
  const [pages, setPages] = useState({
    firstPage: 1,
    currentPage: 1,
    usersPerPage: 4,
    setPagination: false
  })

  const handlePagnationUp = (e) => {
    e.target.blur()
    if (pages.currentPage >= Math.ceil(data.length / pages.usersPerPage)) {
      return null
    }
    if (pages.currentPage >= pages.firstPage) {
      return setPages(pages => ({ ...pages, currentPage: pages.currentPage + 1 }))
    }
  }
  const handlePagnationDown = (e) => {
    e.target.blur()
    return setPages(pages => ({ ...pages, currentPage: pages.currentPage - 1 }))
  }
  const handleShowPagination = e => {
    e.target.blur()
    return setPages(pages => ({ ...pages, currentPage: 1, setPagination: !pages.setPagination, usersPerPage: 5 }))
  }
  const indexOfLastUser = pages.currentPage * pages.usersPerPage
  const indexOfFirstUser = indexOfLastUser - pages.usersPerPage
  const currentUsers = data.slice(indexOfFirstUser, indexOfLastUser)
  const allUsers = data.length
  let newindexOfFirstUser = indexOfFirstUser + 1
  let pageUsers = currentUsers.length + indexOfFirstUser
  return (
    <PageTable.Wrapper
      padding='20px 0 0'
    >
      <PageTable.Container>
        {header}
        <Table >
          <TableHead>
            <th>amount (NGN)</th>
            <th>card</th>
            <th>Withdrawal type</th>
            <th>date</th>
            <th>time</th>
          </TableHead>
          <tbody>
            <UsersWithdrawalList withdrawals={currentUsers} />
          </tbody>

        </Table>
        { pages.setPagination
          ? <div>
            <Pagination
              data={data}
              pages={pages}
              allUsers={allUsers}
              pageUsers={pageUsers}
              newindexOfFirstUser={newindexOfFirstUser}
              handlePagnationUp={handlePagnationUp}
              handlePagnationDown={handlePagnationDown}
            />
            <CardHeader style={{ cursor: 'pointer' }}>
              <Header onClick={handleShowPagination} small margin='0  0 0 auto'>
                  see less
              </Header>
            </CardHeader>
          </div>
          : <CardHeader style={{ cursor: 'pointer' }} onClick={handleShowPagination}>
            <Header small margin='0  0 0 auto'>
              see more
            </Header>
          </CardHeader>
        }
      </PageTable.Container>
    </PageTable.Wrapper>
  )
}

export const UserBonusTable = ({
  title,
  data,
  pageInfo,
  setPagination,
  allUsers,
  pageUsers,
  newindexOfFirstUser,
  handlePagnationUp,
  handlePagnationDown,
  currentUsers,
  history }) => {
  let header
  if (title) {
    header = (
      <CardHeader
        padding='15px 56px'>
        {title}
      </CardHeader>
    )
  }

  return (
    <PageTable.Wrapper
      padding='20px 0 0'
    >
      <PageTable.Container>
        {header}
        <Table >
          <TableHead>
            <th>CUSTOMER REFERRED</th>
            <th>CUSTOMER TYPE</th>
            <th>BONUS NAME</th>
            <th>AMOUNT (NGN)</th>
          </TableHead>
          <tbody>
            <UsersBonusList history={history} bonus={data} />
          </tbody>

        </Table>
        { setPagination ? <Pagination
          data={data}
          pages={pageInfo}
          allUsers={allUsers}
          pageUsers={pageUsers}
          newindexOfFirstUser={newindexOfFirstUser}
          handlePagnationUp={handlePagnationUp}
          handlePagnationDown={handlePagnationDown}
        /> : null
        }
      </PageTable.Container>
    </PageTable.Wrapper>
  )
}
export const TransactionsDepositTable = ({ title, data, setPagination }) => {
  let header
  if (title) {
    header = (
      <CardHeader
        padding='15px 56px'>
        {title}
      </CardHeader>
    )
  }
  const [pages, setPages] = useState({
    firstPage: 1,
    currentPage: 1,
    usersPerPage: 9
  })

  const handlePagnationUp = e => {
    e.target.blur()
    if (pages.currentPage >= Math.ceil(data.length / pages.usersPerPage)) {
      return null
    }
    if (pages.currentPage >= pages.firstPage) {
      return setPages(pages => ({ ...pages, currentPage: pages.currentPage + 1 }))
    }
  }
  const handlePagnationDown = e => {
    e.target.blur()
    return setPages(pages => ({ ...pages, currentPage: pages.currentPage - 1 }))
  }

  const indexOfLastUser = pages.currentPage * pages.usersPerPage
  const indexOfFirstUser = indexOfLastUser - pages.usersPerPage
  const currentUsers = data.slice(indexOfFirstUser, indexOfLastUser)
  const allUsers = data.length
  let newindexOfFirstUser = indexOfFirstUser + 1
  let pageUsers = currentUsers.length + indexOfFirstUser
  return (
    <PageTable.Wrapper
      padding='20px 0 0'
    >
      <PageTable.Container>
        {header}
        <Table >
          <TableHead className='transactionTable' >
            <th>basic info</th>
            <th>amount</th>
            <th>date</th>
            <th>type</th>
            {/* <th>bank</th> */}
            <th>ref. code</th>
          </TableHead>
          <tbody>
            <TransactionDepositList deposits={currentUsers} />
          </tbody>

        </Table>
        { setPagination
          ? <Pagination
            data={data}
            pages={pages}
            allUsers={allUsers}
            pageUsers={pageUsers}
            newindexOfFirstUser={newindexOfFirstUser}
            handlePagnationUp={handlePagnationUp}
            handlePagnationDown={handlePagnationDown}
          />
          : null}
      </PageTable.Container>
    </PageTable.Wrapper>
  )
}

export const TransactionsWithdrawalTable = ({ title, data, setPagination }) => {
  let header
  if (title) {
    header = (
      <CardHeader
        padding='15px 56px'>
        {title}
      </CardHeader>
    )
  }
  const [pages, setPages] = useState({
    firstPage: 1,
    currentPage: 1,
    usersPerPage: 9
  })

  const handlePagnationUp = (e) => {
    e.target.blur()
    if (pages.currentPage >= Math.ceil(data.length / pages.usersPerPage)) {
      return null
    }
    if (pages.currentPage >= pages.firstPage) {
      return setPages(pages => ({ ...pages, currentPage: pages.currentPage + 1 }))
    }
  }
  const handlePagnationDown = (e) => {
    e.target.blur()
    return setPages(pages => ({ ...pages, currentPage: pages.currentPage - 1 }))
  }

  const indexOfLastUser = pages.currentPage * pages.usersPerPage
  const indexOfFirstUser = indexOfLastUser - pages.usersPerPage
  const currentUsers = data.slice(indexOfFirstUser, indexOfLastUser)
  const allUsers = data.length
  let newindexOfFirstUser = indexOfFirstUser + 1
  let pageUsers = currentUsers.length + indexOfFirstUser
  return (
    <PageTable.Wrapper
      padding='20px 0 0'
    >
      <PageTable.Container>
        {header}
        <Table >
          <TableHead className='transactionTable' >
            <th>basic info</th>
            <th>amount</th>
            <th>date</th>
            <th>type</th>
            <th>status</th>
            <th>ref. code</th>
          </TableHead>
          <tbody>
            <TransactionWithdrawalsList withdrawals={currentUsers} />
          </tbody>

        </Table>
        { setPagination
          ? <Pagination
            data={data}
            pages={pages}
            allUsers={allUsers}
            pageUsers={pageUsers}
            newindexOfFirstUser={newindexOfFirstUser}
            handlePagnationUp={handlePagnationUp}
            handlePagnationDown={handlePagnationDown}
          />
          : null}
      </PageTable.Container>
    </PageTable.Wrapper>
  )
}

export default Table
