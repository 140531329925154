import styled from 'styled-components'
import { device } from '../../device'

export const PreviewContainer = styled.section`
height: 561px;
display:flex;
flex-direction: column;
justify-content:flex-start;
align-items: center;
`
export const ThumbsContainer = styled.div`
margin: 16px 0 0;
`
export const Thumbs = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items:flex-start;
.body{
  display: flex;
  justify-content: space-between;
  align-items:center;
  width: 100%;
  height: 40px;
  background: rgba(238, 238, 238, .5);
  border-radius: 4px;
  padding: 0 !important
  .body-content{
    display: flex;
    justify-content: flex-start;
    align-items:center;
  }
  .bad-content{
    display: flex;
    justify-content: flex-start;
    align-items:center;
    flex-direction:column;
  }
}
`
export const Title = styled.h2`
font-size: 18px;
line-height: 23px;
text-align: center;
color: #000000;
margin-bottom:14px
`


export const Text = styled.p`
font-size: 16px
line-height: 16px;
text-align: center;
color: #000000;
`
const getColor = (props) => {
  if (props.isDragAccept) {
    return 'pink'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#54C4CF'
}
export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fff;
  color: #565F62;
  outline: none;
  transition: border .24s ease-in-out;
  min-height: 75px;
  margin: 0 30px 10px;
  :hover{
    cursor: pointer;
  }
  @media ${device.mobileS}{
    width: 100%;
  }
  @media ${device.laptop}{
    width: 400px;
  }
`
export const SmallContainer = styled(Container)`
justify-content:center;
flex-direction: row;
margin: 10px 0 10px;
@media ${device.mobileS}{
  width: 300px;
}
@media ${device.laptop}{
  width: 400px;
}
`
export const OrgUploadContainer = styled(Container)`
width: 400px;
max-height: 208px;
justify-content:center;
flex-direction: column;
margin: 10px 0 10px;
@media ${device.mobileS}{
  width: 300px;
}
@media ${device.laptop}{
  width: 400px;
}
`
export const IMG = styled.img`
    margin: 0 10px;
    height: 18px;
`
export const OrgIMG = styled(IMG)`
width: 48px;
height: 48px;
`
export const UploadIMGContainer = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
margin:0 auto;
flex-wrap:wrap;
height: 360px;
width:100%;
overflow-y:auto;
// padding:0 20px !important;
`



export const UploadZone = styled.div`
width: 358px;
height: 750px;
cursor: pointer;
background: transparent;
/* Progress bar inner */
border: 0.5px dashed #0B69A3;
border-radius: 2px;
display: flex;
flex-direction: column;
align-items: center;
justify-content:center;
padding: 0 40px;
margin: 0 50px;
margin-left: 0;
`