import React from 'react'
import * as Page from '../../styles'
import { ActivityCard } from '../../../Card'
const Locations = ({ insights }) => {
  const { countryWithHighestDemand, stateWithHighestDemand, stateWithLeastDemand, mostPreferredLocation, leastPreferredLocation } = insights
  return (
    <Page.Details style={{ margin: '0 auto' }}>
      <Page.Wrapper alt>
        <Page.ContainerAlt>
          <Page.Container className='activity-container'>
            <ActivityCard title='Country with highest demand' number={countryWithHighestDemand || 'Unknown'} hide />
            <ActivityCard title='State with highest demand' number={stateWithHighestDemand[0] || 'Unknown'} hide />
            <ActivityCard title='State with least demand' number={stateWithLeastDemand[0] || 'Unknown'} hide />
            <ActivityCard title='Most preferred area of residence' number={mostPreferredLocation[0] || 'Unknown'} hide />
            <ActivityCard title='Least preferred area of residence' number={leastPreferredLocation[0] || 'Unknown'} hide />
          </Page.Container>
        </Page.ContainerAlt>
      </Page.Wrapper>
    </Page.Details>
  )
}
export default Locations
