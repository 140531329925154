import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Dashboard from '../../Components/Dashboard/Dashboard'
import Tabhead from '../../Components/TabHead'
import { Wrapper, SubWrapperAlt, ListWrapper } from '../../theme/style/styles'
import { AnalyticsList } from '../../Components/Card'




class index extends Component {
state = {
  showBarChart: true,
  deposits:{
    month: new Date().toLocaleString('default', { month: 'long' }),
    year: new Date().getFullYear()
  },
  withdrawals:{
    month: new Date().toLocaleString('default', { month: 'long' }),
    year: new Date().getFullYear()
  }
}

handleShowChart = e =>{

  if(e.target.value === 'line'){
    this.setState({showBarChart: false})
    return
  }
    this.setState({showBarChart: true})
}
handleSetDate = (e, chart) => {
  if(chart === 'withdrawals'){
    this.setState({...this.state, withdrawals:{...this.state.withdrawals, [e.target.name]: e.target.value}}, ()=>this.handleFetchChartData('withdrawals'))
    return
  }
  this.setState({...this.state, deposits:{...this.state.deposits, [e.target.name]: e.target.value}}, ()=>this.handleFetchChartData('deposits'))
}

handleFetchChartData = chart =>{
  const date = new Date()
  let month = date.toLocaleString('default', { month: 'long' })
  let year = date.getFullYear()


  if (chart === 'withdrawals'){
    if (this.state.withdrawals.month !== ''){
      month = this.state.withdrawals.month
    }
    if(this.state.withdrawals.year !== ''){
      year = this.state.withdrawals.year
    }
    this.setState({...this.state, withdrawals:{month, year}})
    setTimeout(() => {
      this.props.handleDashboardPieChartWithData(month, year)
    }, 1000);
    return
  }

  if (this.state.deposits.month !== ''){
    month = this.state.deposits.month
  }
  if(this.state.deposits.year !== ''){
    year = this.state.deposits.year
  }
  this.setState({...this.state, deposits:{month, year}})
  setTimeout(() => {
    this.props.handleDashboardDoughnutChartWithData(month, year)
  }, 1000);
}
  componentDidMount () {
    this.props.handleDashboardAnalytics()
    this.props.handleDashboardBarChartData()
    this.props.handleDashboardLineChartData()
    this.props.handleDashboardDoughnutChartData()
    this.props.handleDashboardPieChartData()
    .then(res=>{
      this.props.handleDashboardContingentLineChartData()
    })
    
  }


  render () {
    return (
      <Wrapper>
        <Helmet>
          <meta charSet='utf-8' />
          <title> Dashboard || First Homes</title>
        </Helmet>
        <SubWrapperAlt>
          <Tabhead
            title='dashboard'
          />
          <ListWrapper>
            <AnalyticsList
              analytics={this.props.analytics}
            />
          </ListWrapper>

          <Dashboard 
          analytics={this.props.analytics} 
          dashboard={this.props.Dashboard} 
          inputs={this.props.ChartData} 
          state={this.state} 
          handleShowChart={this.handleShowChart}
          handleSetDate={this.handleSetDate}
           />
        </SubWrapperAlt>

      </Wrapper>

    )
  }
}

export default index
