import React from 'react'
import * as Page from '../../../Insights/styles'
import { PlatformBonusTable } from '../../../Table'
import AddBonusModal from '../../Modal/Bonus'
import EditBonusModal from '../../Modal/Bonus/EditBonus'
import DeleteBonusModal from '../../Modal/Bonus/DeleteBonus'
const Bonus = ({ bonuses, inputs, handleSetBonus, handleShowBonusModal, handleBonusInput, handleAddNewBonus, handleEditBonus, handleDeleteBonus }) => {
  // const { oldest, youngest, averageAge } = insights
  return (
    <Page.Details style={{ margin: '0 auto' }}>
      <Page.Wrapper alt>
        <Page.ContainerAlt>
          <PlatformBonusTable data={bonuses} title='' setPagination handleSetBonus={handleSetBonus} />
        </Page.ContainerAlt>
      </Page.Wrapper>
      <AddBonusModal
        inputs={inputs}
        show={inputs.showAddBonus}
        onHide={handleShowBonusModal}
        handleBonusInput={handleBonusInput}
        handleAddNewBonus={handleAddNewBonus}
      />
      <EditBonusModal
        inputs={inputs}
        show={inputs.showEditBonus}
        onHide={() => handleShowBonusModal('edit')}
        handleBonusInput={handleBonusInput}
        handleAddNewBonus={handleAddNewBonus}
        handleEditBonus={handleEditBonus}
      />
      <DeleteBonusModal
        inputs={inputs}
        show={inputs.showDeleteBonus}
        onHide={() => handleShowBonusModal('delete')}
        handleDeleteBonus={handleDeleteBonus}
      />
    </Page.Details>
  )
}
export default Bonus
