import React from 'react'
import * as Page from '../../styles'
import Form from './BlogParts/form'
import Editor from './BlogParts/textEditor'
const BlogForm = ({ inputs, handleShowBlogForm, handleBlogInputs, handleImageChange, handleEditorChange, handleSaveBlog }) => {
  return (
    <Page.Details style={{ margin: '0' }}>
      <Page.Wrapper alt>
        <Page.Container>
          <Form inputs={inputs.blog} handleBlogInputs={handleBlogInputs} handleImageChange={handleImageChange} />
          <Editor inputs={inputs} handleEditorChange={handleEditorChange} handleSaveBlog={handleSaveBlog} handleShowBlogForm={handleShowBlogForm} />
        </Page.Container>
      </Page.Wrapper>
    </Page.Details>
  )
}
export default BlogForm
