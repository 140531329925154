import React from 'react'
import { UsersRow,
  UsersDepositRow,
  UsersWithdrawalRow,
  UsersBonusRow,
  TransactionDepositRow,
  TransactionWithdrawalRow,
  PlatformHousesRow,
  PlatformBlogRow,
  PLatformLocationRow,
  AdminRow, PLatformBonusRow,
  UserInfoRow } from './TableRow'

export const UsersList = ({ users, handleGetSelectUser }) => users.map(user => (
  <UsersRow handleGetSelectUser={handleGetSelectUser} key={user.userID || Math.random()} user={user} {...user} />
))

export const UsersDepositList = ({ deposits }) => deposits.map(deposit => (
  <UsersDepositRow key={Math.random()} deposit={deposit} {...deposit} />
))
export const UsersWithdrawalList = ({ withdrawals }) => withdrawals.map(withdrawal => (
  <UsersWithdrawalRow key={Math.random()} withdrawal={withdrawal} {...withdrawal} />
))
export const UsersBonusList = ({ bonus, history }) => bonus.map(bonus => (
  <UsersBonusRow history={history} key={Math.random()} bonus={bonus} />
))
export const TransactionDepositList = ({ deposits }) => deposits.map(deposit => (
  <TransactionDepositRow key={Math.random()} deposit={deposit} />
))
export const TransactionWithdrawalsList = ({ withdrawals }) => withdrawals.map(withdrawal => (
  <TransactionWithdrawalRow key={Math.random()} withdrawal={withdrawal} />
))
export const PlatformHousesList = ({ houses, handleSetHouse }) => houses.map(house => (
  <PlatformHousesRow key={house._id || Math.random()} house={house} handleSetHouse={handleSetHouse} />
))
export const PlatformBlogList = ({ blogs, handleSetBlog }) => blogs.map(blog => (
  <PlatformBlogRow key={blog._id || Math.random()} blog={blog} handleSetBlog={handleSetBlog} />
))

export const PlatformLocationList = ({ locations, handleSetLocation }) => locations.map(location => (
  <PLatformLocationRow key={Math.random()} location={location} handleSetLocation={handleSetLocation} />
))
export const PlatformBonusList = ({ bonuses, handleSetBonus }) => bonuses.map(bonus => (
  <PLatformBonusRow key={bonus._id || Math.random()} bonus={bonus} handleSetBonus={handleSetBonus}  />
))

export const AdminList = ({ admins, handleSetAdmin }) => admins.map(admin => (
  <AdminRow key={Math.random()} admin={admin} handleSetAdmin={handleSetAdmin} />
))
export const UserInfoList = ({ info, handleSetAdmin }) => info.map(info => (
  <UserInfoRow key={Math.random()} info={info}  />
))
