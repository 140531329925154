const type = {
  LOGIN_ADMIN: 'LOGIN_ADMIN',
  GET_ADMINS: 'GET_ADMINS',
  EDIT_ADMIN: 'EDIT_ADMIN',
  DELETE_ADMIN: 'DELETE_ADMIN',
  EDIT_ADMIN_ACCESS: 'EDIT_ADMIN_ACCESS',
  GET_ALL_USERS: 'GET_ALL_USERS',
  GET_USER_ACTIVITY: 'GET_USER_ACTIVITY',
  GET_USER_INFO: 'GET_USER_INFO',
  GET_USER_HISTORY: 'GET_USER_HISTORY',
  GET_ALL_DEPOSITS: 'GET_ALL_DEPOSITS',
  GET_ALL_WITHDRAWALS: 'GET_ALL_WITHDRAWALS',
  GET_INSIGHT: 'GET_INSIGHT',
  GET_GENERAL_INSIGHTS: 'GET_GENERAL_INSIGHTS',
  GET_HOUSES: 'GET_HOUSES',
  ADD_HOUSE: 'ADD_HOUSE',
  EDIT_HOUSE: 'EDIT_HOUSE',
  GET_USER_BONUS: 'GET_USER_BONUS',
  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_STATES: 'GET_STATES',
  GET_USERS_STATE: 'GET_USERS_STATE',
  GET_USERS_LOCALE: 'GET_USERS_LOCALE',
  GET_HOUSE_INSIGHTS: 'GET_HOUSE_INSIGHTS',
  GET_LOCATION_INSIGHTS: 'GET_LOCATION_INSIGHTS',
  GET_RATE: 'GET_RATE',
  GET_ALL_BLOGS: 'GET_ALL_BLOGS',
  SET_ALL_LOCATIONS: 'SET_ALL_LOCATIONS',
  GET_FINANCE_INSIGHTS: 'GET_FINANCE_INSIGHTS',
  SET_ALL_BONUSES: 'SET_ALL_BONUSES',
  GET_DASHBOARD: 'GET_DASHBOARD',
  GET_IMAGES: 'GET_IMAGES',
  SET_BARCHART: 'SET_BARCHART',
  SET_LINECHART: 'SET_LINECHART',
  SET_DOUGHNUT: 'SET_DOUGHNUT',
  SET_DASHBOARD_DEPOSITS: 'SET_DASHBOARD_DEPOSITS',
  SET_PIE: 'SET_PIE',
  SET_DASHBOARD_WITHDRAWALS: 'SET_DASHBOARD_WITHDRAWALS',
  SET_CONTINGENT_LINECHART: 'SET_CONTINGENT_LINECHART',
  SET_CONTINGENT: 'SET_CONTINGENT'
}

export default type
