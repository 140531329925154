import type from '../Type'
import axios from 'axios'
import { handleError } from '../../lib'

const { GET_DASHBOARD,
  SET_BARCHART,
  SET_LINECHART, SET_DOUGHNUT,
  SET_DASHBOARD_DEPOSITS,
  SET_PIE,
  SET_DASHBOARD_WITHDRAWALS,
  SET_CONTINGENT_LINECHART,
  SET_CONTINGENT } = type

const getAllDashboard = payload => ({
  type: GET_DASHBOARD,
  payload
})
const setBarChartData = payload => ({
  type: SET_BARCHART,
  payload
})
const setLineChartData = payload => ({
  type: SET_LINECHART,
  payload
})

const setDoughnutChartData = payload => ({
  type: SET_DOUGHNUT,
  payload
})
const setDashboardDepositData = payload => ({
  type: SET_DASHBOARD_DEPOSITS,
  payload
})
const setPieChartData = payload => ({
  type: SET_PIE,
  payload
})

const setDashboardWithdrawalsData = payload => ({
  type: SET_DASHBOARD_WITHDRAWALS,
  payload
})

const setContingentLineChartData = payload => ({
  type: SET_CONTINGENT_LINECHART,
  payload
})
const setContingentData = payload => ({
  type: SET_CONTINGENT,
  payload
})

export const handleDashboardAnalytics = () => dispatch => axios
  .get(`/dashboard/cards`)
  .then(res => {
    let result = res.data
    dispatch(getAllDashboard(result))
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
  })

export const handleDashboardBarChartData = () => dispatch => axios
  .get(`/dashboard/overview/user`)
  .then(res => {
    let labels
    let data
    let result = res.data.data
    data = Object.values(result.userGrowth)
    labels = Object.keys(result.userGrowth)
    dispatch(setBarChartData({ labels, data }))
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
  })
export const handleDashboardLineChartData = () => dispatch => axios
  .get(`/dashboard/overview/contribution`)
  .then(res => {
    let labels
    let data
    let result = res.data.data
    data = Object.values(result.contribution)
    labels = Object.keys(result.contribution)
    dispatch(setLineChartData({ labels, data }))
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
  })

export const handleDashboardDoughnutChartData = () => dispatch => axios
  .get(`/dashboard/finance/deposit`)
  .then(res => {
    // let labels
    let data
    let result = res.data.data
    data = Object.values(result).slice(0, 3)
    dispatch(setDoughnutChartData({ data }))
    dispatch(setDashboardDepositData(result))
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
  })
export const handleDashboardDoughnutChartWithData = (month, year) => dispatch => axios
  .get(`/dashboard/finance/deposit?month=${month}&year=${year}`)
  .then(res => {
    // let labels
    let data
    let result = res.data.data
    data = Object.values(result).slice(0, 3)
    dispatch(setDoughnutChartData({ data }))
    dispatch(setDashboardDepositData(result))
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
  })

export const handleDashboardPieChartData = () => dispatch => axios
  .get(`/dashboard/finance/withdrawal`)
  .then(res => {
    // let labels
    let data
    let result = res.data
    data = Object.values(result.data)
    dispatch(setPieChartData({ data }))
    dispatch(setDashboardWithdrawalsData(result))
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
  })
export const handleDashboardPieChartWithData = (month, year) => dispatch => axios
  .get(`/dashboard/finance/withdrawal?month=${month}&year=${year}`)
  .then(res => {
    // let labels
    let data
    let result = res.data
    data = Object.values(result.data)
    dispatch(setPieChartData({ data }))
    dispatch(setDashboardWithdrawalsData(result))
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
  })

export const handleDashboardContingentLineChartData = () => dispatch => axios
  .get(`/dashboard/contigent/graph `)
  .then(res => {
    let labels
    let data
    let result = res.data.data
    data = Object.values(result.contigentLiabilityGraph)
    labels = Object.keys(result.contigentLiabilityGraph)
    // console.log(labels, data)
    dispatch(setContingentLineChartData({ labels, data }))
    dispatch(setContingentData(result.totalContigentLiability))
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
  })
