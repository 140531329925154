import React from 'react'
import * as Page from '../../../styles'
import { BlogForm } from '../../../../Forms'

const Form = ({ inputs, handleBlogInputs, handleImageChange }) => {
  return (
    <Page.Details style={{ margin: '0', }}>
      <Page.Wrapper alt style={{ width: '100%' }}>
        <Page.ContainerAlt style={{ alignItems: 'flex-start' }}>
          <BlogForm inputs={inputs} handleBlogInputs={handleBlogInputs} handleImageChange={handleImageChange} />
        </Page.ContainerAlt>
      </Page.Wrapper>
    </Page.Details>
  )
}
export default Form
