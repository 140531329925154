import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { GalleryImage, Logo } from '../../Picture'
import * as DropZoneStyle from './styles'
import { SubWrapper, SubWrapperAlt } from '../../../theme/style/styles'
import { CardHeader } from '../../../theme/style/typeface'
import Button, { ModalCancelButton } from '../../Button'

// const activeStyle = {
//   borderColor: '#54C4CF'
// }

// const acceptStyle = {
//   borderColor: '#54C4CF'
// }

// const rejectStyle = {
//   borderColor: '#ff1744'
// }
// const thumbsContainer = {
//   display: 'flex',
//   flexDirection: 'row',
//   flexWrap: 'wrap',
//   marginTop: 16
// }

export function UploadGalleryDropZone (props) {
  const [files, setFiles] = useState([])
  const { getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    rejectedFiles } = useDropzone({
    accept: 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, image/svg+xml',
    multiple: true,
    maxSize: 5000000,
    disabled: props.disabled,
    onDrop: acceptedFiles => {
      return (setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))), props.galleryUpload(acceptedFiles))
    }
  })
  const selectedParent = preview => {
    let newfiles = files.filter(file => (
      file.name !== preview
    ))
    setFiles(newfiles)
    props.galleryUpload(newfiles)
  }

  const ClearState = () => {
    setFiles([])
    props.galleryUpload([])
    setTimeout(() => {
      props.ResetUploaded()
    }, 1000)
  }
  if (props.uploaded) {
    setTimeout(() => {
      ClearState()
    }, 2000)
    
  }
  const thumbs = files.map(file => <GalleryImage key={file.name} image={file.preview} deleteImage={() => selectedParent(file.name)} />)
  const badThumbs = rejectedFiles.map((file, index) => {
    return (
      <DropZoneStyle.Thumbs key={index} >
        <div className='body'>
          <div className='bad-content'>
            { file.size > 5000000 ? <div><DropZoneStyle.Text>{file.name} </DropZoneStyle.Text>
              <DropZoneStyle.Text> is too large, maximum size 5mb, it will not be uploaded</DropZoneStyle.Text></div> : <div><DropZoneStyle.Text>{file.name} / </DropZoneStyle.Text>
                <DropZoneStyle.Text>file type must be of .png, , .jpg, .jpeg, .gif  or .svg, it will not be uploaded</DropZoneStyle.Text></div> }
          </div>
        </div>
      </DropZoneStyle.Thumbs>

    )
  })

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files])

  return (
    <SubWrapper justifyContent='flex-start'
      padding='0'
      flexWrap='nowrap'
      alignItems='flex-start'>
      <DropZoneStyle.UploadZone {...getRootProps({ isDragActive, isDragAccept, isDragReject, onChange: event => {} })}>
        <input {...getInputProps()} />
        <Logo src={require('../../../assets/images/upload_dropzone.svg')} width='80px' height='80px' margin='0 0 35px' />
        <DropZoneStyle.Title>Add Photos </DropZoneStyle.Title>
        <DropZoneStyle.Text>Please add a maximum of 6 photos of the apartment in order to get the property onboard.</DropZoneStyle.Text>
      </DropZoneStyle.UploadZone>
      <SubWrapperAlt padding='0'>
        <CardHeader padding='0'>
          Photo Upload
        </CardHeader>
        <SubWrapper
          justifyContent='flex-start'
          padding='0 30px'
          flexWrap='nowrap'
          alignItems='center'
          style={{ margin: '80px 0 60px' }}
        >
          <Logo src={require('../../../assets/images/upload-image.svg')} width='60px' height='60px' />
        </SubWrapper>
        {rejectedFiles && rejectedFiles.length > 0 ? <DropZoneStyle.Title style={{ textAlign: 'center' }}>File(s) Rejected </DropZoneStyle.Title> : null}
        {files && files.length > 0 ? <DropZoneStyle.Title style={{ textAlign: 'center' }}>Uploaded image(s)</DropZoneStyle.Title> : null}
        <DropZoneStyle.UploadIMGContainer>
          {thumbs}
          {badThumbs}
        </DropZoneStyle.UploadIMGContainer>
        <SubWrapper
          justifyContent='flex-end'
          padding='0'
          alignItems='center'
          style={{ margin: '50px 0' }}
        >
          <Button content='Upload' clicked={props.handleUploadImage} disabled={props.loading} loading={props.loading} fontSize='14px' margin='0 12px' />
          <ModalCancelButton content='Cancel' fontSize='14px' margin='0' clicked={props.handleShowUploadImages} />
        </SubWrapper>
      </SubWrapperAlt>
    </SubWrapper>
  )
}
