import Users from '../../Pages/Users'
import { connect } from 'react-redux'
import { handleFetchAllUser, handleFetchUser, handleFetchUserActivity, handleFetchUserHistory, handleFetchUserBonus, handleFetchUserByParams } from '../../Store/Actions'

/**
 * here we handle passing redux to our component and export
 */
const mapStateToProps = state => ({
  currentPage: state.Admin.users.currentPage,
  users: state.Admin.users.users
})

const mapDispatchToProps = dispatch => ({
  handleFetchAllUser () {
    return dispatch(handleFetchAllUser())
  },
  handleFetchUser (userId) {
    return dispatch(handleFetchUser(userId))
  },
  handleFetchUserActivity (userId) {
    return dispatch(handleFetchUserActivity(userId))
  },
  handleFetchUserHistory (userId) {
    return dispatch(handleFetchUserHistory(userId))
  },
  handleFetchUserBonus (userId) {
    return dispatch(handleFetchUserBonus(userId))
  },
  handleFetchUserByParams (month, year) {
    return dispatch(handleFetchUserByParams(month, year))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
