import React from 'react'
import { SelectAlt } from '../Input'
import { ModalCancelButton } from '../Button'
import { Header } from '../../theme/style/typeface'
import * as Tab from './styles'
const TabHead = ({ 
  inputs,
  title,
  wrapperMargin,
  wrapperPadding,
  SubWrapperMargin,
  placeholderOne,
  placeholderTwo,
  showDate,
  handleSetDate,
  handleCancel,
  ...props }) => {
  return (
    <Tab.Wrapper margin={wrapperMargin}
      padding={wrapperPadding}>
      <Header
        margin='0 0 0'
        {...props}>
        {title}
      </Header>
      {showDate ? <Tab.SubWrapper
        margin={SubWrapperMargin}
      >
        <SelectAlt name='month' value={inputs.month} changed={handleSetDate}>
          <option hidden>{ placeholderOne || 'Choose Month'}</option>
          <option value='January'>January</option>
          <option value='February'>February</option>
          <option value='March'>March</option>
          <option value='April'>April</option>
          <option value='May'>May</option>
          <option value='June'>June</option>
          <option value='July'>July</option>
          <option value='August'>August</option>
          <option value='September'>September</option>
          <option value='October'>October</option>
          <option value='November'>November</option>
          <option value='December'>December</option>
        </SelectAlt>
        <SelectAlt name='year' changed={handleSetDate} value={inputs.year}>
          <option hidden>{ placeholderTwo || 'Choose Year'}</option>
          <option value='2019'>2019</option>
          <option value='2018'>2018</option>
          <option value='2017'>2017</option>
          <option value='2016'>2016</option>
          <option value='2015'>2015</option>
          <option value='2014'>2014</option>
        </SelectAlt>
        {inputs.cancel ? <ModalCancelButton content='Cancel' margin='0 0 0 10px' clicked={handleCancel}/> : null }
      </Tab.SubWrapper> : null}

    </Tab.Wrapper>
  )
}

export default TabHead
