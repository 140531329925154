import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Wrapper, SubWrapperAlt } from '../../theme/style/styles'
import { AddAdminButton } from '../../Components/Button'
import Tabhead from '../../Components/TabHead'
import Admins from '../../Components/Support'
import validator from 'validator'
import swal from 'sweetalert'
export default class index extends Component {

  state ={
    showAddAdmin: false,
    showEditAdmin: false,
    showDeleteAdmin:false,
    admin: {
    name:'',
    email:'',
    create:'',
    view:'',
    delete:'',
    edit:'',
    'super admin':'',
    _id: '',
    privileges:[]
  },
    loading: false
  }
  handleOnChange = e =>{
    this.setState({...this.state, admin:{...this.state.admin, [e.target.name]: e.target.value }})
  }
  handleCheckbox = e =>{
    if(e.target.checked){
      this.setState({...this.state, admin:{...this.state.admin, [e.target.name]: e.target.name }})
      return 
    }
    this.setState({...this.state, admin:{...this.state.admin, [e.target.name]:''}})
  }
  handleAddAdmin = e =>{
    e.preventDefault()
    let admin = this.state.admin
    let adminData
    let privileges = []
    if(validator.isEmpty(admin.name)){
      swal('No Name', 'Please enter admin Name', 'warning')
      return
    }
    if(validator.isEmpty(admin.email)){
      swal('No Email', 'Please enter admin Email', 'warning')
      return
    }
    if(!validator.isEmail(admin.email)){
      swal('inValid Email', 'Please enter valid Email', 'error')
      return
    }
    
    if(!validator.isEmpty(admin.create)){
      privileges.push('create')
    }
    if(!validator.isEmpty(admin.edit)){
      privileges.push('edit')
    }
    if(!validator.isEmpty(admin.delete)){
      privileges.push('delete')
    }
    if(!validator.isEmpty(admin.view)){
      privileges.push('view')
    }
    if(!validator.isEmpty(admin['super admin'])){
      privileges.push('super admin')
    }
    if(!privileges || privileges.length === 0 ){
      swal('No Rite Access', 'Please admin type of Access', 'warning')
      return
    }
    adminData ={
      name:admin.name,
      email:admin.email,
      privileges
    }
    this.setState({loading: true})

    
    this.props.handleAddAdmin(adminData)
      .then(res=>{
        this.props.handleFetchAllAdmins()
        .then(res=>(
          this.setState({showAddAdmin: false, loading: false, admin:{
            name:'',
            email:'',
            create:'',
            view:'',
            delete:'',
            edit:'',
            super:'',
            privileges:[]}})
        ))
      })
  }

  handleSetAdmin = (admin, action) => {
    let create;
    let edit;
    let _super;
    let _delete;
    let view


    admin.privileges.map( privilege => {
      if(privilege ==='create'){
        create=privilege
      }
      if(privilege ==='edit'){
        edit=privilege
      }
      if(privilege ==='delete'){
        _delete=privilege
      }
      if(privilege ==='super admin'){
        _super=privilege
      }
      if(privilege ==='view'){
        view=privilege
      }
      return null
    })
// console.log(create, edit, _super, _delete, view)
    if( action === 'delete'){
      return  this.setState({...this.state, admin:{...admin}}, ()=> this.handleShowDeleteAdminModal())
    }
   this.setState({...this.state, admin: {...admin, create, edit, delete:_delete, 'super admin':_super, view}}, ()=> this.handleShowEditAdminModal())
   return
  }
  handleShowAdminModal =() =>{
    let showAddAdmin = !this.state.showAddAdmin
    this.setState({showAddAdmin, loading:false, admin:{
    name:'',
    email:'',
    create:'',
    view:'',
    delete:'',
    edit:'',
    super:'',
    _id:'',
    privileges:[],}})
  } 

  handleShowEditAdminModal =()=>{
    let showEditAdmin = !this.state.showEditAdmin
    this.setState({showEditAdmin, loading:false})
  }
  handleShowDeleteAdminModal =()=>{
    let showDeleteAdmin = !this.state.showDeleteAdmin
    this.setState({showDeleteAdmin, loading:false})
  }
  handleEditAdmin = e =>{
    e.preventDefault()
    let admin = this.state.admin
    let adminData
    let privileges = []
    if(!validator.isEmpty(admin.create)){
      privileges.push('create')
    }
    if(!validator.isEmpty(admin.edit)){
      privileges.push('edit')
    }
    if(!validator.isEmpty(admin.delete)){
      privileges.push('delete')
    }
    if(!validator.isEmpty(admin.view)){
      privileges.push('view')
    }
    if(!validator.isEmpty(admin['super admin'])){
      privileges.push('super admin')
    }
    if(!privileges || privileges.length === 0 ){
      swal('No Rite Access', 'Please admin type of Access', 'warning')
      return
    }
    adminData={
      privileges
    }
    this.setState({loading: true})
    // setTimeout(() => {
    //   // console.log(this.state.admin._id) 
    //   this.setState({loading:false }, ()=>console.log(this.state.admin._id) )
    // }, 3000);
    this.props.handleAccessChange(adminData, this.state.admin._id)
    .then(res=>{
      if(res !== undefined){
        this.props.handleFetchAllAdmins()
        this.setState({loading:false, showEditAdmin:false })
      }
      this.setState({loading:false })
    })
  }
  handleDeleteAdmin = e =>{
    e.preventDefault()
    this.setState({loading: true })
    this.props.handleDelete(this.state.admin._id)
    .then(res=>{
      if(res !== undefined){
        this.props.handleFetchAllAdmins()
        this.setState({loading:false, showDeleteAdmin:false })
      }
      this.setState({loading:false })
    })
  }
  componentDidMount () {
    this.props.handleFetchAllAdmins()
  }
  render () {
    return (
      <Wrapper>
        <Helmet>
          <meta charSet='utf-8' />
          <title> Admins || Admin Dashboard</title>
        </Helmet>
        <SubWrapperAlt>
          <Tabhead
            title='Admin'
          />
          <AddAdminButton  clicked={this.handleShowAdminModal} />
          <Admins 
          handleOnChange={this.handleOnChange} 
          handleAddAdmin={this.handleAddAdmin} 
          handleEditAdmin={this.handleEditAdmin}
          handleDeleteAdmin={this.handleDeleteAdmin}
          admins={this.props.admins} 
          inputs={this.state} 
          handleShowAdminModal={this.handleShowAdminModal}
          handleShowEditAdminModal={this.handleShowEditAdminModal}
          handleShowDeleteAdminModal={this.handleShowDeleteAdminModal}
          handleSetAdmin={this.handleSetAdmin}
          handleCheckbox={this.handleCheckbox}
          />
        </SubWrapperAlt>
      </Wrapper>
    )
  }
}
