import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { Provider } from 'react-redux'
import Store from './Store/Store'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import colors from './theme/main'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { handleFetchUser, handleFetchUserActivity, handleFetchUserHistory, handleFetchUserBonus, handleFetchAdminDetailsOnRefresh } from './Store/Actions'

// axios.create({
//   baseURL: 'https://analytics.firsthomesafrica.com/api/admin'
//   // timeout: 20000,
//   // headers: {'X-Custom-Header': 'foobar'}
// })
const Token = localStorage.getItem('token')
axios.defaults.baseURL = 'https://analytics.firsthomesafrica.com/api/admin'
if (Token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Token}`
  Store.dispatch(handleFetchAdminDetailsOnRefresh())
}
const userId = localStorage.getItem('selecteduserId')
if (userId) {
  Store.dispatch(handleFetchUser(userId))
  Store.dispatch(handleFetchUserActivity(userId))
  Store.dispatch(handleFetchUserHistory(userId))
  Store.dispatch(handleFetchUserBonus(userId))
}
ReactDOM.render(
  <Provider store={Store} >
    <ThemeProvider theme={colors} >
      <Router >
        <App />
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
