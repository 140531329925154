import styled from 'styled-components'

export const Header = styled.h1`
    font-size:${props => props.small ? '16px' :'30px'};
    line-height: ${props => props.small ? '21px' : '38px'};
    font-weight: bold;
    color: ${props => props.alt ? props.theme.white : props.theme.actions};
    text-transform: capitalize;
    margin:${props => props.margin};
`
Header.defaultProps = {
  margin: '0 0 16px'
}
export const SubHeader = styled.h3`
    font-size: 16px;
    line-height: 20px;
    text-transform: ${props => props.textTransform ? props.textTransform : 'capitalize'};
    color: ${props => props.alt ? props.theme.placeholder : props.theme.blackText};
    margin:${props => props.margin}
`
SubHeader.defaultProps = {
  margin: '0 0 16px'
}

export const Title = styled(SubHeader)`
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    text-transform: inherit;
    color: ${props => props.theme.actions};
    margin:${props => props.margin}
`
Title.defaultProps = {
  margin: '0 0 16px'
}
export const UpperNav = styled.h4`
    font-size: 1.4rem;
    color: ${props => props.theme.lightGray};
`
export const MainNav = styled(SubHeader)`
    margin: 0;
    :hover {
        color: ${props => props.theme.blue};
    }
`
export const SubMainNav = styled(Title)`
    text-transform: capitalize;
    color: ${props => props.theme.orange};
    margin:${props => props.margin};
    align-self: flex-start;
`

export const Under = styled.div`
    font-weight: bold;
    font-size: 1.2rem;
    color: ${props => props.theme.black};

    a {
        color: ${props => props.theme.purple};
    }
`
export const Button = styled.span`
    text-transform: ${props => (props.main ? 'uppercase' : null)};
    font-size: 18px;
    line-height: 23px;
    color: ${props => props.alt ? props.theme.red : props.theme.green};
    width: 40px;
    height: 40px;
    background: ${props => props.alt ? props.theme.paleRed : props.theme.paleGreen};
    border-radius: 50%;
`
export const Label = styled.span`
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${props => props.alt ? props.theme.bodyText : props.theme.bodyText};
    padding:${props => props.padding}
    margin :${props => props.margin}
`
export const SkillType = styled(Label)`
font-weight: bold;
color: ${props => props.theme.subHandleText};
`
export const IncrementLabel = styled(Label)`
    text-transform: uppercase;
    font-size: 1rem;
`
export const BoldLabel = styled(Label)`
    text-align: ${props => props.textAlign} !important;
    color: ${props => props.altLabelColor ? props.theme.white : props.theme.blackText};
    margin-left:5px;
`
export const GreenLabel = styled(Label)`
    font-weight: bold;
    text-align: ${props => props.textAlign} !important;
    color: ${props => !props.altColor ? props.theme.red : props.theme.green};
    cursor: pointer;
`
export const PaymentLabel = styled.div`
    padding-left: 1rem;
    color: ${props => props.theme.darkGray};
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
`
export const PaymentTitle = styled(PaymentLabel)`
    font-size: 1.8rem;
`
export const Warning = styled.span`
    color: ${props => props.theme.caution};
    font-weight: bold;
    font-size: 12px;
`
export const Value = styled.span`
    font-weight: bold;
    font-size: 14px;
    color: ${props => props.theme.black};
    opacity: 0.5;
`
export const CheckBoxLabel = styled(Label)`
    display: inline-block;
    vertical-align: top;

    ::after {
        position: absolute;
        top: 3px;
        left: 0;
        width: 14px;
        height: 14px;
        transition: all 0.2s ease-in-out;
        border: 1px solid #7b7c7f;
        border-radius: 2px;
        background: #fff;
        color: #fff;
        font: 10px/13px icomoon !important;
        font-variant: normal;
        text-align: center;
        text-transform: none;
        content: "";
    }
`

export const Number = styled.span`
font-size: 12px;
line-height: 16px;
margin-left:4px;
color: ${props => props.theme.text};
`
export const CardHeader = styled.div`
display:flex;
justify-content: space-between;
align-items:center;
width: 100%;
height: 56px;
border-radius: 4px;
padding: ${props => props.padding ? props.padding : '20px 56px'};
font-style: normal;
font-weight: bold;
font-size: ${props => props.fontSize ? props.fontSize : '20px'};
line-height: 16px;
color: ${props => props.altColor ? props.theme.actions : props.theme.bodyText };
background-color: ${props => props.altBackground ? props.theme.faintBlue : props.theme.transparent};
text-transform:capitalize;
`
export const UserHandle = styled(Header)`
font-size: 12px;
line-height: 16px;
color: ${props => props.theme.handleText};
text-transform: capitalize;
margin:0;
`
export const SubHandle = styled(UserHandle)`
margin: 10px 0 0;
color: ${props => props.theme.subHandleText};
`

export const TabHeader = styled.span`
text-transform:uppercase;
padding:  18px 50px;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 16px;
border-bottom: 4px solid ${props => props.alt ? props.theme.orange : props.theme.transparent};
color: ${props => props.alt ? props.theme.orange : props.theme.cardName};
transition: .3s;
cursor: pointer;
:hover{
    // border-bottom: 4px solid ${props => props.theme.orange};
    color: ${props => props.theme.orange };
}
`

export const CardText = styled.p`
font-size: ${props => props.alt ? '32px' : '24px'};
line-height: ${props => props.alt ? '40px' : '24px'};
margin:${props => props.margin};
color: ${props => props.altColor ? props.theme.white : props.theme.bodyText}
text-transform:capitalize;
`

export const CardSubText = styled(CardText)`
font-size: 14px;
line-height: 18px;
color: ${props => props.altColor ? props.theme.white : props.theme.bodyText};
margin:${props => props.margin};
`