import React from 'react'
import { Pie } from 'react-chartjs-2'

const PieChart = ({ data }) => {
  return (
    <Pie
      data={data}
      height={173}
      width={173}
      id='pie'
      options={
        {
          legend: {
            display: false
          },
          responsive: false,
          maintainAspectRatio: false
        }
      }
    />
  )
}

export default PieChart
