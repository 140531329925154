import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Wrapper, SubWrapper} from '../../theme/style/styles'
import { Banner, LogoContainer, Text, FixedContainer } from './styles'
import { LoginForm } from '../../Components/Forms'
import swal from 'sweetalert'
import validator from 'validator'

export default class index extends Component {
  state = {
    loading:false,
    admin:{
      email:'',
      password:''
    }
  }

  handleLoginInputs = e =>{
    this.setState({...this.state, admin:{...this.state.admin, [e.target.name]: e.target.value}})
  }
  handleLogin =()=>{
    let email = this.state.admin.email
    let password = this.state.admin.password
    if(validator.isEmpty(email)){
      swal('Empty Email field', 'Please enter Email', 'warning')
      return
    }
    if(!validator.isEmail(email)){
      swal('Not an email', 'Please enter valid Email', 'warning')
      return
    }
    if(validator.isEmpty(password)){
      swal('Empty Field', 'Please enter Password', 'warning')
      return
    }
    this.setState({loading:true})
    this.props.handleAdminLogin(this.state.admin)
    .then(res=>{
      if (res !== undefined){
       return this.setState({loading:false,}, ()=> this.props.history.push('/dashboard'))
      }
      this.setState({loading:false,})
    })
  }
  render () {
    return (
      <Wrapper>
        {/*
         ==========================================================================
         React-Helmet
         ==========================================================================
        */}
        <Helmet>
          <meta charSet='utf-8' />
          <title> Login || Admin Dashboard </title>
        </Helmet>

        <SubWrapper padding='0' style={{ justifyContent: 'flex-start', position: 'relative' }}>
          <LogoContainer />
          <Text> Dashboard</Text>
          <Banner />
          <FixedContainer>
              <LoginForm inputs={this.state} 
              handleLoginInputs={this.handleLoginInputs}
              handleLogin={this.handleLogin}
              />
          </FixedContainer>
          
        </SubWrapper>
      </Wrapper>
    )
  }
}
