import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Wrapper, SubWrapperAlt } from '../../theme/style/styles'
import Tabhead from '../../Components/TabHead'
import Insights from '../../Components/Insights'

export default class index extends Component {
  componentDidMount () {
    this.props.handleFetchAllGeneralInsights()
    this.props.handleFetchAllHousesInsights()
    this.props.handleFetchAllLocationInsights()
    this.props.handleFetchAllFinanceInsights()
  }
  render () {
    return (
      <Wrapper>
        {/*
         ==========================================================================
         React-Helmet
         ==========================================================================
        */}
        <Helmet>
          <meta charSet='utf-8' />
          <title> Insights || Admin Dashboard </title>
        </Helmet>
        <SubWrapperAlt>
          <Tabhead
            title='insight'
          />
          <Insights insights={this.props.insights} />
        </SubWrapperAlt>
      </Wrapper>
    )
  }
}
