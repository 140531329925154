import styled from 'styled-components'

export const Wrapper = styled.div`
width: 100%;
height: 56px;
background: ${props => props.theme.transparent};
display:flex;
justify-content: space-between;
align-items: center;
margin: ${props => props.margin};
padding: ${props => props.padding};
`
Wrapper.defaultProps = {
  margin: '0 0 40px'
}
export const SubWrapper = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
height: 100%;
margin: ${props => props.margin};
`
SubWrapper.defaultProps = {
  margin: '0'
}