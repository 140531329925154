import React from 'react'
import * as Page from '../../styles'
import { CardHeader } from '../../../../theme/style/typeface'
import { MainAltLabel, MainAltLabelFormatter } from '../../../Input'
import { PlatformButton } from '../../../Button'

const Finances = ({ rate, state, handleRateValue, handlePostNewRate, handleRateCancel }) => {
  return (
    <Page.Details style={{ margin: '0 auto' }}>
      <Page.Wrapper>
        <Page.ContainerAlt>
          <CardHeader padding='32px 75px' >
            Interest rate
          </CardHeader>
          <Page.Underline />
          <Page.ContainerAlt>
            <Page.Container style={{ padding: '64px 60px 0' }}>
              <MainAltLabel value={`${rate} %`} style={{cursor:'auto'}} width='98px' margin='0 0 0 24px' placeholder='rate' label='Current Interest Rate:' fontSize='18px' disabled altBorder='rgba(0, 0, 0, 0.301008)'/>
            </Page.Container>
            <Page.Container style={{ padding: '34px 60px 0' }}>
              <MainAltLabelFormatter changed={handleRateValue} value={state.newRate} width='98px' height='40px' margin='0 0 0 50px' label='Edit Interest Rate:' placeholder='Set rate' /> <PlatformButton content='Set' clicked={handlePostNewRate} loading={state.loading} margin='0 16px' /> <PlatformButton content='Cancel' clicked={handleRateCancel} alt='true' margin='0 16px'/>
            </Page.Container>
          </Page.ContainerAlt>
        </Page.ContainerAlt>
      </Page.Wrapper>
    </Page.Details>
  )
}
export default Finances
