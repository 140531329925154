import React from 'react'
import * as Profile from '../../styles'
import { UserBonusTable } from '../../../Table'
import { CardHeader } from '../../../../theme/style/typeface'

const TransactionsHistory = ({ user }) => {
  return (
    <Profile.Details>
      <Profile.Wrapper alt>
        <Profile.ContainerAlt>
          {user.bonus && user.bonus.length
            ? <UserBonusTable title=' ' data={user.bonus} /> : <CardHeader padding='0' style={{ margin: '0 auto', textAlign: 'center' }}>
              <p style={{ margin: '0 auto', textAlign: 'center' }}>User Has No Bonus Information</p>
            </CardHeader>}
        </Profile.ContainerAlt>
      </Profile.Wrapper>
    </Profile.Details>
  )
}
export default TransactionsHistory
