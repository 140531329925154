import React from 'react'
import * as Profile from '../../styles'
import { AccountInfo } from '../../../Forms'
import { UserDepositTableSmall, UserWithdrawalTableSmall } from '../../../Table'

const TransactionsHistory = ({ user }) => {
  // const deposit = user.deposits
  // const withdrawals = user.withdraws
  // const [pages, setPages] = useState({
  //   firstPage: 1,
  //   currentPage: 1,
  //   usersPerPage: 4,
  //   withdrawals: false,
  //   deposit: false
  // })

  // const handlePagnationUp = (e) => {
  //   e.target.blur()
  //   if (pages.currentPage >= Math.ceil(deposit.length / 9)) {
  //     console.log('limit!!!')
  //     return null
  //   }
  //   if (pages.currentPage >= pages.firstPage) {
  //     console.log(Math.ceil(deposit.length / 9))
  //     return setPages(pages => ({ ...pages, currentPage: pages.currentPage + 1 }))
  //   }
  // }
  // const handlePagnationDown = (e) => {
  //   e.target.blur()
  //   return setPages(pages => ({ ...pages, currentPage: pages.currentPage - 1 }))
  // }
  // const handleShowFullDeposit = e => {
  //   e.target.blur()
  //   return setPages(pages => ({ ...pages, currentPage: 1, withdrawals: false, deposit: true, usersPerPage: 5 }))
  // }
  // const handleShowFullWithdrawals = e => {
  //   e.target.blur()
  //   return setPages(pages => ({ ...pages, currentPage: 1, withdrawals: true, deposit: false, usersPerPage: 5 }))
  // }
  // const indexOfLastUser = pages.currentPage * pages.usersPerPage
  // const indexOfFirstUser = indexOfLastUser - pages.usersPerPage
  // const currentDeposits = deposit.slice(indexOfFirstUser, indexOfLastUser)
  // const currentWithdrawals = withdrawals.slice(indexOfFirstUser, indexOfLastUser)
  // const allUsers = deposit.length
  // let newindexOfFirstUser = indexOfFirstUser + 1
  // let pageDeposits = currentDeposits.length + indexOfFirstUser
  // let pageWithdrawals = currentWithdrawals.length + indexOfFirstUser
  return (
    <Profile.Details>
      <Profile.Wrapper alt>
        <Profile.ContainerAlt>
          <AccountInfo usersBank={user.usersBank} usersCard={user.usersCard} />
          <UserDepositTableSmall
            title='deposit'
            data={user.deposits}
          />
          <UserWithdrawalTableSmall
            title='withdrawals'
            data={user.withdraws}
          />
        </Profile.ContainerAlt>
      </Profile.Wrapper>
    </Profile.Details>
  )
}
export default TransactionsHistory
