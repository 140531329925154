import type from '../Type'
import axios from 'axios'
import { handleError } from '../../lib'

const { GET_ALL_USERS, GET_USER_ACTIVITY, GET_USER_INFO, GET_USER_HISTORY, GET_USER_BONUS } = type

const getAllUser = payload => ({
  type: GET_ALL_USERS,
  payload
})
const getUserActivity = payload => ({
  type: GET_USER_ACTIVITY,
  payload
})
const getUserDetails = payload => ({
  type: GET_USER_INFO,
  payload
})
const getUserHistory = payload => ({
  type: GET_USER_HISTORY,
  payload
})
const getUserBonus = payload => ({
  type: GET_USER_BONUS,
  payload
})

export const handleFetchAllUser = () => dispatch => {
  return axios
    .get(`/users`)
    .then(res => {
      let result = res.data
      dispatch(getAllUser(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}

export const handleFetchUser = userId => dispatch => {
  return axios
    .get(`/users/${userId}`)
    .then(res => {
      let result = res.data
      dispatch(getUserDetails(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleFetchUserByParams = (month, year) => dispatch => {
  let result = { data: [] }
  dispatch(getAllUser(result))
  return axios
    .get(`/users?month=${month}&year=${year}`)
    .then(res => {
      let result = res.data
      dispatch(getAllUser(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleFetchUserActivity = userId => dispatch => {
  return axios
    .get(`/users/activity/${userId}`)
    .then(res => {
      let result = res.data
      dispatch(getUserActivity(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleFetchUserHistory = userId => dispatch => {
  return axios
    .get(`/users/history/${userId}`)
    .then(res => {
      let result = res.data
      dispatch(getUserHistory(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleFetchUserBonus = userId => dispatch => {
  return axios
    .get(`/users/referrals/${userId}`)
    .then(res => {
      let result = res.data
      dispatch(getUserBonus(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}