import styled from 'styled-components'

export const SideNav = styled.aside`
  height: 100%; 
  width: 300px; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: ${props => props.theme.background};
  overflow: hidden; 
  padding-top: 120px; 
  transition: 0.5s;
  padding-left: 80px;
a {
  // padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #9a9a9a;
  display: block;
  transition: 0.3s;
}
/* When you mouse over the navigation links, change their color */
 a:hover {
  color: ${props => props.theme.text};
}
ul {
  display: flex;
  align-self: flex-start;
  list-style: none;
  justify-content: space-between;
  flex-direction: column;
  padding:0;
  width:200px;
}

 ul li {
  // margin: 0 5px;
  /* padding: 0 10px; */
  font-size: 14px;
  text-transform: capitalize;
  width: 200px;
  height: 50px;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  
}

 ul li a {
  text-decoration: none;
  color: #9a9a9a;
}

 ul li a span {
  margin: 10px;
  // padding: 0 5px;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: capitalize;
}

 ul li.active {
  border-radius: 5px;
  background-color: ${props => props.theme.white};
  color: #0BA4FE;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

 ul li.active a span {
  color: ${props => props.theme.black};
  /* font-size: 14px; */
}

 ul li img {
  margin: 5px;
  margin-left: 10px;
  width: 20px;
  height: 20px;
}
`