import React from 'react'
// import { SelectTrio } from '../../../Forms'
import * as Page from '../../styles'
import { CardHeader } from '../../../../theme/style/typeface'
import { Select, Main } from '../../../Input'
import LocationTable from '../../Modal/Location/LocationsTable'
import Button from '../../../Button'
import AddLocationModal from '../../Modal/Location/AddLocation'
import EditLocationModal from '../../Modal/Location/EditLocation'
import DeleteLocationModal from '../../Modal/Location/DeleteLocation'

const Locations = ({ inputs, handleShowDeleteLocationModal, handleDeleteLocation, handleSetLocation, handleShowEditLocationModal, handleEditLocation, handleShowLocationTableModal, handleShowAddLocationModal, handleLocationInput, handleAddNewLocation, location, handleGetState, handleGetStateUsers, handleGetLocaleUsers }) => {
  const { countries, states, usersPerState, locales, usersPerLocale, allLocations } = location
  return (
    <Page.Details style={{ margin: '0 auto' }}>
    
      <Page.Wrapper >
        <Page.ContainerAlt>
          <CardHeader padding='32px 75px' >
              Available house types
          </CardHeader>
          <Page.Underline />
          <Page.Container style={{ padding: '64px 0 64px 60px' }}>
            <Select changed={handleGetState} label='Country' altArrow width='194px' height='50px'>
              <option hidden>Country</option>
              {countries && countries.length > 0 ? countries.map(country => (
                <option key={country._id} value={country.country}>{country.country}</option>
              )) : null}
            </Select>
            {states && states.length > 0 ? <Select changed={handleGetStateUsers} label='State / Province' altArrow width='194px' height='50px'>
              <option hidden>State</option>
              { states.map((state, index) => (
                <option key={index || Math.random()} value={state}>{state}</option>
              ))}
            </Select> : null }
            {usersPerState && usersPerState !== null && usersPerState !== undefined && usersPerState !== '' ? <Main label='Users' value={usersPerState} fontSize='16px' width='100px' height='50px' disabled /> : null }
            {locales && locales.length > 0 ? <Select changed={handleGetLocaleUsers} label='Local Government' altArrow width='194px' height='50px'>
              <option hidden>Locations</option>
              { locales.map(locale => (
                <option key={locale._id} value={locale.location}>{locale.location}</option>
              ))}
            </Select> : null }
            {usersPerLocale && usersPerLocale !== null && usersPerLocale !== undefined && usersPerLocale !== '' ? <Main label='Users' value={usersPerLocale} width='100px' height='50px' disabled /> : null }
          </Page.Container>
          <Page.Container style={{ justifyContent: 'flex-end' }}>
            <Button content='Edit Location' margin='50px auto 0' clicked={handleShowLocationTableModal} />
          </Page.Container>
        </Page.ContainerAlt>
      </Page.Wrapper>
      <AddLocationModal
        show={inputs.showAddLocation}
        onHide={handleShowAddLocationModal}
        handleLocationInput={handleLocationInput}
        handleAddNewLocation={handleAddNewLocation}
        inputs={inputs}
      />
      <LocationTable
        show={inputs.showEditLocaionTable}
        onHide={handleShowLocationTableModal}
        inputs={inputs}
        location={allLocations}
        handleSetLocation={handleSetLocation}
      />
      <EditLocationModal
        show={inputs.showEditLocation}
        onHide={handleShowEditLocationModal}
        handleLocationInput={handleLocationInput}
        handleEditLocation={handleEditLocation}
        inputs={inputs}
      />
      <DeleteLocationModal
          show={inputs.showDeleteLocation}
          onHide={handleShowDeleteLocationModal}
          handleDeleteLocation={handleDeleteLocation}
          inputs={inputs}
      />
    </Page.Details>

  )
}
export default Locations
