import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Wrapper, SubWrapperAlt } from '../../theme/style/styles'
import { CSVButton } from '../../Components/Button'
import Tabhead from '../../Components/TabHead'
import Transactions from '../../Components/Transactions'
import { ExportToCsv } from 'export-to-csv'
export default class index extends Component {
  state = {
    selected:'deposits',
    month:'',
    year:'',
    spinner: true,
    cancel:false
  }


  
  handleSelected = selected =>(
    this.setState({selected,  month:'',
    year:'',
    spinner: true,
    cancel:false },
    ()=>{
      this.props.handleFetchAllDeposits()
      this.props.handleFetchAllWithdrawals()
    }
    )
  )

  handleSetDate = e =>{
    this.setState({[e.target.name]: e.target.value},()=> this.handleFetchTransactions())
    }
    
    handleFetchTransactions =()=>{
      const date = new Date()
      let month = date.toLocaleString('default', { month: 'long' })
      let year = date.getFullYear()
      if (this.state.month !== ''){
        month = this.state.month
      }
      if(this.state.year !== ''){
        year = this.state.year
      }
      this.setState({month, year, spinner: true, cancel:true})
      if(this.state.selected === 'withdrawals'){
        setTimeout(() => {
          this.props.handleFetchWithdrawalsByParams(month, year)
          .then(res=>{
            setTimeout(() => {
              this.setState({spinner: false})
            }, 5000);
          })
        }, 1000);
        return
      }
      setTimeout(() => {
        this.props.handleFetchDepositsByParams(month, year)
        .then(res=>{
          setTimeout(() => {
            this.setState({spinner: false})
          }, 5000);
        })
      }, 1000);
    }
    
    handleCancel =()=>{
      if(this.state.selected === 'withdrawals'){
        this.props.handleFetchAllWithdrawals()
        .then(res=>{
          this.setState({spinner: false, cancel:false, month:'', year:''})
        })
        return
      }
      this.props.handleFetchAllDeposits()
      .then(res=>{
        this.setState({spinner: false, cancel:false, month:'', year:''})
      })
      return
    }
  componentDidMount () {
    this.props.handleFetchAllDeposits()
    this.props.handleFetchAllWithdrawals()
    this.props.handleFetchAllInsights()
      setTimeout(() => {
            this.setState({spinner: false})
          }, 10000);
  }
  render () {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Transactions CSV',
      useTextFile: false,
      useBom: true,
      filename:'all-transactions',
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', 'Column 3', 'Column 4', 'Column 5' ] // <-- Won't work with useKeysAsHeaders present!
    }
    const csvExporter = new ExportToCsv(options)
    let csvButton 
    if(this.state.selected === 'deposits'){
      csvButton = (
        <CSVButton
          onClick={()=> csvExporter.generateCsv(this.props.transactions.deposits)}
          />
      )
    }
    if(this.state.selected === 'withdrawals'){
      csvButton = (
        <CSVButton
          onClick={()=> csvExporter.generateCsv(this.props.transactions.withdrawals)}
          />
      )
    }
    return (
      <Wrapper>
        <Helmet>
          <meta charSet='utf-8' />
          <title> Transactions || Admin Dashboard</title>
        </Helmet>
        <SubWrapperAlt>
          <Tabhead
            title='Transactions'
            showDate
            handleSetDate={this.handleSetDate}
            inputs={this.state}
            handleCancel={this.handleCancel}
          />
          {csvButton}
          <Transactions transactions={this.props.transactions} handleSelected={this.handleSelected} inputs={this.state} />
        </SubWrapperAlt>
      </Wrapper>
    )
  }
}
