import React from 'react'
import { SubWrapperAlt } from '../../theme/style/styles'

import { UploadGalleryDropZone } from '../Tools/Dropzone'
const GalleryUpload = ({ setImages, handleUploadImage, inputs, handleShowUploadImages, ResetUploaded, ...props }) =>
  <SubWrapperAlt style={{ alignItems: 'flex-start' }} padding='0' {...props}>
    <UploadGalleryDropZone
      uploaded={inputs.uploaded}
      loading={inputs.loading}
      handleUploadImage={handleUploadImage}
      galleryUpload={setImages}
      handleShowUploadImages={handleShowUploadImages}
      ResetUploaded={ResetUploaded}
    />
  </SubWrapperAlt>

export default GalleryUpload
