import React from 'react'
import * as Page from '../../styles'
import { TransactionsWithdrawalTable } from '../../../Table'
import { CardHeader } from '../../../../theme/style/typeface'
import GoldSpinner from '../../../Tools/GoldSpinner'
const index = ({ data, inputs }) => {
  let spinner = inputs.spinner ? <GoldSpinner /> : <CardHeader style={{ justifyContent: 'center' }} padding='0'>
  No Withdrawals Found
  </CardHeader>
  return (
    <Page.Details style={{ margin: '0 auto' }}>
      <Page.Wrapper alt="true">
        <Page.ContainerAlt>

          {data && data.length > 0
            ? <TransactionsWithdrawalTable
              title
              setPagination
              data={data}
            />
            : spinner }
        </Page.ContainerAlt>
      </Page.Wrapper>
    </Page.Details>
  )
}
export default index
