import React from 'react'
import AddAdminModal from './Modal/AddAdmin'
import EditAdminModal from './Modal/EditAdmin'
import DeleteAdminModal from './Modal/DeleteAdmin'
import * as Page from './styles'
import { AdminsTable } from '../Table'

const Admins = ({
  admins,
  inputs,
  handleShowAdminModal,
  handleOnChange,
  handleAddAdmin,
  handleShowEditAdminModal,
  handleEditAdmin,
  handleSetAdmin,
  handleShowDeleteAdminModal,
  handleDeleteAdmin,
  handleCheckbox
}) => {
  return (

    <Page.Details style={{ margin: '0 auto' }}>
      <Page.Wrapper alt >
        <Page.Container>
          <AdminsTable
            title
            setPagination
            data={admins}
            handleSetAdmin={handleSetAdmin}
          />
        </Page.Container>
      </Page.Wrapper>

      {/*
         ==========================================================================
          Admin Modals
         ==========================================================================
        */}
      <AddAdminModal
        show={inputs.showAddAdmin}
        onHide={handleShowAdminModal}
        inputs={inputs}
        handleOnChange={handleOnChange}
        handleAddAdmin={handleAddAdmin}
        handleCheckbox={handleCheckbox}
      />
      <EditAdminModal
        show={inputs.showEditAdmin}
        onHide={handleShowEditAdminModal}
        inputs={inputs}
        handleOnChange={handleOnChange}
        handleEditAdmin={handleEditAdmin}
        handleCheckbox={handleCheckbox}
      />
      <DeleteAdminModal
        show={inputs.showDeleteAdmin}
        onHide={handleShowDeleteAdminModal}
        inputs={inputs}
        handleDeleteAdmin={handleDeleteAdmin}
      />
    </Page.Details>

  )
}

export default Admins
