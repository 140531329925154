import Navbar from '../../Components/Navbar'
import { connect } from 'react-redux'


/**
 * here we handle passing redux to our component and export
 */
const mapStateToProps = state => ({
  Admin: state.Auth.AdminData
})
export default connect(mapStateToProps, null)(Navbar)