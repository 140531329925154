import Admins from '../../Pages/Admins'
import { connect } from 'react-redux'
import { handleFetchAllAdmins, handleAddAdmin, handleAccessChange, handleDelete, handleUploadImage } from '../../Store/Actions'

/**
 * here we handle passing redux to our component and export
 */
const mapStateToProps = state => ({
  admins: state.Admin.admins.admins
})

const mapDispatchToProps = dispatch => ({
  handleFetchAllAdmins () {
    return dispatch(handleFetchAllAdmins())
  },
  handleAddAdmin (admin) {
    return dispatch(handleAddAdmin(admin))
  },
  handleUploadImage (image) {
    return dispatch(handleUploadImage(image))
  },
  handleAccessChange (privilegde, id) {
    return dispatch(handleAccessChange(privilegde, id))
  },
  handleDelete (id) {
    return dispatch(handleDelete(id))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Admins)
