import React from 'react'
import { PlatformHousesTable } from '../../../Table'
import * as Page from '../../styles'
import AddHouseModal from '../../Modal/Houses/AddHouse'
import EditHouseModal from '../../Modal/Houses/EditHouse'
import DeleteHouseModal from '../../Modal/Houses/DeleteHouse'

const Houses = ({ inputs, handleShowAddModal, houses, handleOnChange, handleImageChange, handleAddNewHouse, handleShowEditModal, handleEditNewHouse, handleSetHouse, handleShowDeleteModal, handleDeleteHouse }) => {
  return (
    <Page.Details style={{ margin: '0 auto' }}>
      <Page.Wrapper alt='true'>
        <Page.Container>
          <PlatformHousesTable
            title
            setPagination
            data={houses}
            handleSetHouse={handleSetHouse}
          />
        </Page.Container>
      </Page.Wrapper>
      <AddHouseModal
        show={inputs.showAddHouse}
        onHide={handleShowAddModal}
        inputs={inputs}
        handleOnChange={handleOnChange}
        handleImageChange={handleImageChange}
        handleAddNewHouse={handleAddNewHouse}
      />
      <EditHouseModal
        show={inputs.showEditHouse}
        onHide={handleShowEditModal}
        inputs={inputs}
        handleOnChange={handleOnChange}
        handleImageChange={handleImageChange}
        handleEditNewHouse={handleEditNewHouse}
      />
      <DeleteHouseModal
        show={inputs.showDeleteHouse}
        onHide={handleShowDeleteModal}
        inputs={inputs}
        handleDeleteHouse={handleDeleteHouse}
      />
    </Page.Details>
  )
}
export default Houses
