import styled from 'styled-components'


export const Pair = styled.div`
    display: flex;
    
`
export const PairAlt = styled(Pair)`
flex-direction: column;
`
export const PairSpace = styled(Pair)`
margin-top:0;
justify-content: space-between;
align-items: center;
`
export const NameContainer = styled.div`
width:100%;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
margin: .5rem;
`
export const IMG = styled.img`
    margin-left: 4px;
`

export const NameSpan = styled.span`
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
/* identical to box height */
color: ${props => props.theme.title};
`
export const EmailSpan = styled(NameSpan)`
font-size: 12px;
line-height: 15px;
`