import type from '../Type'
import axios from 'axios'
import { handleError } from '../../lib'
import swal from 'sweetalert'

const { GET_ADMINS } = type

const handleGetAdmins = payload => ({
  type: GET_ADMINS,
  payload
})
export const handleFetchAllAdmins = () => dispatch => {
  return axios
    .get(`/all`)
    .then(res => {
      let result = res.data
      dispatch(handleGetAdmins(result))
    }).catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleAddAdmin = data => dispatch => axios
  .post(`/create`, data)
  .then(res => {
    let result = res.data
    swal('New Admin created', `${result.data.message}`, 'success')
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
  })

export const handleAccessChange = (privilegde, id) => dispatch => axios
  .put(`/privilege/${id}`, privilegde)
  .then(res => {
    let result = res.data
    swal('Admin Priviledge', `${result.data.message}`, 'success')
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
    // return err
  })
  export const handleDelete = id => dispatch => axios
  .delete(`/delete/${id}`)
  .then(res => {
    let result = res.data
    swal('Admin Deleted', `${result.data.message}`, 'success')
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
    // return err
  })

export const handleUploadImage = image => dispatch => {
  let formData = new FormData()
  formData.append('picture', image)
  return axios
    .post(`/upload`, formData)
    .then(res => {
      let result = res.data
      // swal('New Admin created', `${result.data.message}`, 'success' )
      return result
    }).catch(err => {
      handleError(err)
      console.log(err)
    })
}
