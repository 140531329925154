import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { CardHeader } from '../../../../theme/style/typeface'
import { Logo } from '../../../Picture'
import { Main, SelectAlt } from '../../../Input'
import * as ModalStyle from '../../styles'
import Button from '../../../Button'

const AddBonus = ({ inputs, handleBonusInput, handleAddNewBonus, ...props }) => {
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <Form onSubmit={handleAddNewBonus}>
          <CardHeader
            padding='0'
            style={{ borderBottom: '2px solid rgba(238, 238, 238, .2)' }}>
              Add new bonus
            <Logo width='22px' height='22px' margin='0' src={require('../../../../assets/images/close-alt_blue.svg')} clicked={props.onHide} />
          </CardHeader>
          <ModalStyle.ContainerAlt>
            <ModalStyle.Container style={{ justifyContent: 'space-around' }}>
              <Main
                type='text'
                placeholder='Bonus Name'
                name='bonusName'
                value={inputs.bonus.bonusName}
                width='206px'
                height='50px'
                margin='0'
                altBorder='#D0D5DA'
                changed={handleBonusInput}
                fontSize='14px'
              />
              <Main
                type='text'
                placeholder='Amount'
                name='bonusAmount'
                value={inputs.bonus.bonusAmount}
                width='206px'
                height='50px'
                margin='0'
                altBorder='#D0D5DA'
                changed={handleBonusInput}
                fontSize='14px'
              />
            </ModalStyle.Container>
            <ModalStyle.Container >
              <SelectAlt
                name='bonusStatus'
                width='206px'
                height='50px'
                changed={handleBonusInput}
                fontSize='14px'
                altArrow
                margin='16px'
              >
                <option hidden>Status</option>
                <option value='active'>Active</option>
                <option value='inactive'>Inactive</option>
              </SelectAlt>
            </ModalStyle.Container>
          </ModalStyle.ContainerAlt>
          <ModalStyle.Container>
            <Button type='submit' loading={inputs.loading} margin='32px auto 50px' content='Save' fontSize='16px' borderRadius='4px' />
          </ModalStyle.Container>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default AddBonus
