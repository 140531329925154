import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const DoughnutChart = ({ data }) => {
  return (
    <Doughnut
      data={data}
      height={173}
      width={173}
      id='doughnut'
      options={
        {
          legend: {
            display: false
          },
          responsive: false,
          maintainAspectRatio: false
        }
      }
    />
  )
}

export default DoughnutChart
