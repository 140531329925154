import Transactions from '../../Pages/Transactions'
import { connect } from 'react-redux'
import { handleFetchAllDeposits, handleFetchAllWithdrawals, handleFetchAllInsights, handleFetchDepositsByParams, handleFetchWithdrawalsByParams } from '../../Store/Actions'

/**
 * here we handle passing redux to our component and export
 */
const mapStateToProps = state => ({
  transactions: state.Admin.transactions
})

const mapDispatchToProps = dispatch => ({
  handleFetchAllDeposits () {
    return dispatch(handleFetchAllDeposits())
  },
  handleFetchAllWithdrawals () {
    return dispatch(handleFetchAllWithdrawals())
  },
  handleFetchAllInsights () {
    return dispatch(handleFetchAllInsights())
  },
  handleFetchDepositsByParams (month, year) {
    return dispatch(handleFetchDepositsByParams(month, year))
  },
  handleFetchWithdrawalsByParams (month, year) {
    return dispatch(handleFetchWithdrawalsByParams(month, year))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)
