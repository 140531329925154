import React from 'react'

import * as Page from '../../styles'
import { ActivityCard } from '../../../Card'
import { formatAmount } from '../../../Tools/Formatter'


const Insights = ({ data }) => {
  const { highestDeposit, highestWithdrawal, averageWithdrawPerWeek, averageWithdrawalPerMonth, averageDepositPerMonth, averageDepositPerWeek, totalNumberOfSavings, totalNumberOfWithdrawals  } = data
  return (
    <Page.Details style={{margin:'0 auto'}}>
      <Page.Wrapper alt="true">
        <Page.ContainerAlt>
          <Page.Container className='activity-container'>
            <ActivityCard title='Highest deposit' number={`₦ ${formatAmount(highestDeposit)}`} />
            <ActivityCard title='Average deposits per week' number={`₦ ${formatAmount(averageDepositPerWeek)}`} />
            <ActivityCard title='Average deposits per month' number={`₦ ${formatAmount(averageDepositPerMonth)}`} />
            <ActivityCard title='Total number of savings' number={`${totalNumberOfSavings}`} hide />
            <ActivityCard title='Highest withdrawal' number={`₦ ${formatAmount(highestWithdrawal)}`} negative />
            <ActivityCard title='Average withdrawals per week' number={`₦ ${formatAmount(averageWithdrawPerWeek)}`} negative />
            <ActivityCard title='Average withdrawals per month' number={`₦ ${formatAmount(averageWithdrawalPerMonth)}`} negative />
            <ActivityCard title='Total number of withdrawals' number={`${formatAmount(totalNumberOfWithdrawals)}`} hide />
          </Page.Container>
        </Page.ContainerAlt>
      </Page.Wrapper>
    </Page.Details>
  )
}
export default Insights
