import styled from 'styled-components'


export const Details = styled.div`
margin: 30px auto;
`
export const Wrapper = styled.section`
width:960px;
min-height:820px;
background:${props => props.alt ? props.theme.transparent : props.theme.white};

`
export const Container = styled.div`
display:flex;
justify-content:flex-start;
align-items:flex-start;
width:100%;
min-height:100%;
&.activity-container{
  flex-wrap:wrap;
}
`
export const ContainerAlt = styled(Container)`
flex-direction: column;
justify-content:center;
align-items:center;
`