import styled from 'styled-components'

export const Wrapper = styled.div`
background: ${props => props.theme.background}
min-height: 100vh;
width: 100%;
`

export const SubWrapper = styled.div`
width:100%;
height:100%;
display: flex;
justify-content: ${props => props.justifyContent ? props.justifyContent : 'center' };
align-items:${props => props.alignItems ? props.alignItems : 'center' };
padding:${props => props.padding ? props.padding : '60px 0 '};
flex-wrap ${props => props.flexWrap ? props.flexWrap : 'wrap'};
margin:0 auto;

`
export const SubWrapperAlt = styled(SubWrapper)`
width:100%;
height:100%;
flex-direction:column;
align-items: center;
padding:${props => props.padding ? props.padding : '60px 0 '};
flex-wrap ${props => props.flexWrap ? props.flexWrap : 'wrap'};
`

export const ListWrapper = styled(SubWrapper)`
justify-content:flex-start;
flex-wrap ${props => props.flexWrap ? props.flexWrap : 'wrap'};
padding:${props => props.padding ? props.padding : ' 0 '};
&.galley_list{
  height: 400px;
  width:100%;
  overflow-y:auto;
  padding:0 20px !important;
  align-items: flex-start;
}
`