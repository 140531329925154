import React from 'react'
import * as Profile from '../../styles'
import { UserDetailsCard, ActivityCard } from '../../../Card'
import { formatAmount } from '../../../Tools/Formatter'


const Activity = ({ user }) => {
  const { preferredHouseType, location, houseValue } = user.activity.propertyDetails
  const { totalSavings, totalVoluntarySavings, mandatorySavings, contigentLiability, totalSkippedMonths } = user.activity.activity
  return (
    <Profile.Details>
      <Profile.Wrapper alt='true'>
        <Profile.ContainerAlt>
          <Profile.Container className='activity-container'>
            <ActivityCard title='Total savings' number={`₦ ${formatAmount(totalSavings || 0)}`} />
            <ActivityCard title='Voluntary savings' number={`₦ ${formatAmount(totalVoluntarySavings || 0)}`} />
            <ActivityCard title='Contractual Savings' number={`₦ ${formatAmount(mandatorySavings || 0)}`} />
            <ActivityCard title='Total number of skipped months' number={`${totalSkippedMonths}`} hide />
            <ActivityCard title='Total contingent liability' number={`₦ ${formatAmount(contigentLiability || 0)}`} />
          </Profile.Container>

          <UserDetailsCard
            title='Property details'
            labelOne={'Preferred house type'}
            labelTwo={'Location'}
            labelThree={'House value'}
            infoOne={preferredHouseType}
            infoTwo={location}
            infoThree={`₦ ${formatAmount(houseValue || 0)}`}
            hidelineOne
            hideRowTwo
            hideRowThree
            hideRowFour
          />
        </Profile.ContainerAlt>
      </Profile.Wrapper>
    </Profile.Details>
  )
}
export default Activity
