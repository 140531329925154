
import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import * as PageTable from './styles'
import { TableHead } from './TableParts'
import { PlatformHousesList, PlatformBlogList, PlatformLocationList, PlatformBonusList } from './TableList'
import { CardHeader } from '../../theme/style/typeface'
import Pagination from '../Tools/Pagination'

export const PlatformHousesTable = ({ title, data, setPagination, handleSetHouse }) => {
  let header
  if (title) {
    header = (
      <CardHeader
        padding='15px 56px'>
        {title}
      </CardHeader>
    )
  }
  const [pages, setPages] = useState({
    firstPage: 1,
    currentPage: 1,
    usersPerPage: 9
  })

  const handlePagnationUp = (e) => {
    e.target.blur()
    if (pages.currentPage >= Math.ceil(data.length / pages.usersPerPage)) {
      return null
    }
    if (pages.currentPage >= pages.firstPage) {
      return setPages(pages => ({ ...pages, currentPage: pages.currentPage + 1 }))
    }
  }
  const handlePagnationDown = (e) => {
    e.target.blur()
    return setPages(pages => ({ ...pages, currentPage: pages.currentPage - 1 }))
  }

  const indexOfLastUser = pages.currentPage * pages.usersPerPage
  const indexOfFirstUser = indexOfLastUser - pages.usersPerPage
  const currentUsers = data.slice(indexOfFirstUser, indexOfLastUser)
  const allUsers = data.length
  let newindexOfFirstUser = indexOfFirstUser + 1
  let pageUsers = currentUsers.length + indexOfFirstUser
  return (
    <PageTable.Wrapper
      padding='20px 0 0'
    >
      <PageTable.Container>
        {header}
        <Table >
          <TableHead>
            <th>image</th>
            <th>house type</th>
            <th>description</th>
            <th>edit/delete</th>
          </TableHead>
          <tbody>
            <PlatformHousesList houses={currentUsers} handleSetHouse={handleSetHouse} />
          </tbody>

        </Table>
        { setPagination
          ? <Pagination
            data={data}
            pages={pages}
            allUsers={allUsers}
            pageUsers={pageUsers}
            newindexOfFirstUser={newindexOfFirstUser}
            handlePagnationUp={handlePagnationUp}
            handlePagnationDown={handlePagnationDown}
          />
          : null}
      </PageTable.Container>
    </PageTable.Wrapper>
  )
}

export const PlatformBlogTable = ({ title, data, setPagination, handleSetBlog }) => {
  let header
  if (title) {
    header = (
      <CardHeader
        padding='15px 56px'>
        {title}
      </CardHeader>
    )
  }
  const [pages, setPages] = useState({
    firstPage: 1,
    currentPage: 1,
    usersPerPage: 9
  })

  const handlePagnationUp = (e) => {
    e.target.blur()
    if (pages.currentPage >= Math.ceil(data.length / pages.usersPerPage)) {
      return null
    }
    if (pages.currentPage >= pages.firstPage) {
      return setPages(pages => ({ ...pages, currentPage: pages.currentPage + 1 }))
    }
  }
  const handlePagnationDown = (e) => {
    e.target.blur()
    return setPages(pages => ({ ...pages, currentPage: pages.currentPage - 1 }))
  }

  const indexOfLastUser = pages.currentPage * pages.usersPerPage
  const indexOfFirstUser = indexOfLastUser - pages.usersPerPage
  const currentUsers = data.slice(indexOfFirstUser, indexOfLastUser)
  const allUsers = data.length
  let newindexOfFirstUser = indexOfFirstUser + 1
  let pageUsers = currentUsers.length + indexOfFirstUser
  return (
    <PageTable.Wrapper
      padding='20px 0 0'
    >
      <PageTable.Container>
        {header}
        <Table >
          <TableHead>
            <th>blog title</th>
            <th>publish date</th>
            <th>views</th>
            <th>edit/delete</th>
          </TableHead>
          <tbody>
            <PlatformBlogList blogs={currentUsers} handleSetBlog={handleSetBlog} />
          </tbody>

        </Table>
        { setPagination
          ? <Pagination
            data={data}
            pages={pages}
            allUsers={allUsers}
            pageUsers={pageUsers}
            newindexOfFirstUser={newindexOfFirstUser}
            handlePagnationUp={handlePagnationUp}
            handlePagnationDown={handlePagnationDown}
          />
          : null}
      </PageTable.Container>
    </PageTable.Wrapper>
  )
}

export const PlatformLocationTable = ({ title, data, setPagination, handleSetLocation }) => {
  let header
  if (title) {
    header = (
      <CardHeader
        padding='15px 56px'>
        {title}
      </CardHeader>
    )
  }
  const [pages, setPages] = useState({
    firstPage: 1,
    currentPage: 1,
    usersPerPage: 5
  })

  const handlePagnationUp = (e) => {
    e.target.blur()
    if (pages.currentPage >= Math.ceil(data.length / pages.usersPerPage)) {
      return null
    }
    if (pages.currentPage >= pages.firstPage) {
      return setPages(pages => ({ ...pages, currentPage: pages.currentPage + 1 }))
    }
  }
  const handlePagnationDown = (e) => {
    e.target.blur()
    return setPages(pages => ({ ...pages, currentPage: pages.currentPage - 1 }))
  }

  const indexOfLastUser = pages.currentPage * pages.usersPerPage
  const indexOfFirstUser = indexOfLastUser - pages.usersPerPage
  const currentUsers = data.slice(indexOfFirstUser, indexOfLastUser)
  const allUsers = data.length
  let newindexOfFirstUser = indexOfFirstUser + 1
  let pageUsers = currentUsers.length + indexOfFirstUser
  return (
    <PageTable.Wrapper
      padding='20px 0 0'
    >
      <PageTable.Container>
        {header}
        <Table >
          <TableHead>
            <th>country</th>
            <th>state</th>
            <th>local goverment </th>
            <th>edit/delete</th>
          </TableHead>
          <tbody>
            <PlatformLocationList locations={currentUsers}handleSetLocation={handleSetLocation} />
          </tbody>

        </Table>
        { setPagination
          ? <Pagination
            data={data}
            pages={pages}
            allUsers={allUsers}
            pageUsers={pageUsers}
            newindexOfFirstUser={newindexOfFirstUser}
            handlePagnationUp={handlePagnationUp}
            handlePagnationDown={handlePagnationDown}
          />
          : null}
      </PageTable.Container>
    </PageTable.Wrapper>
  )
}
export const PlatformBonusTable = ({ title, data, setPagination, handleSetBonus }) => {
  let header
  if (title) {
    header = (
      <CardHeader
        padding='15px 56px'>
        {title}
      </CardHeader>
    )
  }
  const [pages, setPages] = useState({
    firstPage: 1,
    currentPage: 1,
    usersPerPage: 5
  })

  const handlePagnationUp = (e) => {
    e.target.blur()
    if (pages.currentPage >= Math.ceil(data.length / pages.usersPerPage)) {
      return null
    }
    if (pages.currentPage >= pages.firstPage) {
      return setPages(pages => ({ ...pages, currentPage: pages.currentPage + 1 }))
    }
  }
  const handlePagnationDown = (e) => {
    e.target.blur()
    return setPages(pages => ({ ...pages, currentPage: pages.currentPage - 1 }))
  }

  const indexOfLastUser = pages.currentPage * pages.usersPerPage
  const indexOfFirstUser = indexOfLastUser - pages.usersPerPage
  const currentUsers = data.slice(indexOfFirstUser, indexOfLastUser)
  const allUsers = data.length
  let newindexOfFirstUser = indexOfFirstUser + 1
  let pageUsers = currentUsers.length + indexOfFirstUser
  return (
    <PageTable.Wrapper
      padding='20px 0 0'
    >
      <PageTable.Container>
        {header}
        <Table >
          <TableHead>
            <th>bonus</th>
            <th>amount</th>
            <th>status</th>
            <th>edit/delete</th>
          </TableHead>
          <tbody>
            <PlatformBonusList bonuses={currentUsers} handleSetBonus={handleSetBonus} />
          </tbody>

        </Table>
        { setPagination
          ? <Pagination
            data={data}
            pages={pages}
            allUsers={allUsers}
            pageUsers={pageUsers}
            newindexOfFirstUser={newindexOfFirstUser}
            handlePagnationUp={handlePagnationUp}
            handlePagnationDown={handlePagnationDown}
          />
          : null}
      </PageTable.Container>
    </PageTable.Wrapper>
  )
}
 