import styled from 'styled-components'
import { device } from "../../Components/device";


export const Banner = styled.section`
width:100%;
height:60vh;
background:  
url(${require('../../assets/images/banner.png')});
background-repeat: no-repeat;
background-size: 100% 100%;
background-position: center;
background-attachment: scroll;
`
export const LogoContainer = styled.div`
position:absolute
width: 315.69px;
height: 100px;
left: calc(50% - 315.69px / 2);
top: 90px;
background:  
url(${require('../../assets/images/FirstBannerLogo.svg')});
background-repeat: no-repeat;
background-size: 100% 100%;
background-position: center;
background-attachment: scroll;
`
export const Text = styled.p`
position:absolute;
width: 244px;
height: 64px;
// left: 598px;
left: calc(50% - 244px / 2);
top: 190px;
font-style: normal;
font-weight: bold;
font-size: 48px;
line-height: 64px;
color: #000000;
`
export const FixedContainer = styled.div`
position:absolute;
left: calc(50% - 500px / 2);
top:60%
@media screen and ${device.desktopL}{
  top:calc(100% - 350px / 2);
}
`
// style={{position:'absolute', top:'60%', left:'450px'}}