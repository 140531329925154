import authState from '../AuthStore'
const authReducer = (state = authState, action) => {
  switch (action.type) {
    case 'LOGIN_ADMIN':
      // console.log('this is the result of the login', action.payload)
      return {
        ...state,
        AdminData: { ...action.payload.data.admin }
      }
    default:
      return state
  }
}
export default authReducer
