import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Wrapper, SubWrapperAlt } from '../../theme/style/styles'
import { Header } from '../../theme/style/typeface'
import Platform from '../../Components/Platform/Platform'
import swal from 'sweetalert'
import validator from 'validator'



export default class index extends Component {

  state = {
    selected: 'house',
    loading:false,
    newRate:'',
    image: null, 
    imagePreviewUrl: null,
    imageUploadUrl:'',
    showAddHouse:false,
    showEditHouse:false,
    showDeleteHouse:false,
    showAddLocation:false,
    showEditLocaionTable:false,
    showEditLocation:false,
    showDeleteLocation:false,
    showDeleteBlog:false,
    showBlogForm:false,
    showAddBonus: false,
    showEditModal: false,
    showDeleteBonus:false,
    location:{
      location:'',
      country:'',
      state:'',
      _id:''
    },
    blog:{
      "_id": "",
      "title": "",
      "imageUrl": "",
      "body": "<p></p>",
      "tags": [],
      "timestamp": ''
    },
    bonus:{
      "_id": "",
      "bonusName": "",
      "bonusAmount": "",
      "bonusStatus": ""
    },
    houseType:'',
    description:'',
    _id:''
  }

  handleSelected = selected =>(
    this.setState({selected})
  )
  handleGetState = e =>(
    this.props.handleFetchAllCountryStates({country:e.target.value })
  )

  handleGetStateUsers = e =>(
    this.props.handleFetchAllUsersForState({state:e.target.value })
  )
  handleGetLocaleUsers = e =>(
    this.props.handleFetchAllUsersForLocale({location:e.target.value })
  )
  handleRateValue = values => {
    const { value } = values;
    // formattedValue = $2,223
    // value ie, 2223

    if(value > 100){
      swal('', 'Interest rate can\'t be greater than 100%', 'warning' )
      this.setState({newRate: 100})
      return
    }
    this.setState({newRate: value})
  }
handlePostNewRate =()=>{
  let loading = !this.state.loading
  let interestRate = this.state.newRate
  this.setState({loading})
  this.props.handlePostNewInterestRate({interestRate})
  .then(res=>{
    this.props.handleFetchInterestRate()
    .then(res=>{
      let loading = !this.state.loading
      this.setState({loading, newRate:''})
    })
  })
}
handleRateCancel =()=>(
this.setState({newRate:''})
)
handleShowAddModal =()=>{
  let showAddHouse = !this.state.showAddHouse
  this.setState({showAddHouse,  
  houseType:'',
  description:'',
  image: null, 
  imagePreviewUrl: null,
  imageUploadUrl: '', })
}
handleOnChange = e =>{
  this.setState({...this.state, [e.target.name]: e.target.value})
}
handleLocationInput = e =>{
  this.setState({...this.state, location:{...this.state.location, [e.target.name]: e.target.value}})
}
handleAddNewHouse = e =>{
  e.preventDefault()
  let house = this.state
  let data
  if(validator.isEmpty(house.houseType)){
    swal('No House Type', 'Please enter House Type', 'warning')
    return
  }
  if(validator.isEmpty(house.description)){
    swal('No Description', 'Please enter house Description', 'warning')
    return
  }
  
  if(!validator.isEmpty(house.imageUploadUrl)){
    if(!validator.isURL(house.imageUploadUrl)){
      swal('inValid Image Path', 'Please enter valid Image Path', 'error')
      return
    }
  }
data ={
    houseType:house.houseType,
    description:house.description,
  }
if(house.imageUploadUrl !== '' && house.imageUploadUrl !== null && house.imageUploadUrl !== undefined ){
  data ={
    houseType:house.houseType,
    description:house.description,
    image: house.imageUploadUrl 
  }
}
this.setState({loading: true})
this.props.handleAddNewHouse(data)
  .then(res=>{
    this.setState({loading: false, showAddHouse:false})
  })

}
handleSetHouse = (house, action) =>{
  if(action === 'delete'){
    return this.setState({...house, imagePreviewUrl:house.image}, ()=> this.handleShowDeleteModal())
  }
return this.setState({...house, imagePreviewUrl:house.image}, ()=> this.handleShowEditModal())
}
handleShowEditModal =()=>{
let showEditHouse = !this.state.showEditHouse
  this.setState({showEditHouse, loading:false,
    image: null, 
    imageUploadUrl: ''})
}
handleShowDeleteModal =()=>{
  let showDeleteHouse = !this.state.showDeleteHouse
    this.setState({showDeleteHouse, loading:false,
      image: null, 
      imageUploadUrl: ''})
  }
handleEditNewHouse= e =>{
  e.preventDefault()
  let house = this.state
  let data
  if(validator.isEmpty(house.houseType)){
    swal('No House Type', 'Please enter House Type', 'warning')
    return
  }
  if(validator.isEmpty(house.description)){
    swal('No Description', 'Please enter house Description', 'warning')
    return
  }
  
  if(!validator.isEmpty(house.imageUploadUrl)){
    if(!validator.isURL(house.imageUploadUrl)){
      swal('inValid Image Path', 'Please enter valid Image Path', 'error')
      return
    }
  }
data ={
    houseType:house.houseType,
    description:house.description,
  }
if(house.imageUploadUrl !== '' && house.imageUploadUrl !== null && house.imageUploadUrl !== undefined ){
  data ={
    houseType:house.houseType,
    description:house.description,
    image: house.imageUploadUrl 
  }
}
  this.setState({loading: true})
  // console.log(data)
  this.props.handleEditHouse(data, this.state._id)
  .then(res=>{
    this.props.handleFetchAllHouses()
    .then(res=>{
      this.setState({loading: false, showEditHouse:false})
    })
  })
  // console.log(this.state)
}

handleDeleteHouse = e =>{
  e.preventDefault()
  this.props.handleDeleteHouse(this.state._id)
  .then(res=>{
    this.props.handleFetchAllHouses()
    .then(res=>{
      this.setState({loading: false, showDeleteHouse:false})
    })
  })

  console.log(this.state)
}
 handleImageChange = (e) => {
  const imageMaxSize = 1000000 // bytes
  let file = e.target.files[0]
  console.log(file)
  const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
  const acceptedFileTypesArray = acceptedFileTypes.split(',').map((item) => { return item.trim() })
  const verifyFile = (files) => {
    if (files) {
      const currentFile = file
      const currentFileType = currentFile.type
      const currentFileSize = currentFile.size
      if (currentFileSize > imageMaxSize) {
        swal('Large Image', `This file is not allowed.  ${Math.round(currentFileSize / 1000000)} Mb is too large, maximum allowed size is 1mb`, 'error')
        return false
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        swal('Rejected', 'This file is not allowed. Only images are allowed.', 'error')
        return false
      }
      return true
    }
  }
  if (file) {
    const isVerified = verifyFile(file)
    console.log(isVerified)
    if (isVerified) {
      let reader = new FileReader()
      reader.onloadend = () => {
        this.setState({ ...this.state, image: file, imagePreviewUrl: reader.result }, ()=>this.handleUploadImage(this.state.image))
      }
      reader.readAsDataURL(file)
    }
  }
}

handleUploadImage = image =>{
  this.props.handleUploadImage(image)
    .then(res=>{
      swal('Image Uploaded',`${res.data.url}`, 'success' )
      this.setState({...this.state, imageUploadUrl: res.data.url})
    })
}
handleShowAddLocationModal=()=>{
let showAddLocation = !this.state.showAddLocation
this.setState({showAddLocation, loading: false, location:{
  location:'',
  country:'',
  state:'',
  _id:''
}})
}
handleAddNewLocation = e =>{
  e.preventDefault()
  let location = this.state.location
  let data
  if(validator.isEmpty(location.country)){
    swal('No Country', 'Please enter Country', 'warning')
    return
  }
  if(validator.isEmpty(location.state)){
    swal('No State', 'Please enter state', 'warning')
    return
  }
  
  if(validator.isEmpty(location.location)){
      swal('no location', 'Please enter location', 'warning')
      return
  }
data ={
    country:location.country,
    state:location.state,
    location:location.location,
  }

  this.setState({loading: true})
  this.props.handlePostNewLocation(data)
.then(res=>{
  this.props.handleFetchAllHouses()
  .then(res=>{
      this.setState({loading: false, showAddLocation:false})
  })
})
}
handleShowLocationTableModal =()=>{
  let showEditLocaionTable = !this.state.showEditLocaionTable
  this.setState({ showEditLocaionTable, loading:false })
}
handleSetLocation = (location, action)=>{
  if(action === 'delete'){
    return this.setState({...this.state, location:{...location}}, ()=> this.handleShowDeleteLocationModal())
  }
  return this.setState({...this.state, location:{...location}}, ()=> this.handleShowEditLocationModal())
}
handleShowEditLocationModal =()=> {
let showEditLocation = !this.state.showEditLocation
this.setState({showEditLocation, loading: false})
}
handleShowDeleteLocationModal =()=> {
  let showDeleteLocation = !this.state.showDeleteLocation
  this.setState({showDeleteLocation, loading: false})
  }
handleEditLocation= e =>{
  e.preventDefault()
  let location = this.state.location
  let data
  if(validator.isEmpty(location.country)){
    swal('No Country', 'Please enter Country', 'warning')
    return
  }
  if(validator.isEmpty(location.state)){
    swal('No State', 'Please enter state', 'warning')
    return
  }
  
  if(validator.isEmpty(location.location)){
      swal('no location', 'Please enter location', 'warning')
      return
  }
data ={
    country:location.country,
    state:location.state,
    location:location.location,
  }

  this.setState({loading: true})
  this.props.handleEditLocation(data, location._id)
  .then(res=>{
    this.props.handleFetchAllLocation()
    .then(res=>{
        this.setState({loading: false, showEditLocation:false})
    })
  })
}
handleDeleteLocation = e =>{
  e.preventDefault()
  this.setState({loading:true})
  this.props.handleDeleteLocation(this.state.location._id)
  .then(res=>{
    this.props.handleFetchAllLocation()
    .then(res=>{
        this.setState({loading: false, showDeleteLocation:false})
    })
  })
}
handleSetBlog = (blog, action) =>{
  if (action === 'delete'){
    return this.setState({...this.state, blog:{...blog}}, ()=> this.handleShowDeleteBlogModal())
  }
  return this.setState({...this.state, blog:{title: blog.title, tags:[...blog.tags], body:blog.body, _id:blog._id }}, ()=> this.handleShowBlogForm('edit'))
}
handleShowBlogForm = action => {
 
  let showBlogForm = !this.state.showBlogForm
  if (action ==='edit'){
  
    this.setState({showBlogForm, loading: false})
    return
  }
  this.setState({showBlogForm, loading: false,  blog:{
    "_id": "",
    "title": "",
    "imageUrl": "",
    "body": "<p></p>",
    "tags": [],
    "timestamp": ''
  }})
  }
handleShowDeleteBlogModal = () => {
let showDeleteBlog = !this.state.showDeleteBlog
this.setState({showDeleteBlog, loading: false})
}
handleDeleteBlog = e =>{
  e.preventDefault()
  this.setState({loading: true})
  this.props.handleDeleteBlog(this.state.blog._id)
  .then(res=>{
    swal('Success', `${res.message} has been added`, 'success')
    this.props.handleFetchAllLocation()
    .then(res=>{
        this.setState({loading: false})
        this.props.handleFetchAllBlogs()
        .then(res=>{
          this.setState({loading: false, showDeleteBlog:false})
      })
    })
  })
}
handleBlogInputs = e =>{
  if (e.target.name === 'tags'){
    return this.setState({...this.state, blog:{...this.state.blog, tags: e.target.value.split(',')}})
  }
 return this.setState({...this.state, blog:{...this.state.blog, [e.target.name]:e.target.value}})
}

handleEditorChange = content => {
  this.setState({...this.state, blog:{...this.state.blog, body: content}})
}


handleSaveBlog = e =>{
e.preventDefault()
let blog = this.state.blog
let body = blog.body
let imageUrl = this.state.imageUploadUrl
  let data
  if(validator.isEmpty(blog.title)){
    swal('Title missing', 'Please enter title', 'warning')
    return
  }
data ={
    title:blog.title,
    imageUrl,
    body,
    tags:blog.tags
  }
  
if(!validator.isEmpty(blog._id)){
  data ={
    title:blog.title,
    imageUrl: blog.imageUrl,
    body,
    tags:blog.tags
  }
  this.setState({loading:true})
  this.props.handleEditBlog(data, blog._id)
  .then(res=>{
    swal('Edit Successful',`${res.data.updatedBlpg.title} has been editted`, 'success')
    this.props.handleFetchAllBlogs()
    .then(res=>{
        this.setState({loading: false, imageUploadUrl:'', blog:{
          "_id": "",
          "title": "",
          "imageUrl": "",
          "body": "<p></p>",
          "tags": [],
          "timestamp": ''
        }})
    })
  })
  return
}
if(validator.isEmpty(imageUrl)){
  swal('No Image', 'Please Upload Image', 'warning')
  return
}
this.setState({loading:true})
this.props.handleAddNewBlog(data)
.then(res=>{
  swal('Success', `${res.data.blog.title} has been added`, 'success')
  this.props.handleFetchAllBlogs()
  .then(res=>{
      this.setState({loading: false, imageUploadUrl:'', blog:{
        "_id": "",
        "title": "",
        "imageUrl": "",
        "body": "<p></p>",
        "tags": [],
        "timestamp": ''
      } })
  })
})
}
handleSetBonus = (bonus, action) =>{
if (action==='delete'){
return this.setState({...this.state, bonus:{...bonus}}, ()=> this.handleShowBonusModal('delete'))
}
return this.setState({...this.state, bonus:{...bonus}}, ()=> this.handleShowBonusModal('edit'))
}
handleShowBonusModal = modal => {
  let showAddBonus = !this.state.showAddBonus
  let showEditBonus = !this.state.showEditBonus
  let showDeleteBonus = !this.state.showDeleteBonus
  if(modal ==='edit'){
    this.setState({ ...this.state, showEditBonus, loading: false })
    return
  }
  if(modal ==='delete'){
    this.setState({ ...this.state, showDeleteBonus, loading: false })
    return
  }
  this.setState({ ...this.state, showAddBonus, loading: false,   bonus:{
    "_id": "",
    "bonusName": "",
    "bonusAmount": "",
    "bonusStatus": ""
  } })
}
handleBonusInput = e =>{
this.setState({...this.state, bonus:{...this.state.bonus, [e.target.name]:e.target.value}})
}
handleAddNewBonus = e =>{
  e.preventDefault()
  let bonus = this.state.bonus
  let data
  if(validator.isEmpty(bonus.bonusName)){
    swal('No Name', 'Please enter Bonus Name', 'warning')
    return
  }
  if(validator.isEmpty(bonus.bonusAmount)){
    swal('No Amount', 'Please enter an Amount', 'warning')
    return
  }
  
  if(validator.isEmpty(bonus.bonusStatus)){
      swal('no Status', 'Please enter Status', 'warning')
      return
  }
data ={
  bonusName:bonus.bonusName,
  bonusAmount:Number(bonus.bonusAmount),
  bonusStatus:bonus.bonusStatus,
  }

  this.setState({loading: true})
  this.props.handlePostNewBonus(data)
  .then(res=>{
  swal('Successful',`${res.data.message}`, 'success')
  this.props.handleFetchAllBonuses()
  .then(res=>{
      this.setState({loading: false, showAddBonus:false, bonus:{
        "_id": "",
        "bonusName": "",
        "bonusAmount": "",
        "bonusStatus": ""
      }})
  })
})
}

handleEditBonus = e =>{
  e.preventDefault()
  let bonus = this.state.bonus
  let data
  if(validator.isEmpty(bonus.bonusName)){
    swal('No Name', 'Please enter Bonus Name', 'warning')
    return
  }
  if(validator.isEmpty(bonus.bonusAmount)){
    swal('No Amount', 'Please enter an Amount', 'warning')
    return
  }
  
  if(validator.isEmpty(bonus.bonusStatus)){
      swal('no Status', 'Please enter Status', 'warning')
      return
  }
data ={
  bonusName:bonus.bonusName,
  bonusAmount:Number(bonus.bonusAmount),
  bonusStatus:bonus.bonusStatus,
  }

  this.setState({loading: true})
  this.props.handleEditBonus(data, bonus._id)
  .then(res=>{
  swal('Successful',`${res.data.message}`, 'success')
  this.props.handleFetchAllBonuses()
  .then(res=>{
      this.setState({loading: false, showEditBonus:false, bonus:{
        "_id": "",
        "bonusName": "",
        "bonusAmount": "",
        "bonusStatus": ""
      }})
  })
})
}
handleDeleteBonus = e =>{
  e.preventDefault()
  let bonus = this.state.bonus
  this.setState({loading: true})
  this.props.handleDeleteBonus(bonus._id)
  .then(res=>{
  swal('Successful',`${res.message}`, 'success')
  this.props.handleFetchAllBonuses()
  .then(res=>{
      this.setState({loading: false, showDeleteBonus:false, bonus:{
        "_id": "",
        "bonusName": "",
        "bonusAmount": "",
        "bonusStatus": ""
      }})
  })
})
}
  componentDidMount () {
    this.props.handleFetchAllHouses()
    this.props.handleFetchAllCountries()
    this.props.handleFetchInterestRate()
    this.props.handleFetchAllBlogs()
    this.props.handleFetchAllLocation()
    this.props.handleFetchAllBonuses()
  }
  render () {
    return (
      <Wrapper>
        {/*
         ==========================================================================
         React-Helmet
         ==========================================================================
        */}
        <Helmet>
          <meta charSet='utf-8' />
          <title> Platform || house Dashboard </title>
        </Helmet>
        <SubWrapperAlt>
            <Header style={{alignSelf:'flex-start'}}>
             Platform
            </Header>
          <Platform 
          state={this.state}
          handleSelected={this.handleSelected} 
          selected={this.state.selected} 
          platform={this.props.platform}  
          handleGetState={this.handleGetState}
          handleGetStateUsers={this.handleGetStateUsers}
          handleGetLocaleUsers={this.handleGetLocaleUsers}
          handleRateValue={this.handleRateValue}
          handlePostNewRate={this.handlePostNewRate}
          handleRateCancel={this.handleRateCancel}
          handleShowAddModal={this.handleShowAddModal}
          handleShowAddLocationModal={this.handleShowAddLocationModal}
          handleImageChange={this.handleImageChange}
          handleOnChange={this.handleOnChange}
          handleAddNewHouse={this.handleAddNewHouse}
          handleShowEditModal={this.handleShowEditModal}
          handleEditNewHouse={this.handleEditNewHouse}
          handleSetHouse={this.handleSetHouse}
          handleShowDeleteModal={this.handleShowDeleteModal}
          handleAddNewLocation={this.handleAddNewLocation}
          handleDeleteHouse={this.handleDeleteHouse}
          handleLocationInput={this.handleLocationInput}
          handleShowLocationTableModal={this.handleShowLocationTableModal}
          handleEditLocation={this.handleEditLocation}
          handleShowEditLocationModal={this.handleShowEditLocationModal}
          handleDeleteLocation={this.handleDeleteLocation}
          handleShowDeleteLocationModal={this.handleShowDeleteLocationModal}
          handleSetLocation={this.handleSetLocation}
          handleSetBlog={this.handleSetBlog}
          handleShowDeleteBlogModal={this.handleShowDeleteBlogModal}
          handleDeleteBlog={this.handleDeleteBlog}
          handleShowBlogForm={this.handleShowBlogForm}
          handleBlogInputs={this.handleBlogInputs}
          handleEditorChange={this.handleEditorChange}
          handleSaveBlog={this.handleSaveBlog}
          handleShowBonusModal={this.handleShowBonusModal}
          handleBonusInput={this.handleBonusInput}
          handleAddNewBonus={this.handleAddNewBonus}
          handleSetBonus={this.handleSetBonus}
          handleEditBonus={this.handleEditBonus}
          handleDeleteBonus={this.handleDeleteBonus}
           />
        </SubWrapperAlt>
      </Wrapper>
    )
  }
}