import React from 'react'
import { Modal } from 'react-bootstrap'
import { CardHeader } from '../../../../theme/style/typeface'
import { Logo } from '../../../Picture'
// import { Main, Text } from '../../Input'
import * as ModalStyle from '../../styles'
import { ModalCancelButton } from '../../../Button'
import { PlatformLocationTable } from '../../../Table'
// import { TxtArea } from '../../../theme/style/Form';

const AddHouse = ({ inputs, location, handleSetLocation, ...props }) => {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <CardHeader
          padding='0'
          style={{ borderBottom: '2px solid rgba(238, 238, 238, .2)' }}>
            List of locations
          <Logo width='22px' height='22px' margin='0' src={require('../../../../assets/images/close-alt_blue.svg')} clicked={props.onHide} />
        </CardHeader>
        <ModalStyle.ContainerAlt>
          <PlatformLocationTable title='' data={location} setPagination handleSetLocation={handleSetLocation} />
        </ModalStyle.ContainerAlt>
        <ModalStyle.Container>
          <ModalCancelButton type='button' margin='32px auto 50px' content='Cancel' fontSize='16px' borderRadius='4px' onClick={props.onHide} />
        </ModalStyle.Container>
      </Modal.Body>
    </Modal>
  )
}
export default AddHouse
