import type from '../Type'
import axios from 'axios'
import { handleError } from '../../lib'
import swal from 'sweetalert'

const { LOGIN_ADMIN } = type

const setAdmin = payload => ({
  type: LOGIN_ADMIN,
  payload
})
export const handleAdminLogin = admin => dispatch => axios
  .post('/login', admin)
  .then(res => {
    let result = res.data
    localStorage.setItem('token', result.data.token)
    localStorage.setItem('adminId', result.data.admin._id)
    dispatch(setAdmin(result))
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
    return err
  })
export const handleFetchAdminDetailsOnRefresh = () => dispatch => axios
  .get('/')
  .then(res => {
    let result = res.data
    dispatch(setAdmin(result))
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
  })
// export const handledLoginWithRouter = withRouter(handledLogin)
export const handleEditAdmin = data => dispatch => {
  return axios
    .post(`/create`, data)
    .then(res => {
      let result = res.data
      swal('New Admin created', `${result.data.message}`, 'success')
      return result
    }).catch(err => {
      handleError(err)
      console.log(err)
    })
}
