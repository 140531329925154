
import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import * as PageTable from './styles'
import { TableHead } from './TableParts'
import { UserInfoList } from './TableList'
import { CardHeader } from '../../theme/style/typeface'
import Pagination from '../Tools/Pagination'

export const UserInfoTable = ({ title, data, setPagination }) => {
  let header
  if (title) {
    header = (
      <CardHeader
        padding='15px 56px'>
        {title}
      </CardHeader>
    )
  }
  const [pages, setPages] = useState({
    firstPage: 1,
    currentPage: 1,
    usersPerPage: 5
  })

  const handlePagnationUp = (e) => {
    e.target.blur()
    if (pages.currentPage >= Math.ceil(data.length / pages.usersPerPage)) {
      return null
    }
    if (pages.currentPage >= pages.firstPage) {
      return setPages(pages => ({ ...pages, currentPage: pages.currentPage + 1 }))
    }
  }
  const handlePagnationDown = (e) => {
    e.target.blur()
    return setPages(pages => ({ ...pages, currentPage: pages.currentPage - 1 }))
  }

  const indexOfLastUser = pages.currentPage * pages.usersPerPage
  const indexOfFirstUser = indexOfLastUser - pages.usersPerPage
  const currentUsers = data.slice(indexOfFirstUser, indexOfLastUser)
  const allUsers = data.length
  let newindexOfFirstUser = indexOfFirstUser + 1
  let pageUsers = currentUsers.length + indexOfFirstUser
  return (
    <PageTable.Wrapper
      padding='20px 0 0'
      style={{margin:'0 auto'}}
    >
      <PageTable.Container>
        {header}
        <Table >
          <TableHead>
            <th>document name</th>
            <th>document</th>
            <th>date uploaded</th>
          </TableHead>
          <tbody>
            <UserInfoList info={currentUsers} />
          </tbody>

        </Table>
        { setPagination
          ? <Pagination
            data={data}
            pages={pages}
            allUsers={allUsers}
            pageUsers={pageUsers}
            newindexOfFirstUser={newindexOfFirstUser}
            handlePagnationUp={handlePagnationUp}
            handlePagnationDown={handlePagnationDown}
          />
          : null}
      </PageTable.Container>
    </PageTable.Wrapper>
  )
}

