import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { CardHeader, SubHeader } from '../../../theme/style/typeface'
import { Logo } from '../../Picture'
import * as ModalStyle from '../styles'
import Button from '../../Button'

const EditAdmin = ({ inputs, handleDeleteAdmin, ...props }) => {
  return (
    <Modal id='addAdmin_modal'
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <Form onSubmit={handleDeleteAdmin}>
          <CardHeader
            padding='0'
            style={{ borderBottom: '2px solid rgba(238, 238, 238, .2)' }}>
              Delete admin
            <Logo width='22px' height='22px' margin='0' src={require('../../../assets/images/close-alt_blue.svg')} clicked={props.onHide} />
          </CardHeader>
          <ModalStyle.ContainerAlt>
            <SubHeader style={{textTransform:'inherit'}}>
                Are your sure you want to delete <strong style={{textTransform:'uppercase'}}>{inputs.admin.name}</strong>
            </SubHeader>
          </ModalStyle.ContainerAlt>
          <ModalStyle.Container>
            <Button type='submit' loading={inputs.loading} margin='32px auto 50px' content='Delete' fontSize='16px' borderRadius='4px' />
            <Button type='button' onClick={props.onHide} alt margin='32px auto 50px' content='Cancel' fontSize='16px' borderRadius='4px' />
          </ModalStyle.Container>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default EditAdmin
