import React from 'react'
import { GalleryImage } from '../Picture'
import { SubWrapper, SubWrapperAlt, ListWrapper } from '../../theme/style/styles'
import { CardHeader } from '../../theme/style/typeface'
const GalleryList = ({ images, handleDeleteImage, children, ...props }) =>
  <SubWrapperAlt padding='0' {...props}>
    <CardHeader padding='0'>
          Photo edit
    </CardHeader>
    <ListWrapper className='galley_list' padding='0'>
      {images && images.length > 0 ? images.map(image => <GalleryImage key={image._id} image={image.image} deleteImage={() => handleDeleteImage(image._id)} />) : <CardHeader style={{ justifyContent: 'center' }}>No Images found </CardHeader> }
    </ListWrapper>
    <SubWrapper justityContent='flex-end' padding='0 40px' {...props}>
      {children}
    </SubWrapper>
  </SubWrapperAlt>

export default GalleryList
