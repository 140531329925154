import React from 'react'
import { Row, Tabs, Tab } from 'react-bootstrap'
import Overview from './Tabs/Overview'
import Finances from './Tabs/Finances'
import * as Page from './styles'


const Dashboard = ({ inputs, state, handleShowChart, dashboard, handleSetDate }) => {
  return (
    <Page.Details>
    
      {/* All CSS for Tabs/ Overview / Finance can be found in Dashboard.css */}
      <Tabs defaultActiveKey='overview' id='firsthomes-tabs'>
        <Tab eventKey='overview' title='Overview'>
          <Row>
            <Overview inputs={inputs} state={state} handleShowChart={handleShowChart} />
          </Row>
        </Tab>
        <Tab eventKey='finance' title='Finance'>
          <Finances dashboard={dashboard} inputs={inputs} state={state} handleSetDate={handleSetDate} />
        </Tab>
      </Tabs>
    </Page.Details>
  )
}

export default Dashboard
