import type from '../Type'
import axios from 'axios'
import { handleError } from '../../lib'

const { GET_ALL_DEPOSITS, GET_ALL_WITHDRAWALS, GET_INSIGHT } = type

const getAllDeposits = payload => ({
  type: GET_ALL_DEPOSITS,
  payload
})
const getAllWithdrawals = payload => ({
  type: GET_ALL_WITHDRAWALS,
  payload
})
const getAllInsights = payload => ({
  type: GET_INSIGHT,
  payload
})

export const handleFetchAllDeposits = () => dispatch => {
  return axios
    .get(`/transactions`)
    .then(res => {
      let result = res.data
      dispatch(getAllDeposits(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleFetchAllWithdrawals = () => dispatch => {
  return axios
    .get(`/withdrawals`)
    .then(res => {
      let result = res.data
      dispatch(getAllWithdrawals(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}

export const handleFetchAllInsights = () => dispatch => {
  return axios
    .get(`/insights`)
    .then(res => {
      let result = res.data
      dispatch(getAllInsights(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}

export const handleFetchDepositsByParams = (month, year) => dispatch => {
  let result = { data: { transactions: [] } }
  dispatch(getAllDeposits(result))
  return axios
    .get(`/transactions?month=${month}&year=${year}`)
    .then(res => {
      let result = res.data
      dispatch(getAllDeposits(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}

export const handleFetchWithdrawalsByParams = (month, year) => dispatch => {
  let result = { data: { withdrawals: [] } }
  dispatch(getAllWithdrawals(result))
  return axios
    .get(`/withdrawals?month=${month}&year=${year}`)
    .then(res => {
      let result = res.data
      dispatch(getAllWithdrawals(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}