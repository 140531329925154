import Login from '../../Pages/Login'
import { connect } from 'react-redux'
import { handleAdminLogin } from '../../Store/Actions'

/**
 * here we handle passing redux to our component and export
 */
const mapStateToProps = state => ({
  currentPage: state.Admin.users.currentPage,
  users: state.Admin.users.users
})

const mapDispatchToProps = dispatch => ({
  handleAdminLogin (admin) {
    return dispatch(handleAdminLogin(admin))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
