import styled from 'styled-components'

export const Wrapper = styled.section`
padding: ${props => props.padding ? props.padding : '50px 80px'};
width: 100%;
height: 100%;
table{
  // margin:50px;
  width:100%;
}
`
export const Container = styled.div`
background: ${props => props.theme.white} !important;
`
export const Thead = styled.thead`
background: ${props => props.theme.faintRed};
tr th{
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${props => props.theme.tHeaderText};
  padding: 12px 37px;
  border: none;
}
&.transactionTable tr th{
  padding: 12px 20px;
}
`
export const Tr = styled.tr`
  td{
  border: none;
  border-bottom: 1px solid #eeeeee !important;
  padding: 16px 0;
  padding-left: 37px;
  cursor:pointer;
  // padding-right: 10px;
}
&.transactionTable td{
  padding: 20px 0;
  padding-left: 20px;
  // padding-right: 10px;
}
&.noCursor td {
  cursor:auto;
}
`

export const Text = styled.p`
font-size: 16px;
line-height: 20px;
text-transform: ${props => props.textTransForm};
color: ${props => props.theme.bodyText};
margin:0;
&.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}
`
export const SubText = styled(Text)`
font-size: 10px;
line-height: 14px;
color: ${props => props.altSubText ? props.theme.blue : props.theme.text};
`

export const SideBy = styled.div`
display:flex;
justify-content: flex-start;
align-items: center;
padding:0;
margin:0;
`
export const Stacked = styled(SideBy)`
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
margin-left: 5px;
`
export const Accepted = styled.div`
width: 78px;
height: 24px;
padding: 5px 10px;
border-radius: 2px;
text-align: center;
background: ${props => props.theme.faintGreen};
font-size: 10px;
  line-height: 14px;
  text-align: center;
  text-transform: capitalize;
span{
  color: ${props => props.theme.green};
}
`
export const Unaccepted = styled(Accepted)`
background: ${props => props.alt ? props.theme.lightOrange : props.theme.faintRed};
span{
  color: ${props => props.alt ? props.theme.mediumOrange : props.theme.red};
}
`

const IMG = styled.div`
 width:25px;
 height:25px;
 transition: .3s;
`
export const Button = styled.button`
 background: #fff;
 border: #fff;
 margin-top: -5px;
 padding: 0;
 margin-right: 30px;
 &.edit{
  margin-left: 5px;
 }
 `

export const EditIMG = styled(IMG)`
 background:url('${require('../../assets/images/edit.svg')}') bottom center no-repeat;
 :hover{
  background:url('${require('../../assets/images/edit-2.svg')}') bottom center no-repeat;
 }
 `
export const DeleteIMG = styled(IMG)`
 background:url('${require('../../assets/images/trashcan.svg')}') bottom center no-repeat;
 :hover{
  background:url('${require('../../assets/images/trash-2.svg')}') bottom center no-repeat;
 }
 `
export const TextWrapper = styled.div`
 display:flex;
 align-items:center;
 justify-content:flex-start;
 width:100%;
 `