import React, { Component } from 'react'
import { Link, Route } from 'react-router-dom'
// import profileImage from  '../../img/Ellipse.png'
import dashboardBlue from '../../assets/images/dashboard_blue.svg'
import dashBoardGrey from '../../assets/images/dashboard_grey.svg'
import userBlue from '../../assets/images/user_blue.svg'
import userGrey from '../../assets/images/user_grey.svg'
import transBlue from '../../assets/images/trans_blue.svg'
import transGrey from '../../assets/images/trans_grey.svg'
import settingBlue from '../../assets/images/setting_blue.svg'
import settingGrey from '../../assets/images/setting_grey.svg'
import adminBlue from '../../assets/images/admin_blue.svg'
import adminGrey from '../../assets/images/admin_grey.svg'
import insightBlue from '../../assets/images/insight_blue.svg'
import insightGrey from '../../assets/images/insight_grey.svg'
import galleryBlue from '../../assets/images/gallery.svg'
import galleryGrey from '../../assets/images/gallery_alt.svg'
import * as Page from './styles';




const NavLinks = ({ img, img2, address, activeOnlyWhenExact }) => {
  // let link = `/${address}`
  let newAddress = address.split(" ")[0].trim()
  let link = `/${newAddress}`
  return (
    <Route
    path={link}
    exact={activeOnlyWhenExact}
    children={({ match }) => (
      <li className={match ? `active` : ""}>
      <Link to= {link}>
        <img src={match ? img : img2} alt={address} />
        <span>
          {address}
        </span>
      </Link>
    </li>
    )}
  />
  )
}
export class index extends Component {

  static defaultProps ={
    links:[
      { 
        id:'1',
        img:dashboardBlue,
        img2:dashBoardGrey,
        address:'dashboard'
      },
      { 
       id:'2',
       img:userBlue,
       img2:userGrey,
       address:'users'
     },
     { 
       id:'3',
       img:transBlue,
       img2:transGrey,
       address:'transactions'
     },
     { 
       id:'4',
       img:settingBlue,
       img2:settingGrey,
       address:'Platform settings'
     },
     { 
       id:'5',
       img: adminBlue,
       img2: adminGrey,
       address:'Admin settings'
     },
     { 
      id:'6',
      img: insightBlue,
      img2: insightGrey,
      address:'Insights'
    },
    { 
      id:'7',
      img: galleryBlue,
      img2: galleryGrey,
      address:'gallery'
    },
    ]
  }
  render() {
    const navlink = this.props.links.map((link)=>{
      return  <NavLinks key={link.id} {...link}/>
     })
   
    return (
      <div>
        <Page.SideNav>
          <ul>
            {navlink}
          </ul>
        </Page.SideNav>
      </div>
    )
  }
}

export default index
