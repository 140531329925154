import Profile from '../../Pages/UserProfile'
import { connect } from 'react-redux'
// import { handleFetchUser } from '../../Store/Actions'

/**
 * here we handle passing redux to our component and export
 */
const mapStateToProps = state => ({
  user: state.Admin.users.user
})

// const mapDispatchToProps = dispatch => ({
 
// })

export default connect(mapStateToProps, null)(Profile)
