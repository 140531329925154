import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { CardHeader, SubHeader } from '../../../theme/style/typeface'
import { Logo } from '../../Picture'
import { MainNoLabel, CheckBox } from '../../Input'
import * as ModalStyle from '../styles'
import Button from '../../Button'

const AddAdmin = ({ inputs, handleOnChange, handleAddAdmin, handleCheckbox, ...props }) => {
  return (
    <Modal id='addAdmin_modal'
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <Form onSubmit={handleAddAdmin}>
          <CardHeader
            padding='0 35px'
            fontSize='18px'
            style={{ borderBottom: '2px solid rgba(238, 238, 238, .2)' }}>
            Add new admin
            <Logo width='22px' height='22px' margin='0' src={require('../../../assets/images/close-alt_blue.svg')} clicked={props.onHide} />
          </CardHeader>
          <ModalStyle.ContainerAlt>
            <ModalStyle.Container style={{ justifyContent: 'space-around' }}>
              <MainNoLabel
                type='text'
                placeholder='Admin Name'
                name='name'
                value={inputs.admin.name}
                width='400px'
                height='60px'
                margin='0 auto'
                altBorder='#D0D5DA'
                fontSize='14px'
                changed={handleOnChange}
              />
            </ModalStyle.Container>
            <ModalStyle.Container style={{ justifyContent: 'space-around' }}>
              <MainNoLabel
                type='email'
                placeholder='Email address'
                name='email'
                value={inputs.admin.email}
                width='400px'
                height='60px'
                margin='0 auto'
                altBorder='#D0D5DA'
                changed={handleOnChange}
                fontSize='14px'
              />
            </ModalStyle.Container>
            <ModalStyle.ContainerAlt >
              <SubHeader margin='30px 0 30px 32px' style={{ alignSelf: 'flex-start' }}>
                  Priviledges
              </SubHeader>
              <ModalStyle.Container style={{ margin:'0 20px' }}>
                <CheckBox label='Create' type='checkbox' name='create' onChange={handleCheckbox} checked={inputs.admin.create} />
                <CheckBox label='Edit' type='checkbox' name='edit' onChange={handleCheckbox} checked={inputs.admin.edit} />
                <CheckBox label='View' type='checkbox' name='view' onChange={handleCheckbox} checked={inputs.admin.view} />
                <CheckBox label='Delete' type='checkbox' name='delete' onChange={handleCheckbox} checked={inputs.admin.delete} />
              </ModalStyle.Container>
              <ModalStyle.Container >
                <CheckBox label='Super Admin' type='checkbox' name='super admin' onChange={handleCheckbox} checked={inputs.admin['super admin']} />
              </ModalStyle.Container>
            </ModalStyle.ContainerAlt>
          </ModalStyle.ContainerAlt>
          <ModalStyle.Container>
            <Button type='submit' loading={inputs.loading} margin='32px auto 50px' content='Save' fontSize='16px' borderRadius='4px' />
          </ModalStyle.Container>

        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default AddAdmin
