import styled from 'styled-components'

export const Container = styled.button`
    margin:${props => props.margin};
    width: ${props => props.width};
    height: ${props => props.height};
    border-radius:${props => props.borderRadius};
    border: 1px solid ${props => props.theme.actions};
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: ${props => props.fontSize};
    line-height: 21px;
    cursor: pointer;
    color: ${props => props.alt ? props.theme.actions : props.theme.white};
    background-color: ${props => props.alt ? props.theme.white : props.theme.actions};
    transition: .3s;
`
Container.defaultProps = {
  width: '131px',
  height: '45px',
  margin: '24px auto',
  borderRadius: '4px',
  fontSize: '12px'
}

export const AltContainer = styled(Container)`
    background-color: ${props =>
    props.chosen ? props.theme.seaBlue : 'transparent'};
    border: ${props => props.chosen ? null : `0.5px solid ${props.theme.seaBlue}`};
    color: ${props => (props.chosen ? '#fff' : props.theme.seaBlue)};
    width:${props => props.width};
    height:${props => props.height};
`

export const CSVButton = styled(Container)`
font-size: 14px;
line-height: 21px;
margin:${props => props.margin};
width: ${props => props.width};
height: ${props => props.height};
border-radius:${props => props.borderRadius};
color: ${props => props.alt ? props.theme.placeholder : props.theme.white};
background-color: ${props => props.alt ? props.theme.transparent : props.theme.actions };
border: 1px solid ${props => props.theme.actions};
`
CSVButton.defaultProps = {
  width: '131px',
  height: '45px',
  margin: '10px 0 24px auto',
  borderRadius: '4px',
}
export const AddAdmin = styled(CSVButton)``

export const PlatformButton = styled(Container)`
background-color: ${props => props.alt ? props.theme.transparent : props.theme.actions};
border: 1px solid ${props => props.alt ? props.theme.actions : props.theme.transparent};
font-size: 16px;
line-height: 20px;
margin:${props => props.margin};
width: ${props => props.width};
height: ${props => props.height};
color: ${props => props.alt ? props.theme.bodyText : props.theme.white};
border-radius:${props => props.borderRadius};
`
PlatformButton.defaultProps = {
  width: '100px',
  height: '40px',
  margin: '0 0 0 24px',
  borderRadius: '4px'
}

export const RedContainer = styled(Container)`
background-color: ${props =>  props.theme.buttonRed};
border: 1px solid ${props =>  props.theme.transparent};
font-size: 16px;
line-height: 20px;
margin:${props => props.margin};
width: ${props => props.width};
height: ${props => props.height};
color: ${props => props.theme.white};
border-radius:${props => props.borderRadius};
`
PlatformButton.defaultProps = {
  width: '131px',
  height: '40px',
  margin: '0',
  borderRadius: '4px'
}