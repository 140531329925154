import React from 'react'
import * as Card from './styles'
import { CardText, CardSubText, Title } from '../../theme/style/typeface'
// import { Logo } from '../Picture'
// import { Main } from '../Input'
import { CardInformation, UserDetailsRow, Details } from './cardParts'
// import Button, { DuoButton } from '../Button'
import TabHead from '../TabHead'

const CardBox = ({ children, ...props }) => {
  return (
    <Card.Container {...props}>
      {children}
    </Card.Container>
  )
}
export const Analytics = ({ figure, title, color, ...props }) => (
  <CardBox
    backgroundColor={color}
    align='center'
    justify='center'
    flexWrap='nowrap'
    margin='0 auto 10px 0 '
    {...props} >
    <CardInformation
      text={figure}
      subText={title}
      textAlt='true'
      subTextAlt='true'
      altColor='true'
    />
  </CardBox>
)

export const FinanceCard = ({
  placeholderOne,
  placeholderTwo,
  title,
  labelOne,
  labelTwo,
  labelThree,
  valueOne,
  valueTwo,
  valueThree,
  children,
  state,
  dashboard,
  handleSetDepositDate,
  ...props }) => {
  return (
    <CardBox
      width='470px'
      height='306px'
      margin='50px 0'
      padding='16px 5px 0'
    >
      <TabHead
        wrapperMargin='0'
        wrapperPadding='0 0 0 20px'
        width='117px'
        height='30px'
        small
        title={title}
        placeholderOne={placeholderOne}
        placeholderTwo={placeholderTwo}
        inputs={state}
        handleSetDate={handleSetDepositDate}
        {...props}
      />
      <Card.Line />
      <Card.Container
        padding='0'
        margin='26px 0 0'
        direction='row'
        boxShadow='none'
        justify='center'
        align='center'
        width='100%'
        height='1'
        color='black'
        backgroundColor='transparent'
      >
        {children}
        <Card.MainWrapper>
          <Details
            color='#2A2A72'
            label={labelOne}
            number={valueOne}
          />
          <Details
            color='#FFA400'
            label={labelTwo}
            number={valueTwo}
          />
          <Details
            color='#009FFD'
            label={labelThree}
            number={valueThree}
          />
        </Card.MainWrapper>

      </Card.Container>
      {/* <Card.SpanCircle style={{ background: '#2A2A72' }} /> */}
    </CardBox>
  )
}

export const FinanceSmallCard = ({ number }) => (
  <CardBox
    width='300px'
    height=' 160px'
    justify='center'
    align='flex-start'
    margin='64px 0'
    padding='0 24px'
  >
    <Card.Container
      width='3'
      height=' 1'
      backgroundColor='transparent'
      boxShadow='none'
    >
      <CardSubText>
        Contingent Liabilities
      </CardSubText>
      <CardText>
        &#8358; {number || '1,050,000'}
      </CardText>
    </Card.Container>
  </CardBox>
)
export const ActivityCard = ({ title, number }) => {
  return (
    <CardBox
      width='300px'
      height=' 160px'
      justify='center'
      align='flex-start'
      margin='0 20px 50px 0'
      padding='0 24px'
      boxShadow='0px 4px 12px rgba(0, 0, 0, 0.1)'
    >
      <Card.Wrapper style={{ justifyContent: 'space-between', width: '100%' }}>
        <Card.Container
          width='3'
          height=' 1'
          backgroundColor='transparent'
          boxShadow='none'
        >
          <CardSubText>
            { title || 'No Info' }
          </CardSubText>
          <CardText>
            {number || 'Nil'}
          </CardText>
        </Card.Container>
      </Card.Wrapper>

    </CardBox>
  )
}
export const UserDetailsCard = ({
  title,
  hideOne,
  hideTwo,
  hideRowOne,
  hideRowTwo,
  hideRowThree,
  hideRowFour,
  hidelineOne,
  hidelineTwo,
  hidelineThree,
  hidelineFour,
  infoOne,
  infoTwo,
  infoThree,
  infoFour,
  infoFive,
  infoSix,
  infoSeven,
  infoEight,
  infoNine,
  infoTen,
  labelOne,
  labelTwo,
  labelThree,
  labelFour,
  labelFive,
  labelSix,
  labelSeven,
  labelEight,
  labelNine,
  labelTen,
  hideRowOneColTwo,
  hideRowOneColThree,
  hideRowTwoColTwo,
  hideRowTwoColThree,
  hideRowThreeColTwo,
  hideRowThreeColThree,
  hideRowFourColTwo,
  hideRowFourColThree }) => {
  return (
    <CardBox
      justify='flex-start'
      align='flex-start'
      boxShadow='none'
      backgroundColor='transparent'
      padding='0'
      margin='40px 0'
      width='100%'
      height='100%'
    >
      <Title margin='0 0 30px'>
        {title || 'Information'}
      </Title>
      <UserDetailsRow
        labelOne={labelOne}
        labelTwo={labelTwo}
        labelThree={labelThree}
        infoOne={infoOne}
        infoTwo={infoTwo}
        infoThree={infoThree}
        hideline={hidelineOne}
        hideColTwo={hideRowOneColTwo}
        hideColThree={hideRowOneColTwo}
      />
      {hideRowTwo ? null : <UserDetailsRow
        labelOne={labelFour}
        labelTwo={labelFive}
        labelThree={labelSix}
        infoOne={infoFour}
        infoTwo={infoFive}
        infoThree={infoSix}
        hideline={hidelineTwo}
        hideColTwo={hideRowTwoColTwo}
        hideColThree={hideRowTwoColThree} />}
      {hideRowThree ? null : <UserDetailsRow
        labelOne={labelSeven}
        labelTwo={labelEight}
        labelThree={labelNine}
        infoOne={infoSeven}
        infoTwo={infoEight}
        infoThree={infoNine}
        hide={hideOne} hideline={hidelineThree}
        hideColTwo={hideRowThreeColTwo}
        hideColThree={hideRowThreeColThree} />}
      {hideRowFour ? null : <UserDetailsRow
        labelOne={labelTen}
        infoOne={infoTen}
        hide={hideTwo} hideline={hidelineFour}
        hideColTwo={hideRowFourColTwo}
        hideColThree={hideRowFourColThree} />}

    </CardBox>
  )
}

export const BlogFormCard = ({ children }) => (
  <CardBox
    backgroundColor='#0B69A3'
    width='400px'
    height='660px'
    justify='flex-start'
    align='center'
    margin='20px 10px 0'
    padding='24px'
    boxShadow='none'
  >
    <CardText altColor='true' style={{textTransform:'inherit'}}>
      Create a Post
    </CardText>
    <Card.UserDetailsLine style={{width:'100%'}} borderColor='#FFFFFF' />
    {children}
  </CardBox>
)
export const LoginCard = ({ children }) => (
  <CardBox
    backgroundColor='#fff'
    width='500px'
    height='350px'
    justify='flex-start'
    align='center'
    margin='20px auto 0'
    padding='24px 60px 0'
    boxShadow='none'
  >
    {children}
  </CardBox>
)

export default CardBox
