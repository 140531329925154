import React from 'react'
// import './PersonalDetails.css'
import * as Profile from '../../styles'
import { User } from '../../../Picture'
import { UserDetailsCard } from '../../../Card'
import { UserInfoTable } from '../../../Table'
import { CardHeader } from '../../../../theme/style/typeface'

const PersonalDetails = ({ user }) => {
  const { firstname, lastname, profilePicture, dateOfBirth, BVN, gender, email, occupation, address, phoneNumber, phoneAlt, NOK, NOKphone, NOKaddress, relationship, NOKemail, userFiles } = user
  // const bankinfo = bank.map((bank, index) => (
  //   <BankDetails key={index} {...bank} />
  // ))
  return (
    <Profile.Details>
      <Profile.Wrapper>
        <Profile.Container>
          <User
            width='100px'
            height='100px'
            margin='40px'
            image={profilePicture}
          />
          <Profile.ContainerAlt style={{ padding: '0 10px 0 0' }}>
            <UserDetailsCard
              title='Personal Details'
              labelOne={'First Name'}
              labelTwo={'Last Name'}
              labelThree={'BVN'}
              labelFour={'D.O.B'}
              labelFive={'Gender'}
              labelSix={'Occupation'}
              labelSeven={'Email'}
              labelEight={'Phone'}
              labelNine={'Alternative number'}
              labelTen={'Address'}
              infoOne={firstname}
              infoTwo={lastname}
              infoThree={BVN}
              infoFour={dateOfBirth}
              infoFive={gender}
              infoSix={occupation}
              infoSeven={email}
              infoEight={phoneNumber}
              infoNine={phoneAlt}
              infoTen={address}
              hideTwo
              hidelineFour
              hideRowFourColTwo
              hideRowFourColThree
            />
            <UserDetailsCard
              labelOne={'Name of Next of Kin'}
              labelTwo={'Relationship'}
              labelThree={'Phone number'}
              labelFour={'Email address'}
              labelFive={'Address '}
              infoOne={NOK}
              infoTwo={relationship}
              infoThree={NOKphone}
              infoFour={NOKemail}
              infoFive={NOKaddress}
              title='Next of Kin Details'
              hideRowThree
              hideRowFour
              hidelineTwo
              hideRowTwoColThree />
            <Profile.Container style={{width:'95%'}}>
              { userFiles && userFiles.length > 0 ? <UserInfoTable title='' data={userFiles} setPagination /> : <CardHeader style={{ textTransform: 'inherit' }} padding='40px 0'> {`${firstname} ${lastname}`} has not uploaded any files</CardHeader>}
            </Profile.Container>
          </Profile.ContainerAlt>
        </Profile.Container>
      </Profile.Wrapper>
    </Profile.Details>
  )
}
export default PersonalDetails
