import React from 'react'
import { Logo } from '../Picture'
import Spinner from '../Tools/Spinner'
import * as ButtonStyle from './styles'

const Button = ({ content, clicked, loading, ...props }) => {
  return (
    <ButtonStyle.Container disabled={loading} onClick={clicked} {...props}>
      { loading ? <Spinner /> : content}
    </ButtonStyle.Container>
  )
}

export const CSVButton = ({ content, clicked, loading, alt, ...props }) => {
  return (
    <ButtonStyle.CSVButton onClick={clicked} alt={alt} {...props}>
      {content || 'Export CSV '}
      {alt ? <Logo
        src={require('../../assets/images/cloud-alt.svg')}
        width='20px' height='16px' margin='0 8px' />
        : <Logo
          src={require('../../assets/images/cloud.svg')}
          width='20px' height='16px' margin='0 8px' />}

    </ButtonStyle.CSVButton>
  )
}
export const AddAdminButton = ({ content, clicked, loading, alt, ...props }) => {
  return (
    <ButtonStyle.AddAdmin onClick={clicked} alt={alt} {...props}>
      {content || 'Add admin'}
      <Logo
        src={require('../../assets/images/plus.svg')}
        width='20px' height='16px' margin='0 8px' />
    </ButtonStyle.AddAdmin>
  )
}
export const PlatformButton = ({ content, clicked, loading, ...props }) => {
  return (
    <ButtonStyle.PlatformButton disabled={loading} onClick={clicked} {...props}>
      { loading ? <Spinner /> : content}
    </ButtonStyle.PlatformButton>
  )
}
export const ModalCancelButton = ({ content, clicked, loading, ...props }) => {
  return (
    <ButtonStyle.RedContainer disabled={loading} onClick={clicked} {...props}>
      { loading ? <Spinner /> : content}
    </ButtonStyle.RedContainer>
  )
}
export default Button
