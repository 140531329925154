import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { formatAmount } from '../../Tools/Formatter'
import DoughnutChart from '../../Charts/Doughnut'
import PieChart from '../../Charts/Pie'
import LineChart from '../../Charts/ContingentLineChart'
import { Label } from '../../../theme/style/typeface'

import { FinanceCard, FinanceSmallCard } from '../../Card'
const Finances = ({ state, dashboard, inputs, handleSetDate }) => {
  const { totalContractual, totalVoluntary, totalMatchingDeposit } = dashboard.deposits
  const { totalInstantWithdrawal, totalDeferredWithdrawal, totalWithdrawal } = dashboard.withdrawals
  const reducer = (accumulator, currentValue) => accumulator + currentValue
  return (
    <div id='finance'>
      <Row>
        <Col xs={12} md={6} lg={6}>
          <FinanceCard
            labelOne='Contractual deposits '
            labelTwo='Total voluntary deposit'
            labelThree='Total matching deposit'
            title='deposits'
            placeholderOne='month'
            placeholderTwo='year'
            showDate
            valueOne={formatAmount(totalContractual)}
            valueTwo={formatAmount(totalVoluntary)}
            valueThree={formatAmount(totalMatchingDeposit)}
            state={state.deposits}
            handleSetDate={(e) => handleSetDate(e, 'deposits')}
          >
            {inputs.doughnutData.datasets[0] && inputs.doughnutData.datasets[0].data.length > 0 && parseInt(inputs.doughnutData.datasets[0].data.reduce(reducer)) > 0 ? <DoughnutChart data={inputs.doughnutData} />
              : <Label padding='0 70px 0 0' style={{ textAlign: 'center' }}>
                 No Data
              </Label>
            }

            {/* <img src={require('../../../assets/images/circle.svg')} alt='Ellipse' /> */}
          </FinanceCard>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <FinanceCard
            title='withdrawals'
            labelOne='Quick withdrawal '
            labelTwo='Normal withdrawal'
            labelThree='Total withdrawal'
            placeholderOne='month'
            placeholderTwo='year'
            valueOne={formatAmount(totalInstantWithdrawal)}
            valueTwo={formatAmount(totalDeferredWithdrawal)}
            valueThree={formatAmount(totalWithdrawal)}
            showDate
            state={state.withdrawals}
            handleSetDate={(e) => handleSetDate(e, 'withdrawals')}
          >
            {inputs.pieData.datasets[0] && inputs.pieData.datasets[0].data.length > 0 && parseInt(inputs.pieData.datasets[0].data.reduce(reducer)) > 0 ? <PieChart data={inputs.pieData} />
              : <Label padding='0 70px 0 0' style={{ textAlign: 'center' }}>
                  No Data
              </Label>
            }

            {/* /  <img src={require('../../../assets/images/pie-chart.svg')} alt='Ellipse' /> */}
          </FinanceCard>
        </Col>
        <Col xs={12} md={12} lg={12}>
          <div className='finance-card'>
            <div className='body'>
              <div className='chart' >
                <LineChart data={inputs.contingentLineData} />
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={12} lg={12}>

          <FinanceSmallCard
            number={formatAmount(dashboard.totalContigentLiability)}
          />
          {/* <Card className='contingent'>
            <Card.Body>
              <Card.Text style={{ fontSize: '14px', lineHeight: '18px', color: '#000000' }}>
              Contingent Liabilities
              </Card.Text>
              <Card.Text style={{ fontSize: '24px', lineHeight: '24px', color: '#2E384D' }}>
              &#8358; 1,050,000
              </Card.Text>
            </Card.Body>
          </Card> */}
        </Col>
      </Row>
    </div>
  )
}

export default Finances
