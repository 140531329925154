import React from 'react'
import { PlatformBlogTable } from '../../../Table'
import DeleteBlogModal from '../../Modal/Blog/DeleteBlog'
import * as Page from '../../styles'
import { CSVButton } from '../../../Button'
import Editor from './BlogForm'

const Blog = ({ blogs, inputs, handleShowBlogForm, handleSaveBlog, handleEditorChange, handleImageChange, handleBlogInputs, handleShowDeleteBlogModal, handleSetBlog, handleDeleteBlog }) => {
  let content = <Editor inputs={inputs} handleBlogInputs={handleBlogInputs} handleImageChange={handleImageChange} handleEditorChange={handleEditorChange} handleSaveBlog={handleSaveBlog} handleShowBlogForm={handleShowBlogForm} />
  if (!inputs.showBlogForm) {
    content = (
      <PlatformBlogTable
        title
        setPagination
        handleSetBlog={handleSetBlog}
        data={blogs}
      />
    )
  }
  return (
    <Page.Details style={{ margin: '0' }}>
      <Page.Wrapper alt>
        <Page.ContainerAlt style={{ alignItems: 'flex-start' }}>
          {!inputs.showBlogForm ? <Page.Container style={{ margin: '0 10px 40px', justifyContent: 'flex-end' }}> <CSVButton alt='true' margin='0' /></Page.Container> : null}
          <Page.Container>
            {content}
          </Page.Container>
        </Page.ContainerAlt>
      </Page.Wrapper>
      <DeleteBlogModal
        show={inputs.showDeleteBlog}
        onHide={handleShowDeleteBlogModal}
        inputs={inputs}
        handleDeleteBlog={handleDeleteBlog}
      />
    </Page.Details>
  )
}
export default Blog
