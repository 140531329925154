import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import Navbar from './Container/Navbar'
import Dashboard from './Container/Dashboard'
import Users from './Container/Users'
import UserProfile from './Container/UserProfile'
import Transactions from './Container/Transactions'
import Support from './Container/Admin'
import Insights from './Container/Insights'
import Gallery from './Container/Gallery'
import Platform from './Container/Platform'
import './App.css'
import SignIn from './Container/Login'
import SideNav from './Components/Sidebar'
import swal from 'sweetalert'



function PrivateRoute ({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (localStorage.getItem('adminId')) {
          return <Component {...props} />
        }
        swal('Your have to login to view this page', '', 'error')
        return <Redirect
          to={{
            pathname: '/',
            state: { from: props.location }
          }}
        />
      }
      }
    />
  )
}
function SignedInRoute ({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (localStorage.getItem('adminId')) {
          swal('You are already Logged in', 'Please logout to Continue', 'warning')
          return (<Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location }
            }}
          />)
        }
        return <Component {...props} />
      }
      }
    />
  )
}
class App extends Component {
  render () {
    return (

      <Switch>
        <div id='main'>
          <SignedInRoute exact path='/' component={SignIn} />

          {
            this.props.location.pathname !== '/' ? <SideNav /> : null
          }
          {/* <Route  path='/' component={Navbar} /> */}
          <Container>
            {
              this.props.location.pathname !== '/' ? <Navbar /> : null
            }
            <PrivateRoute exact path='/dashboard' component={Dashboard} />
            <PrivateRoute exact path='/users' component={Users} />
            <PrivateRoute exact path='/users/userprofile' component={UserProfile} />
            <PrivateRoute path='/transactions' component={Transactions} />
            <PrivateRoute path='/admin' component={Support} />
            <PrivateRoute path='/insights' component={Insights} />
            <PrivateRoute path='/gallery' component={Gallery} />
            <PrivateRoute exact path='/platform' component={Platform} />
          </Container>
          {/* <Footer /> */}
        </div>
      </Switch>

    )
  }
}

export default withRouter(App)
