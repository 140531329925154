import styled from 'styled-components'


export const Details = styled.div`
margin: 30px auto;
width:100%;
#textEditor{
  height: 576px;
  border: 3px solid ${props => props.theme.actions};
}
`
export const Wrapper = styled.section`
width:100%;
min-height:820px;
background:${props => props.alt ? props.theme.transparent : props.theme.white};

`
export const Container = styled.div`
display:flex;
justify-content:flex-start;
align-items:flex-start;
width:100%;
min-height:100%;
&.activity-container{
  flex-wrap:wrap;
}
`
export const ContainerAlt = styled(Container)`
flex-direction: column;
justify-content:center;
align-items:center;
`
export const Underline = styled.hr`
border: .5px solid rgba(0, 0, 0, 0.301008);
width: 808px;
margin:0;
`

export const HouseImage = styled.img`
border-radius:50%;
width:120px;
height:120px;
margin:32px auto 10px;
`