import React from 'react'
import * as Table from './styles'

export const TableHead = ({ children, ...props }) => (
  <Table.Thead {...props}>
    <tr>
      {children}
    </tr>
  </Table.Thead>
)

export const TableBody = ({ history, clicked, children, ...props }) => (
  <Table.Tr onClick={clicked} {...props}>
    {children}
  </Table.Tr>
)

export const Text = ({ text, ...props }) => (
  <Table.Text {...props}>
    {text}
  </Table.Text>
)

export const Status = ({ status, accepted }) => {
  let statusMessage = (
    <Table.Accepted>
      <span>{ accepted || 'approved'}</span>
    </Table.Accepted>
  )
  if (status !== 'SUCCESSFUL') {
    statusMessage = (
      <Table.Unaccepted>
        <span>pending</span>
      </Table.Unaccepted>
    )
  }
  if (status === 'active') {
    statusMessage = (
      <Table.Accepted>
        <span>active</span>
      </Table.Accepted>
    )
  }
  if (status === 'inactive') {
    statusMessage = (
      <Table.Unaccepted>
        <span>inactive</span>
      </Table.Unaccepted>
    )
  }
  return (
    <Table.Stacked>
      {statusMessage}
    </Table.Stacked>
  )
}
