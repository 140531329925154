import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import Houses from './Tabs/Houses/Houses'
import Locations from './Tabs/Locations/Locations'
import Finances from './Tabs/Finances/Finances'

import Blog from './Tabs/Blog/Blog'
import Bonus from './Tabs/Bonus'
import { AddAdminButton } from '../Button'
import * as Page from './styles'
const Platform = ({
  state,
  selected,
  platform,
  handleSelected,
  handleGetState,
  handleGetStateUsers,
  handleGetLocaleUsers,
  handleRateValue,
  handlePostNewRate,
  handleRateCancel,
  handleShowAddModal,
  handleImageChange,
  handleOnChange,
  handleAddNewHouse,
  handleShowEditModal,
  handleEditNewHouse,
  handleSetHouse,
  handleShowDeleteModal,
  handleDeleteHouse,
  handleAddNewLocation,
  handleShowAddLocationModal,
  handleLocationInput,
  handleShowLocationTableModal,
  handleEditLocation,
  handleShowEditLocationModal,
  handleSetLocation,
  handleDeleteLocation,
  handleShowDeleteLocationModal,
  handleShowDeleteBlogModal,
  handleSetBlog,
  handleDeleteBlog,
  handleShowBlogForm,
  handleBlogInputs,
  handleEditorChange,
  handleSaveBlog,
  handleShowBonusModal,
  handleBonusInput,
  handleAddNewBonus,
  handleSetBonus,
  handleEditBonus,
  handleDeleteBonus
}) => {
  return (
    <Page.Details style={{ margin: '0 auto' }}>
      <Page.ContainerAlt style={{ alignItems: 'flex-start' }}>
        {selected === 'house' ? <div style={{ marginLeft: 'auto', paddingRight: '10px' }}><AddAdminButton clicked={handleShowAddModal} content='Add House' /></div> : null}
        {selected === 'blog' ? <div style={{ marginLeft: 'auto', paddingRight: '10px' }}><AddAdminButton clicked={handleShowBlogForm} disabled={state.showBlogForm} content='Create Post' /></div> : null}
        {selected === 'location' ? <div style={{ marginLeft: 'auto', paddingRight: '10px' }}><AddAdminButton clicked={handleShowAddLocationModal} content='Add Location' width='150px' /></div> : null }
        {selected === 'bonus' ? <div style={{ marginLeft: 'auto', paddingRight: '10px' }}><AddAdminButton clicked={handleShowBonusModal} content='Add Bonus' width='150px' /></div> : null }
        <Tabs id='firsthomes-tabs'
          defaultActiveKey='house'
          onSelect={link => handleSelected(link)}>

          <Tab eventKey='house' title='Houses'>
            <Houses
              inputs={state}
              houses={platform.houseDetails}
              handleShowAddModal={handleShowAddModal}
              handleOnChange={handleOnChange}
              handleImageChange={handleImageChange}
              handleAddNewHouse={handleAddNewHouse}
              handleShowEditModal={handleShowEditModal}
              handleEditNewHouse={handleEditNewHouse}
              handleSetHouse={handleSetHouse}
              handleShowDeleteModal={handleShowDeleteModal}
              handleDeleteHouse={handleDeleteHouse}
            />
          </Tab>
          <Tab eventKey='location' title='Locations'>
            <Locations
              inputs={state}
              location={platform.location}
              handleGetState={handleGetState}
              handleGetStateUsers={handleGetStateUsers}
              handleGetLocaleUsers={handleGetLocaleUsers}
              handleAddNewLocation={handleAddNewLocation}
              handleShowAddLocationModal={handleShowAddLocationModal}
              handleLocationInput={handleLocationInput}
              handleShowLocationTableModal={handleShowLocationTableModal}
              handleEditLocation={handleEditLocation}
              handleShowEditLocationModal={handleShowEditLocationModal}
              handleSetLocation={handleSetLocation}
              handleDeleteLocation={handleDeleteLocation}
              handleShowDeleteLocationModal={handleShowDeleteLocationModal}
            />
          </Tab>
          <Tab eventKey='finance' title='Finances'>
            <Finances rate={platform.interestRate} state={state} handleRateValue={handleRateValue} handlePostNewRate={handlePostNewRate} handleRateCancel={handleRateCancel} />
          </Tab>
          <Tab eventKey='blog' title='Blog' >
            <Blog
              inputs={state}
              blogs={platform.blogs}
              handleShowDeleteBlogModal={handleShowDeleteBlogModal}
              handleSetBlog={handleSetBlog}
              handleDeleteBlog={handleDeleteBlog}
              handleBlogInputs={handleBlogInputs}
              handleImageChange={handleImageChange}
              handleEditorChange={handleEditorChange}
              handleSaveBlog={handleSaveBlog}
              handleShowBlogForm={handleShowBlogForm}
            />
          </Tab>
          <Tab eventKey='bonus' title='Bonus' >
            <Bonus
              inputs={state}
              bonuses={platform.bonuses}
              handleShowBonusModal={handleShowBonusModal}
              handleBonusInput={handleBonusInput}
              handleAddNewBonus={handleAddNewBonus}
              handleSetBonus={handleSetBonus}
              handleEditBonus={handleEditBonus}
              handleDeleteBonus={handleDeleteBonus}
            />
          </Tab>
        </Tabs>
      </Page.ContainerAlt>

    </Page.Details>
  )
}

export default withRouter(Platform)
