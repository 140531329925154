const state = {
  dashboard: {
    analytics: [
      {
        figure: '0',
        title: 'Total Sign-ups',
        color: '#347FC4'
      },
      {
        figure: '0',
        title: 'Active Users',
        color: '#A167A5'
      },
      {
        figure: '0',
        title: 'Customer referrals',
        color: '#D499B9'
      },
      {
        figure: '0',
        title: 'Influencer referrals',
        color: '#FF6B6B'
      },
      {
        figure: '0%',
        title: 'Churn-rate',
        color: '#4281A4'
      }
    ],
    deposits: {
      'totalContractual': 0,
      'totalVoluntary': 0,
      'totalMatchingDeposit': 0,
      'overallTotal': 0
    },
    withdrawals: {
      'totalInstantWithdrawal': '0.00',
      'totalDeferredWithdrawal': '0.00',
      'totalWithdrawal': '0.00'
    },
    'totalContigentLiability': 0
  },
  users: {
    users: [],
    user: {
      details: {
        BVN: '',
        profilePicture: '',
        gender: '',
        highestDegree: 'Msc.',
        relationship: '',
        NOK: '',
        NOKphone: '',
        NOKaddress: '',
        NOKemail: '',
        phoneAlt: '',
        occupation: '',
        address: '',
        houseType: '',
        location: '',
        houseValue: '',
        totalEquity: '',
        totalVoluntaryContributions: '',
        totalReferralBonus: '',
        currentLevel: '',
        dateStarted: '',
        mortgageMaturityDate: '',
        phoneNumber: ' ',
        dateCreated: '',
        hasUsedReferralCode: '',
        otp: null,
        monthCreated: '',
        referralCode: '',
        email: '',
        referralCodeUsed: null,
        isVerified: true,
        lastname: '',
        hasMadeFirstPayment: true,
        yearCreated: '',
        userID: '',
        firstname: '',
        dateOfBirth: '',
        userFiles: []
      },
      activity: {
        activity: {
          totalSavings: 0,
          totalVoluntarySavings: 0,
          mandatorySavings: 0,
          contigentLiability: 0,
          totalSkippedMonths: 0
        },
        propertyDetails: {
          preferredHouseType: '',
          location: '',
          houseValue: 0
        }
      },
      bonus: [],
      usersCard: [],
      usersBank: [],
      deposits: [],
      withdraws: []
    }
  },
  transactions: {
    deposits: [],
    withdrawals: [],
    insights: {
      'highestDeposit': 0,
      'highestWithdrawal': 0,
      'averageWithdrawPerWeek': 0,
      'averageWithdrawalPerMonth': 0,
      'averageDepositPerMonth': 0,
      'averageDepositPerWeek': 0,
      'totalNumberOfSavings': 0,
      'totalNumberOfWithdrawals': 0
    }
  },
  admins: {
    admins: []
  },
  insights: {
    'oldest': 0,
    'youngest': 0,
    'averageAge': 0,
    'mostPreffered': [''],
    'leastPreffered': [''],
    'countryWithHighestDemand': '',
    'stateWithHighestDemand': [''],
    'stateWithLeastDemand': [''],
    'mostPreferredLocation': [''],
    'leastPreferredLocation': [''],
    'monthWithMostContribution': ['', 0],
    'monthWithLeastContribution': ['', 0],
    'weekDayWithHighestDeposit': ['', 0],
    'weekDayWithLeastDeposit': ['', 0],
    'monthWithMostWithdrawal': ['', 0],
    'monthWithLeastWithdrawal': ['', 0],
    'weekDayWithMostWithdrawal': ['0', 0],
    'weekDayWithLeastWithdrawal': ['', 0],
    'totalNumberOfReferrals': 0,
    'mostCommonHouseValue': 0

  },
  platform: {
    houseDetails: [],
    location: {
      countries: [],
      states: [],
      usersPerState: '',
      locales: [],
      usersPerLocale: '',
      allLocations: []
    },
    interestRate: '',
    bonuses: [],
    blogs: []
  },
  gallery: [],
  chartData: {
    barData: {
      labels: [],
      datasets: [
        {
          label: 'User Growth',
          data: [
          ],
          backgroundColor: '#347FC4'
        }
      ]
    },
    lineData: {
      labels: [],
      datasets: [
        {
          label: 'Contributions',
          data: [

          ],
          backgroundColor: 'rgba(255, 255, 255, .1)',
          borderColor: '#F8634B'
        }
      ]
    },
    doughnutData: {
      labels: [
        'Contractual deposits',
        'Total voluntary deposit',
        'Total matching deposit'
      ],
      datasets: [{
        data: [],
        backgroundColor: [
          '#2A2A72',
          '#FFA400',
          '#009FFD'
        ],
        hoverBackgroundColor: [
          '#2A2A72',
          '#FFA400',
          '#009FFD'
        ]
      }]
    },
    pieData: {
      labels: [
        'Quick withdrawal',
        'Normal withdrawal',
        'Total withdrawal'
      ],
      datasets: [{
        data: [0, 0, 0],
        backgroundColor: [
          '#2A2A72',
          '#FFA400',
          '#009FFD'
        ],
        hoverBackgroundColor: [
          '#2A2A72',
          '#FFA400',
          '#009FFD'
        ]
      }]
    },
    contingentLineData: {
      labels: [],
      datasets: [
        {
          label: 'Contingent Liabilites',
          data: [],
          backgroundColor: 'rgba(255, 255, 255, .1)',
          borderColor: '#10BE1E'
        }
      ]
    }
  }
}

export default state
