import React from 'react'
import * as Page from '../../styles'
import { ActivityCard } from '../../../Card'
const Houses = ({ insights }) => {
  const { mostPreffered, leastPreffered } = insights
  return (
    <Page.Details style={{ margin: '0 auto' }}>
      <Page.Wrapper alt>
        <Page.ContainerAlt>
          <Page.Container className='activity-container'>
            <ActivityCard title='Most preferred house type ' number={mostPreffered[0] || 'Studio apartment'} hide />
            <ActivityCard title='Least preferred house type' number={leastPreffered[0] || 'Detached house'} hide />
          </Page.Container>
        </Page.ContainerAlt>
      </Page.Wrapper>
    </Page.Details>
  )
}
export default Houses
