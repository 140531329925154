import React from 'react'
import * as Page from '../../styles'
import { ActivityCard } from '../../../Card'
import { formatAmount } from '../../../Tools/Formatter'
const Finances = ({ insights }) => {
  const {
    monthWithMostContribution,
    monthWithLeastContribution,
    weekDayWithHighestDeposit,
    weekDayWithLeastDeposit,
    monthWithMostWithdrawal,
    monthWithLeastWithdrawal,
    weekDayWithMostWithdrawal,
    weekDayWithLeastWithdrawal,
    totalNumberOfReferrals,
    mostCommonHouseValue
  } = insights
  return (
    <Page.Details style={{ margin: '0 auto' }}>
      <Page.Wrapper alt>
        <Page.ContainerAlt>
          <Page.Container className='activity-container'>
            <ActivityCard title='month With Most Contribution' number={`${monthWithMostContribution[0]}, ${monthWithMostContribution[1]}`} />
            <ActivityCard title='month With Least Contribution' number={`${monthWithLeastContribution[0]}, ${monthWithLeastContribution[1]}`} />
            <ActivityCard title='week Day With Highest Deposit' number={`${weekDayWithHighestDeposit[0]}, ${weekDayWithHighestDeposit[1]}`} />
            <ActivityCard title='week Day With Least Deposit' number={`${weekDayWithLeastDeposit[0]}, ${weekDayWithLeastDeposit[1]}`} />
            <ActivityCard title='month With Most Withdrawal' number={`${monthWithMostWithdrawal[0]}, ${monthWithMostWithdrawal[1]}`} />
            <ActivityCard title='month With Least Withdrawal' number={` ${monthWithLeastWithdrawal[0]}, ${monthWithLeastWithdrawal[1]}`} />
            <ActivityCard title='week Day With Most Withdrawal' number={`${weekDayWithMostWithdrawal[0]}, ${weekDayWithMostWithdrawal[1]}`} />
            <ActivityCard title='week Day With Least Withdrawal' number={` ${weekDayWithLeastWithdrawal[0]}, ${weekDayWithLeastWithdrawal[1]}`} />
            <ActivityCard title='total Number Of Referrals' number={`${formatAmount(totalNumberOfReferrals)}`} />
            <ActivityCard title='most Common House Value' number={`₦ ${formatAmount(mostCommonHouseValue || 0)}`} />
          </Page.Container>
        </Page.ContainerAlt>
      </Page.Wrapper>
    </Page.Details>
  )
}
export default Finances
