import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Wrapper, SubWrapperAlt } from '../../theme/style/styles'
import { Header } from '../../theme/style/typeface'
import Profile from '../../Components/UserProfile'

export default class index extends Component {
  render () {
    return (
      <Wrapper>
        {/*
         ==========================================================================
         React-Helmet
         ==========================================================================
        */}
        <Helmet>
          <meta charSet='utf-8' />
          <title> User Profile || Admin Dashboard </title>
        </Helmet>
        <SubWrapperAlt>
          <Header style={{ alignSelf: 'flex-start' }}>
              User Profile
          </Header>
          <Profile user={this.props.user} />
        </SubWrapperAlt>
      </Wrapper>
    )
  }
}
