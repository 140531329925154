import React from 'react'
import { Line } from 'react-chartjs-2'

const ContingentLineChart = ({ data }) => {
  return (
    <Line
      data={data}
      width={950}
      height={320}
      options={{ maintainAspectRatio: true }}
    />
  )
}

export default ContingentLineChart
