import React from 'react'
import * as Page from '../../../styles'
import { Editor } from '@tinymce/tinymce-react'
import Button from '../../../../Button'

const TextEditor = ({ inputs, handleShowBlogForm, handleEditorChange, handleSaveBlog }) => {
  return (
    <Page.Details style={{ margin: '20px 0 0' }}>
      <Page.Wrapper alt style={{ width: '100%' }}>
        <Page.ContainerAlt style={{ alignItems: 'flex-start', border: '1px solid #0B69A3' }}>
          <Editor
            apiKey='6r4qfsl8qgdauabt0ils6etgz4b398f5emshevs5ow9dhmvk' value={inputs.blog.body} onEditorChange={handleEditorChange} id='textEditor' />
        </Page.ContainerAlt>
        <Page.Container style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button content='Save' margin='24px 10px 0' clicked={handleSaveBlog} loading={inputs.loading} disabled={inputs.loading} />
          <Button content='Cancel' alt margin='24px 0 0 10px' clicked={handleShowBlogForm} />
        </Page.Container>
      </Page.Wrapper>
    </Page.Details>
  )
}
export default TextEditor
