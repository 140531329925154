import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { CardHeader } from '../../../../theme/style/typeface'
import { Logo } from '../../../Picture'
import { Main, Text } from '../../../Input'
import * as ModalStyle from '../../styles'
import Button from '../../../Button'
// import { TxtArea } from '../../../theme/style/Form';

const AddHouse = ({ inputs, handleOnChange, handleImageChange, handleAddNewHouse, ...props }) => {
  return (
    <Modal id='addAdmin_modal'
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <Form onSubmit={handleAddNewHouse}>
          <CardHeader
            padding='0'
            style={{ borderBottom: '2px solid rgba(238, 238, 238, .2)' }}>
            Add new House
            <Logo width='22px' height='22px' margin='0' src={require('../../../../assets/images/close-alt_blue.svg')} clicked={props.onHide} />
          </CardHeader>
          <ModalStyle.ContainerAlt>
            <ModalStyle.Container>
              {inputs.imagePreviewUrl ? <ModalStyle.HouseImage src={inputs.imagePreviewUrl} /> : null }
            </ModalStyle.Container>
            <ModalStyle.Container style={{ justifyContent: 'space-around' }}>
              {inputs.imageUploadUrl  ? <Main
                type='text'
                value={inputs.imageUploadUrl}
                width='206px'
                height='50px'
                margin='0'
                altBorder='#D0D5DA'
                fontSize='14px'
                disabled
              /> : <Main
                type='file'
                width='206px'
                height='50px'
                margin='0'
                altBorder='#D0D5DA'
                fontSize='14px'
                changed={handleImageChange}
              /> }
              <Main
                type='text'
                placeholder='house type'
                name='houseType'
                value={inputs.houseType}
                width='206px'
                height='50px'
                margin='0'
                altBorder='#D0D5DA'
                changed={handleOnChange}
                fontSize='14px'
              />
            </ModalStyle.Container>
            <ModalStyle.Container style={{ justifyContent: 'space-around' }}>
              <Text
                type='text'
                placeholder='description'
                name='description'
                value={inputs.description}
                width='446px'
                height='140px'
                margin='0'
                altBorder='#D0D5DA'
                changed={handleOnChange}
                fontSize='14px'
              />
            </ModalStyle.Container>
          </ModalStyle.ContainerAlt>
          <ModalStyle.Container>
            <Button type='submit' loading={inputs.loading} margin='32px auto 50px' content='Save' fontSize='16px' borderRadius='4px' />
          </ModalStyle.Container>

        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default AddHouse
