import React from 'react'
import { TableBody, Text, Status } from './TableParts'
import { Logo } from '../Picture'
import { formatAmount } from '../Tools/Formatter'
import moment from 'moment'
import { Button, EditIMG, DeleteIMG, TextWrapper } from './styles'

export const UsersRow = ({ user, handleGetSelectUser, ...props }) => (
  <TableBody clicked={() => handleGetSelectUser(user.userID)} {...props}>
    <td >
      <Text
        text={`${user.firstname} ${user.lastname}`}
      />
    </td>
    <td>
      <Text
        text={user.email}
      />
    </td>
    <td>
      <Text
        text={user.dateOfBirth}
      />
    </td>
    <td>
      <Text
        text={user.phoneNumber}
      />
    </td>
    <td>
      <Text
        text={user.location.split(',')[0] || 'Lagos'}
      />
    </td>
  </TableBody>
)
export const UsersDepositRow = ({ deposit, ...props }) => {
  let timestamp = moment(new Date(deposit.createdAt)).format('LT')
  let cardName = Math.floor(Math.random() * 3) < 2 ? 'Visa' : 'Mastercard'
  let card = deposit.card ? deposit.card : `${cardName} ${Math.ceil(Math.random().toFixed(4) * 10000)}`
  return (
    <TableBody {...props}>
      <td >
        <Text
          text={formatAmount(deposit.depositAmount)}
        />
      </td>
      <td>
        <Text
          text={card}
        />
      </td>
      <td>
        <Text
          text={deposit.type}
        />
      </td>
      <td>
        <Text
          text={deposit.depositDate}
        />
      </td>
      <td>
        <Text
          text={timestamp}
        />
      </td>
    </TableBody>
  )
}

export const UsersWithdrawalRow = ({ withdrawal, ...props }) => {
  let timestamp = moment(new Date(withdrawal.createdAt)).format('LT')
  let cardName = Math.floor(Math.random() * 3) < 2 ? 'Visa' : 'Mastercard'
  let card = withdrawal.card ? withdrawal.card : `${cardName} ${Math.ceil(Math.random().toFixed(4) * 10000)}`

  return (
    <TableBody {...props}>
      <td >
        <Text
          text={formatAmount(withdrawal.amount)}
        />
      </td>
      <td>
        <Text
          text={card}
        />
      </td>
      <td>
        <Text
          text={withdrawal.typeOfWithdrawal}
        />
      </td>
      <td>
        <Text
          text={withdrawal.withdrawalDate}
        />
      </td>
      <td>
        <Text
          text={timestamp}
        />
      </td>
    </TableBody>
  )
}
export const UsersBonusRow = ({ bonus, history, ...props }) => (
  <TableBody history={history} {...props}>
    <td>
      <Text
        text={bonus.name}
      />
    </td>
    <td>
      <Text
        text={bonus.customerType}
      />
    </td>
    <td>
      <Text
        text={bonus.bonus || 'Welcome Bonus'}
      />
    </td>
    <td >
      <Text
        text={formatAmount(bonus.amount || 0)}
      />
    </td>
  </TableBody>
)
export const TransactionDepositRow = ({ deposit, ...props }) => (
  <TableBody className='transactionTable' {...props}>
    <td>
      <Text
        text={deposit.name}
      />
    </td>
    <td>
      <Text
        text={`₦ ${formatAmount(deposit.depositAmount || 0)}`}
      />
    </td>
    <td >
      <Text
        text={moment(new Date(deposit.depositDate)).format('DD/MM/YYYY')}
      />
    </td>
    <td >
      <Text
        text={deposit.type}
      />
    </td>
    {/* <td >
      <Text
        text={deposit.bank || 'undisclosed'}
      />
    </td> */}
    <td >
      <Text
        text={deposit.reference}
      />
    </td>
  </TableBody>
)

export const TransactionWithdrawalRow = ({ withdrawal, ...props }) => (
  <TableBody className='transactionTable' {...props}>
    <td>
      <Text
        text={withdrawal.name}
      />
    </td>
    <td>
      <Text
        text={`₦ ${formatAmount(withdrawal.amount || 0)}`}
      />
    </td>
    <td >
      <Text
        text={moment(new Date(withdrawal.withdrawalDate)).format('DD/MM/YYYY')}
      />
    </td>
    <td >
      <Text
        text={withdrawal.typeOfWithdrawal}
      />
    </td>
    <td >
      <Status
        status={withdrawal.status}
      />
    </td>
    <td >
      <Text
        text={withdrawal.reference}
      />
    </td>
  </TableBody>
)
export const PlatformHousesRow = ({ house, handleSetHouse, ...props }) => (
  <TableBody {...props}>
    <td>
      <Logo
        width='40px'
        height='40px'
        src={house.image || require('../../assets/images/apartment_one.png')}
        style={{ borderRadius: '50%' }}
      />
    </td>
    <td>
      <Text
        text={house.houseType}
      />
    </td>
    <td>
      <Text
        text={house.description}
      />
    </td>
    {/* <td >
      <Text
        text={`₦ ${formatAmount(house.amount || 1000000)}`}
      />
    </td> */}
    <td >
      <Button> <EditIMG onClick={() => handleSetHouse(house)} /> </Button>
      <Button> <DeleteIMG onClick={() => handleSetHouse(house, 'delete')} /> </Button>
    </td>
  </TableBody>
)
export const PlatformBlogRow = ({ blog, handleSetBlog, ...props }) => (
  <TableBody {...props}>
    <td>
      <Text
        text={blog.title}
      />
    </td>
    <td>
      <Text
        text={moment(new Date(blog.timestamp)).fromNow()}
      />
    </td>
    <td>
      <Text
        text={Math.ceil(Math.random() * 1000)}
      />
    </td>
    <td >
      <Button> <EditIMG onClick={() => handleSetBlog(blog)} /> </Button>
      <Button> <DeleteIMG onClick={() => handleSetBlog(blog, 'delete')} /> </Button>
    </td>
  </TableBody>
)
export const AdminRow = ({ admin, handleSetAdmin, ...props }) => {
  let priviledges = admin.privileges.sort().map(priviledge => (
    <Text
      key={Math.random()}
      text={priviledge}
      style={{ marginRight: '10px' }}
    />
  ))
  return (
    <TableBody className='noCursor' {...props}>
      <td>
        <Text
          text={admin.name}
        />
      </td>
      <td>
        <Text
          text={admin.email}
        />
      </td>
      <td style={{ paddingLeft: '10px' }}>
        <TextWrapper>
          { priviledges }
        </TextWrapper>
      </td>
      <td >
        <Button> <EditIMG onClick={() => handleSetAdmin(admin)} /> </Button>
        <Button> <DeleteIMG onClick={() => handleSetAdmin(admin, 'delete')} /> </Button>
      </td>
    </TableBody>
  )
}
export const PLatformLocationRow = ({ location, handleSetLocation, ...props }) => {
  return (
    <TableBody className='noCursor' {...props}>
      <td>
        <Text
          style={{ textTransform: 'capitalize' }}
          text={location.country}
        />
      </td>
      <td>
        <Text
          style={{ textTransform: 'capitalize' }}
          text={location.state}
        />
      </td>
      <td>
        <Text
          text={location.location}
        />
      </td>
      <td >
        <Button> <EditIMG onClick={() => handleSetLocation(location)} /> </Button>
        <Button> <DeleteIMG onClick={() => handleSetLocation(location, 'delete')} /> </Button>
      </td>
    </TableBody>
  )
}
export const PLatformBonusRow = ({ bonus, handleSetBonus, ...props }) => {
  return (
    <TableBody className='noCursor' {...props}>
      <td>
        <Text
          style={{ textTransform: 'capitalize' }}
          text={bonus.bonusName}
        />
      </td>
      <td>
        <Text
          style={{ textTransform: 'capitalize' }}
          text={formatAmount(bonus.bonusAmount)}
        />
      </td>
      <td>
        <Status
          status={bonus.bonusStatus}
        />
      </td>
      <td >
        <Button> <EditIMG onClick={() => handleSetBonus(bonus)} /> </Button>
        <Button> <DeleteIMG onClick={() => handleSetBonus(bonus, 'delete')} /> </Button>
      </td>
    </TableBody>
  )
}
export const UserInfoRow = ({ info, ...props }) => {
  let link = info.document.slice(0, 20)
  return (
    <TableBody className='noCursor' {...props}>
      <td>
        <Text
          text={info.documentName}
        />
      </td>
      <td>
        <a href={`${info.document}`} target='_blank' rel='noopener noreferrer'>
          <Text
            // className='dont-break-out'
            text={`${link}...`}
          />
        </a>

      </td>
      <td>
        <Text
          text={moment(new Date(info.dateAdded)).format('DD/MM/YYYY')}
        />
      </td>
    </TableBody>
  )
}
