import React from 'react'
import * as Page from './styles'

const Pagination = ({ data, pages, currentPage, handlePagnationUp, handlePagnationDown, handleDataRange, pageUsers, newindexOfFirstUser, allUsers, pageNumbers, selectedPage, pageLimit, upperPageBound, lowerPageBound }) => {

  // let pages
  // if (pageNumbers().length && pageNumbers().length > 0 ) {
  //   pages = ()=> pageNumbers().map(page => {
  //   if (page.page === data.currentPage) {
  //     return <Page.PageNumber active key={page.id} onClick={() => selectedPage(page.page)} className='currentpage'>{page.page}</Page.PageNumber>
  //   }
  //   if (page.page <= upperPageBound && page.page > lowerPageBound) {
  //     return <Page.PageNumber key={page.id} onClick={() => selectedPage(page.page)}>{page.page}</Page.PageNumber>
  //   }
  //   return null
  // })}
  return (
    <Page.Wrapper>
      <Page.PageNumber> Showing {newindexOfFirstUser} - {pageUsers} of {allUsers || ''}  </Page.PageNumber>
      <div>
        {pages.currentPage !== 1 ? <Page.Button onClick={handlePagnationDown} value='Prev'> <img className='double_arrow rotate ' src={require('../../../assets/images/double_arrow-left.svg')} alt='double_arrow' /> Prev </Page.Button > : null}
        <Page.PageNumber> {pages.currentPage} / {Math.ceil(data.length / pages.usersPerPage)} </Page.PageNumber>
        {pages.currentPage >= Math.ceil(data.length / pages.usersPerPage) ? null : <Page.Button onClick={handlePagnationUp} type='button' value='Next'>Next <img className='double_arrow' src={require('../../../assets/images/double_arrow-right.svg')} alt='double_arrow' /></Page.Button > }
      </div>
    </Page.Wrapper>
  )
}

export default Pagination
