import AdminReducers from './AdminReducers'
import AuthReducers from './AuthReducers'
import { combineReducers } from 'redux'

const rootReducers = combineReducers({
  Auth: AuthReducers,
  Admin: AdminReducers
})

export default rootReducers
