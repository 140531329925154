import type from '../Type'
import axios from 'axios'
import { handleError } from '../../lib'

const { GET_GENERAL_INSIGHTS, GET_HOUSE_INSIGHTS, GET_LOCATION_INSIGHTS, GET_FINANCE_INSIGHTS } = type

const getAllGeneralInsights = payload => ({
  type: GET_GENERAL_INSIGHTS,
  payload
})

const getAllHousesInsights = payload => ({
  type: GET_HOUSE_INSIGHTS,
  payload
})
const getAllLocationInsights = payload => ({
  type: GET_LOCATION_INSIGHTS,
  payload
})

const getAllFinanceInsights = payload => ({
  type: GET_FINANCE_INSIGHTS,
  payload
})

export const handleFetchAllGeneralInsights = () => dispatch => {
  return axios
    .get(`/general/insights`)
    .then(res => {
      let result = res.data
      dispatch(getAllGeneralInsights(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleFetchAllHousesInsights = () => dispatch => {
  return axios
    .get(`/general/insights/house`)
    .then(res => {
      let result = res.data
      dispatch(getAllHousesInsights(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleFetchAllLocationInsights = () => dispatch => axios
  .get(`/general/insights/location`)
  .then(res => {
    let result = res.data
    dispatch(getAllLocationInsights(result))
    return res
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })

export const handleFetchAllFinanceInsights = () => dispatch => axios
  .get(`/general/insights/financial`)
  .then(res => {
    let result = res.data
    dispatch(getAllFinanceInsights(result))
    return res
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })
