import React, { Fragment } from 'react'
import * as Com from './styles'

/**
 *  Name component to Display Name and profile of Admin and toggle dropdown
 */
export const AdminName = ({ name, email, clicked, children, ...props }) => {
  // const Label = marginBot ? Form.NoMLabel : Form.Label
  return (
    <Fragment>
      <Com.NameContainer onClick={clicked}>
        <Com.PairAlt>
          <Com.NameSpan>{ name || 'Ore Beecroft' }</Com.NameSpan>
          <Com.Pair>
            <Com.EmailSpan>{ email || 'obeecroft@gmail.com '}</Com.EmailSpan>
            <Com.IMG src={require('../../assets/images/chevron-down.svg')} alt='down-arrow' {...props} />
          </Com.Pair>
        </Com.PairAlt>
      </Com.NameContainer>
      {children}
    </Fragment>
  )
}
