import type from '../Type'
import axios from 'axios'
import { handleError } from '../../lib'

const { GET_HOUSES, ADD_HOUSE, EDIT_HOUSE, GET_COUNTRIES, GET_STATES, GET_USERS_LOCALE, GET_USERS_STATE, GET_RATE, GET_ALL_BLOGS, SET_ALL_LOCATIONS, SET_ALL_BONUSES } = type

const getAllHouses = payload => ({
  type: GET_HOUSES,
  payload
})
const setNewHouse = payload => ({
  type: ADD_HOUSE,
  payload
})
const setEditHouse = payload => ({
  type: EDIT_HOUSE,
  payload
})
const getAllCountries = payload => ({
  type: GET_COUNTRIES,
  payload
})

const getAllCountryStates = payload => ({
  type: GET_STATES,
  payload
})
const getAllUsersForState = payload => ({
  type: GET_USERS_STATE,
  payload
})
const getAllUsersForLocale = payload => ({
  type: GET_USERS_LOCALE,
  payload
})
const getInterestRate = payload => ({
  type: GET_RATE,
  payload
})
const getAllBlogs = payload => ({
  type: GET_ALL_BLOGS,
  payload
})
const setAllLocations = payload => ({
  type: SET_ALL_LOCATIONS,
  payload
})
const setAllBonuses = payload => ({
  type: SET_ALL_BONUSES,
  payload
})

export const handleFetchAllHouses = () => dispatch => {
  return axios
    .get(`/platform`)
    .then(res => {
      let result = res.data
      dispatch(getAllHouses(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleAddNewHouse = house => dispatch => {
  return axios
    .post(`/platform`, house)
    .then(res => {
      let result = res.data
      dispatch(setNewHouse(result))
      return result
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleEditHouse = (house, houseId) => dispatch => {
  return axios
    .put(`/platform/${houseId}`, house)
    .then(res => {
      let result = res.data
      dispatch(setEditHouse(result))
      return result
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}

export const handleDeleteHouse = houseId => dispatch => {
  return axios
    .delete(`/platform/${houseId}`)
    .then(res => {
      let result = res.data
      return result
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleFetchAllCountries = () => dispatch => {
  return axios
    .get(`/country`)
    .then(res => {
      let result = res.data
      dispatch(getAllCountries(result))
      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleFetchAllCountryStates = country => dispatch => {
  return axios
    .post(`/country/state`, country)
    .then(res => {
      let result = res.data
      dispatch(getAllCountryStates(result))

      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleFetchAllUsersForState = state => dispatch => {
  return axios
    .post(`/state/user`, state)
    .then(res => {
      let result = res.data
      dispatch(getAllUsersForState(result))

      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleFetchAllUsersForLocale = users => dispatch => {
  return axios
    .post(`/location/user`, users)
    .then(res => {
      let result = res.data
      dispatch(getAllUsersForLocale(result))

      return res
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handleFetchInterestRate = () => dispatch => {
  return axios
    .get(`/rate`)
    .then(res => {
      let result = res.data
      dispatch(getInterestRate(result))
      return result
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}
export const handlePostNewInterestRate = rate => dispatch => {
  return axios
    .post(`/rate`, rate)
    .then(res => {
      let result = res.data
      dispatch(getInterestRate(result))
      return result
    })
    .catch(err => {
      handleError(err)
      console.log(err)
    })
}

export const handleFetchAllBlogs = () => dispatch => axios
  .get(`/blog`)
  .then(res => {
    let result = res.data
    dispatch(getAllBlogs(result))
    return result
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })
export const handlePostNewLocation = data => dispatch => axios
  .post(`/location`, data)
  .then(res => {
    let result = res.data
    return result
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })
export const handleFetchAllLocation = () => dispatch => axios
  .get(`/location`)
  .then(res => {
    let result = res.data
    dispatch(setAllLocations(result))
    return result
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })
export const handleEditLocation = (data, id) => dispatch => axios
  .put(`/location/${id}`, data)
  .then(res => {
    let result = res.data
    return result
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })
export const handleDeleteLocation = id => dispatch => axios
  .delete(`/location/${id}`)
  .then(res => {
    let result = res.data
    return result
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })

export const handleAddNewBlog = blog => dispatch => axios
  .post(`/blog`, blog)
  .then(res => {
    let result = res.data
    return result
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })
export const handleEditBlog = (blog, id) => dispatch => axios
  .put(`/blog/${id}`, blog)
  .then(res => {
    let result = res.data
    return result
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })
export const handleDeleteBlog = id => dispatch => axios
  .delete(`/blog/${id}`)
  .then(res => {
    let result = res.data
    return result
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })

export const handleFetchAllBonuses = () => dispatch => axios
  .get(`/bonus`)
  .then(res => {
    let result = res.data
    dispatch(setAllBonuses(result))
    return result
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })

  export const handlePostNewBonus = bonus => dispatch => axios
  .post(`/bonus`, bonus)
  .then(res => {
    let result = res.data
    return result
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })

  export const handleEditBonus = (bonus, id) => dispatch => axios
  .put(`/bonus/${id}`, bonus)
  .then(res => {
    let result = res.data
    return result
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })

  export const handleDeleteBonus = id => dispatch => axios
  .delete(`/bonus/${id}`)
  .then(res => {
    let result = res.data
    // dispatch(setAllBonuses(result))
    return result
  })
  .catch(err => {
    handleError(err)
    console.log(err)
  })
 