export default {
    black: '#071013',
    bodyText: '#2E384D',
    cardHeader: '#009FFD',
    background: '#F2F2F2',
    placeholder: '#565F62',
    text: '#334E68',
    actions: '#0B69A3',
    green: '#10BE1E',
    red: '#E12D39',
    ActiveLine: '#F8634B',
    white: '#ffffff',
    blue: '#347FC4',
    title: '#333333',
    transparent: 'transparent',
    faintBorder: 'rgba(86, 95, 98, .1)',
    grayText: '#B0BAC9',
    blackText: '#000',
    faintRed: 'rgba(252, 208, 201, 0.25)',
    tHeaderText: '#93A2B8',
    faintGreen: 'rgba(16, 190, 30, .3)',
    buttonRed: '#DC493A'

}