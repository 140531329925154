import React from 'react'
import { Bar } from 'react-chartjs-2'

const BarChart = ({ data }) => {
  return (
    <Bar
      data={data}
      width={950}
      height={320}
      options={{ maintainAspectRatio: false }}
    />
  )
}

export default BarChart
