import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import Users from './Tabs/Users'
import Houses from './Tabs/Houses'
import Locations from './Tabs/Locations'
import * as Page from './styles'
import Finances from './Tabs/Finances';
const Insights = ({ insights }) => {
  return (
    <Page.Details style={{ margin: '0 auto' }}>

      {/*
          ==========================================================================
          Userprofile-Content
          ==========================================================================
        */}
      <Tabs
        defaultActiveKey='users'
        id='firsthomes-tabs'
      >
        <Tab eventKey='users' title='Users'>
          <Users insights={insights} />
        </Tab>
        <Tab eventKey='houses' title='Houses'>
          <Houses insights={insights} />
        </Tab>
        <Tab eventKey='location' title='Locations'>
          <Locations insights={insights} />
        </Tab>
        <Tab eventKey='finance' title='Finances'>
          <Finances insights={insights} />
        </Tab>
      </Tabs>
    </Page.Details>
  )
}

export default Insights
