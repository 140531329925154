import React from 'react'
import { Line } from 'react-chartjs-2'

const LineChart = ({ data, width, height }) => {
  return (
    <Line
      data={data}
      width={width || 950}
      height={height || 320}
      options={{ maintainAspectRatio: false }}
    />
  )
}

export default LineChart
