import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { CardHeader } from '../../../../theme/style/typeface'
import { Logo } from '../../../Picture'
import { Main } from '../../../Input'
import * as ModalStyle from '../../styles'
import Button from '../../../Button'

const AddLocation = ({ inputs, handleLocationInput, handleAddNewLocation, ...props }) => {
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <Form onSubmit={handleAddNewLocation} >
          <CardHeader
            padding='0'
            style={{ borderBottom: '2px solid rgba(238, 238, 238, .2)' }}>
            Add new Location
            <Logo width='22px' height='22px' margin='0' src={require('../../../../assets/images/close-alt_blue.svg')} clicked={props.onHide} />
          </CardHeader>
          <ModalStyle.ContainerAlt>

            <Main
              type='text'
              placeholder='Country'
              name='country'
              value={inputs.location.country}
              width='206px'
              height='50px'
              margin='0'
              altBorder='#D0D5DA'
              changed={handleLocationInput}
              fontSize='14px'
            />
            <Main
              type='text'
              placeholder='State'
              name='state'
              value={inputs.location.state}
              width='206px'
              height='50px'
              margin='0'
              altBorder='#D0D5DA'
              changed={handleLocationInput}
              fontSize='14px'
            />
            <Main
              type='text'
              placeholder='Location'
              name='location'
              value={inputs.location.location}
              width='206px'
              height='50px'
              margin='0'
              altBorder='#D0D5DA'
              changed={handleLocationInput}
              fontSize='14px'
            />
          </ModalStyle.ContainerAlt>
          <ModalStyle.Container>
            <Button type='submit' loading={inputs.loading} margin='32px auto 50px' content='Save' fontSize='16px' borderRadius='4px' />
          </ModalStyle.Container>

        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default AddLocation
