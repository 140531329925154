import type from '../Type'
import axios from 'axios'
import { handleError } from '../../lib'
import swal from 'sweetalert'

const { GET_IMAGES } = type

const getAllImages = payload => ({
  type: GET_IMAGES,
  payload
})

export const handleFetchAllImages = () => dispatch => axios
  .get(`/gallery`)
  .then(res => {
    let result = res.data
    dispatch(getAllImages(result))
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
  })
export const handleDeleteImage = id => dispatch => axios
  .delete(`/gallery/${id}`)
  .then(res => {
    let result = res.data
    swal('Deleted', result.message, 'success')
    return result
  }).catch(err => {
    handleError(err)
    console.log(err)
  })

export const handleGalleryUploadImage = image => dispatch => {
  let picture = image
  let formData = new FormData()
  for (let index = 0; index < picture.length; index++) {
    const image = picture[index]
    formData.append('picture', image)
  }
  return axios
    .post(`/gallery`, formData)
    .then(res => {
      let result = res.data
      // swal('New Admin created', `${result.data.message}`, 'success' )
      return result
    }).catch(err => {
      handleError(err)
      console.log(err)
    })
}

