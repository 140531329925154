import Platform from '../../Pages/Platform'
import { connect } from 'react-redux'
import { handleFetchAllHouses,
  handleFetchAllCountries,
  handleFetchAllCountryStates,
  handleFetchAllUsersForState,
  handleFetchAllUsersForLocale,
  handleFetchInterestRate,
  handlePostNewInterestRate,
  handleFetchAllBlogs,
  handleUploadImage,
  handleAddNewHouse,
  handleEditHouse,
  handleDeleteHouse,
  handlePostNewLocation,
  handleFetchAllLocation,
  handleEditLocation,
  handleDeleteLocation,
  handleAddNewBlog,
  handleDeleteBlog,
  handleEditBlog,
  handleFetchAllBonuses,
  handlePostNewBonus,
  handleEditBonus,
  handleDeleteBonus } from '../../Store/Actions'

/**
 * here we handle passing redux to our component and export
 */
const mapStateToProps = state => ({
  platform: state.Admin.platform
})

const mapDispatchToProps = dispatch => ({
  handleFetchAllHouses () {
    return dispatch(handleFetchAllHouses())
  },
  handleFetchAllCountries () {
    return dispatch(handleFetchAllCountries())
  },
  handleFetchAllCountryStates (country) {
    return dispatch(handleFetchAllCountryStates(country))
  },
  handleFetchAllUsersForState (state) {
    return dispatch(handleFetchAllUsersForState(state))
  },
  handleFetchAllUsersForLocale (state) {
    return dispatch(handleFetchAllUsersForLocale(state))
  },
  handleFetchInterestRate () {
    return dispatch(handleFetchInterestRate())
  },
  handlePostNewInterestRate (rate) {
    return dispatch(handlePostNewInterestRate(rate))
  },
  handleFetchAllBlogs () {
    return dispatch(handleFetchAllBlogs())
  },
  handleUploadImage (image) {
    return dispatch(handleUploadImage(image))
  },
  handleAddNewHouse (house) {
    return dispatch(handleAddNewHouse(house))
  },
  handleEditHouse (newHouse, id) {
    return dispatch(handleEditHouse(newHouse, id))
  },
  handleDeleteHouse (id) {
    return dispatch(handleDeleteHouse(id))
  },
  handlePostNewLocation (location) {
    return dispatch(handlePostNewLocation(location))
  },
  handleFetchAllLocation () {
    return dispatch(handleFetchAllLocation())
  },
  handleEditLocation (location, id) {
    return dispatch(handleEditLocation(location, id))
  },
  handleDeleteLocation (id) {
    return dispatch(handleDeleteLocation(id))
  },
  handleDeleteBlog (id) {
    return dispatch(handleDeleteBlog(id))
  },
  handleAddNewBlog (blog) {
    return dispatch(handleAddNewBlog(blog))
  },
  handleEditBlog (blog, id) {
    return dispatch(handleEditBlog(blog, id))
  },
  handleFetchAllBonuses () {
    return dispatch(handleFetchAllBonuses())
  },
  handlePostNewBonus (data) {
    return dispatch(handlePostNewBonus(data))
  },
  handleEditBonus (bonus, id) {
    return dispatch(handleEditBonus(bonus, id))
  },
  handleDeleteBonus(id){
    return dispatch(handleDeleteBonus(id))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Platform)
