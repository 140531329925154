import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import PersonalDetails from './Tabs/personalDetails/PersonalDetails'
import * as Page from './styles'
import Activity from './Tabs/Activity'
import TransactionsHistory from './Tabs/Transactions'
import BonusHistory from './Tabs/Bonus'

const UserProfile = ({ user }) => {
  return (
    <Page.Details>

      {/*
          ==========================================================================
          Userprofile-Content
          ==========================================================================
        */}
      <Tabs
        defaultActiveKey='personal'
        id='firsthomes-tabs'
      >

        <Tab eventKey='personal' title='Profile'>
          <PersonalDetails user={user.details} />
        </Tab>
        <Tab eventKey='activity' title='Activity'>
          <Activity user={user} />
        </Tab>
        <Tab eventKey='transaction' title='Transaction history '>
          <TransactionsHistory user={user} />
        </Tab>
        <Tab eventKey='bonus' title='Bonus history '>
          <BonusHistory user={user} />
        </Tab>
      </Tabs>
    </Page.Details>
  )
}
export default withRouter(UserProfile)
