import State from '../DummyStore'
import { formatAmount } from '../../Components/Tools/Formatter'

const adminReducer = (state = State, action) => {
  switch (action.type) {
    case 'GET_ADMINS':
      return {
        ...state,
        admins: { ...state.admins, admins: [ ...action.payload.data ] }
      }
    case 'GET_ALL_USERS':
      return {
        ...state,
        users: { ...state.users, users: [...action.payload.data] }
      }
    case 'GET_USER_ACTIVITY':
      return {
        ...state,
        users: { ...state.users, user: { ...state.users.user, activity: { ...action.payload.data } } }
      }
    case 'GET_USER_INFO':
      return {
        ...state,
        users: { ...state.users, user: { ...state.users.user, details: { ...state.users.user.details, profilePicture: null, userFiles: [], ...action.payload.data } } }
      }
    case 'GET_USER_HISTORY':
      return {
        ...state,
        users: { ...state.users, user: { ...state.users.user, ...action.payload.data } }
      }
    case 'GET_USER_BONUS':
      return {
        ...state,
        users: { ...state.users, user: { ...state.users.user, bonus: [ ...action.payload.data.referralAndAmount ] } }
      }

    case 'GET_ALL_DEPOSITS':
      // console.log(action.payload)
      return {
        ...state,
        transactions: { ...state.transactions, deposits: [ ...action.payload.data.transactions ] }
      }
    case 'GET_ALL_WITHDRAWALS':
      return {
        ...state,
        transactions: { ...state.transactions, withdrawals: [ ...action.payload.data.withdrawals ] }
      }
    case 'GET_INSIGHT':
      return {
        ...state,
        transactions: { ...state.transactions, insights: { ...action.payload.data.insights } }
      }
    case 'GET_GENERAL_INSIGHTS':
      return {
        ...state,
        insights: { ...state.insights, ...action.payload.data }
      }
    case 'GET_HOUSE_INSIGHTS':
      return {
        ...state,
        insights: { ...state.insights, ...action.payload.data }
      }
    case 'GET_LOCATION_INSIGHTS':
      return {
        ...state,
        insights: { ...state.insights, ...action.payload.data }
      }
    case 'GET_FINANCE_INSIGHTS':
      return {
        ...state,
        insights: { ...state.insights, ...action.payload.data }
      }

    case 'GET_HOUSES':
      return {
        ...state,
        platform: { ...state.platform, houseDetails: [ ...action.payload.data.houseDetails ] }
      }
    case 'ADD_HOUSE':
      return {
        ...state,
        platform: { ...state.platform, houseDetails: [ ...state.platform.houseDetails, action.payload.data.houseDetails ] }
      }
    case 'EDIT_HOUSE':
      return {
        ...state,
        platform: { ...state.platform, houseDetails: [ ...state.platform.houseDetails, action.payload.data.newHouseDetails ] }
      }

    case 'GET_COUNTRIES':
      return {
        ...state,
        platform: { ...state.platform, location: { ...state.platform.location, countries: [ ...action.payload.data.countries ] } }
      }
    case 'GET_STATES':
      return {
        ...state,
        platform: { ...state.platform, location: { ...state.platform.location, locales: [], usersPerState: '', states: [ ...action.payload.data.s ] } }
      }
    case 'GET_USERS_STATE':
      return {
        ...state,
        platform: { ...state.platform, location: { ...state.platform.location, usersPerLocale: '', usersPerState: action.payload.data.usersInaState, locales: [ ...action.payload.data.locations ] } }
      }
    case 'GET_USERS_LOCALE':
      return {
        ...state,
        platform: { ...state.platform, location: { ...state.platform.location, usersPerLocale: action.payload.data.usersInALocation } }
      }
    case 'SET_ALL_LOCATIONS':
      return {
        ...state,
        platform: { ...state.platform, location: { ...state.platform.location, allLocations: [...action.payload.data.locations] } }
      }

    case 'GET_RATE':
      return {
        ...state,
        platform: { ...state.platform, interestRate: action.payload.data.interestRate.interestRate }
      }

    case 'GET_ALL_BLOGS':
      return {
        ...state,
        platform: { ...state.platform, blogs: [ ...action.payload.data.blogs ] }
      }
    case 'SET_ALL_BONUSES':
      return {
        ...state,
        platform: { ...state.platform, bonuses: [ ...action.payload.data.bonuses ] }
      }
    case 'GET_DASHBOARD':
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          analytics: [
            {
              figure: formatAmount(action.payload.data.totalSignups || '0'),
              title: 'Total Sign-ups',
              color: '#347FC4'
            },
            {
              figure: formatAmount(action.payload.data.activeUsers || '0'),
              title: 'Active Users',
              color: '#A167A5'
            },
            {
              figure: formatAmount(action.payload.data.totalNumberOfCustomerReferrals || '0'),
              title: 'Customer referrals',
              color: '#D499B9'
            },
            {
              figure: formatAmount(action.payload.data.totalNumberOfInfluencerReferrals || '0'),
              title: 'Influencer referrals',
              color: '#FF6B6B'
            },
            {
              figure: `${formatAmount(action.payload.data.churnRate || '0')}%`,
              title: 'Churn-rate',
              color: '#4281A4'
            }
          ]
        }
      }
    case 'SET_DASHBOARD_DEPOSITS':
      // console.log(action.payload.data)
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          deposits: {
            ...action.payload
          }
        }
      }

    case 'SET_DASHBOARD_WITHDRAWALS':
      // console.log(action.payload.data)
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          withdrawals: {
            ...action.payload.data
          }
        }
      }
    case 'SET_CONTINGENT':
      // console.log(action.payload.data)
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          totalContigentLiability: action.payload

        }
      }

    case 'SET_BARCHART':
      return {
        ...state,
        chartData: {
          ...state.chartData,
          barData: {
            labels: action.payload.labels,
            datasets: [
              {
                label: 'User Growth',
                data: action.payload.data,
                backgroundColor: '#347FC4'
              }
            ]
          }
        }
      }
    case 'SET_LINECHART':
      return {
        ...state,
        chartData: {
          ...state.chartData,
          lineData: {
            labels: action.payload.labels,
            datasets: [
              {
                label: 'Contributions',
                data: action.payload.data,
                backgroundColor: 'rgba(255, 255, 255, .1)',
                borderColor: '#F8634B'
              }
            ]
          }
        }
      }
    case 'SET_CONTINGENT_LINECHART':
      // console.log(action.payload)
      return {
        ...state,
        chartData: {
          ...state.chartData,
          contingentLineData: {
            labels: action.payload.labels,
            datasets: [
              {
                label: 'Contingent Liabilites',
                data: action.payload.data,
                backgroundColor: 'rgba(255, 255, 255, .1)',
                borderColor: '#10BE1E'
              }
            ]
          }
        }
      }

    case 'SET_DOUGHNUT':
      // console.log(action.payload)
      return {
        ...state,
        chartData: {
          ...state.chartData,
          doughnutData: {
            ...state.chartData.doughnutData,
            datasets: [{
              data: action.payload.data,
              backgroundColor: [
                '#2A2A72',
                '#FFA400',
                '#009FFD'
              ],
              hoverBackgroundColor: [
                '#2A2A72',
                '#FFA400',
                '#009FFD'
              ]
            }]
          }
        }
      }
    case 'SET_PIE':
      return {
        ...state,
        chartData: {
          ...state.chartData,
          pieData: {
            ...state.chartData.pieData,
            datasets: [{
              data: [...action.payload.data],
              backgroundColor: [
                '#2A2A72',
                '#FFA400',
                '#009FFD'
              ],
              hoverBackgroundColor: [
                '#2A2A72',
                '#FFA400',
                '#009FFD'
              ]
            }]
          }
        }
      }

    case 'GET_IMAGES':
      return {
        ...state,
        gallery: [ ...action.payload.data.galleries ]
      }
    default:
      return state
  }
}
export default adminReducer
