import React, { Component } from 'react'
import Users from '../../Components/Users/Users'
import { withRouter } from 'react-router-dom'
import { Wrapper, SubWrapperAlt } from '../../theme/style/styles'
import { Helmet } from 'react-helmet'
import { CSVButton } from '../../Components/Button'
import Tabhead from '../../Components/TabHead'
import GoldSpinner from '../../Components/Tools/GoldSpinner'
import {CardHeader} from '../../theme/style/typeface'
import { ExportToCsv } from 'export-to-csv'
class index extends Component {
 state ={
   users:[],
   month:'',
   year:'',
   spinner: true,
   cancel:false
 }
  handleGetSelectUser = userId =>{
    localStorage.setItem('selecteduserId', userId)
    this.props.handleFetchUser(userId)
    .then(res=>{
     this.props.history.push('/users/userprofile')
     this.props.handleFetchUserActivity(userId)
      this.props.handleFetchUserHistory(userId)
      this.props.handleFetchUserBonus(userId)
    })
  }
handleSetDate = e =>{
this.setState({[e.target.name]: e.target.value},()=> this.handleFetchUsers())
}

handleFetchUsers =()=>{
  const date = new Date()
  let month = date.toLocaleString('default', { month: 'long' })
  let year = date.getFullYear()
  if (this.state.month !== ''){
    month = this.state.month
  }
  if(this.state.year !== ''){
    year = this.state.year
  }
this.setState({month, year, spinner: true, cancel:true})
  setTimeout(() => {
    this.props.handleFetchUserByParams(month, year)
    .then(res=>{
      setTimeout(() => {
        this.setState({spinner: false})
      }, 5000);
    })
  }, 1000);
}

handleCancel =()=>{
  this.props.handleFetchAllUser()
  .then(res=>{
    this.setState({spinner: false, cancel:false, month:'', year:''})
  })
}
  componentDidMount () {
    this.props.handleFetchAllUser()
    setTimeout(() => {
            this.setState({spinner: false})
          }, 5000);
  }
  render () {
    let spinner = this.state.spinner ? <GoldSpinner/> : <CardHeader style={{justifyContent:'center'}} padding='0'>
      No Users Found
      </CardHeader>
const options = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: true,
  title: 'Users CSV',
  filename:'users',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  // headers: ['Column 1', 'Column 2', 'Column 3', 'Column 4', 'Column 5' ] // <-- Won't work with useKeysAsHeaders present!
}
const csvExporter = new ExportToCsv(options)
    return (
      <Wrapper>
        <Helmet>
          <meta charSet='utf-8' />
          <title> Users || First Homes</title>
        </Helmet>
        <SubWrapperAlt>
          <Tabhead
            title='Users'
            showDate
            handleSetDate={this.handleSetDate}
            inputs={this.state}
            handleCancel={this.handleCancel}
          />
          <a href="https://analytics.firsthomesafrica.com/api/admin/data" width='20px' height='16px' margin='0 8px'>

          <CSVButton
          // onClick={()=> csvExporter.generateCsv(this.props.users)}
          // onClick={()=> this.fetchNewData()}
          />
          </a>
          {this.props.users && this.props.users.length > 0 ? 
          <Users handleGetSelectUser={this.handleGetSelectUser} users={this.props.users}  /> 
          : spinner }
        </SubWrapperAlt>
      </Wrapper>

    )
  }
}
export default withRouter(index)
