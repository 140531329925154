import Gallery from '../../Pages/Gallery'
import { connect } from 'react-redux'
import { handleFetchAllImages, handleGalleryUploadImage, handleDeleteImage } from '../../Store/Actions'

/**
 * here we handle passing redux to our component and export
 */
const mapStateToProps = state => ({
  Gallery: state.Admin.gallery
})

const mapDispatchToProps = dispatch => ({
  handleFetchAllImages () {
    return dispatch(handleFetchAllImages())
  },
  handleDeleteImage (id) {
    return dispatch(handleDeleteImage(id))
  },
  handleGalleryUploadImage (picture) {
    return dispatch(handleGalleryUploadImage(picture))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)
