import React from 'react'
import * as Page from '../../styles'
import { ActivityCard } from '../../../Card'
const Users = ({ insights }) => {
  const { oldest, youngest, averageAge, totalNumberOfCustomerReferrals, totalNumberOfInfluencerReferrals } = insights
  return (
    <Page.Details style={{ margin: '0 auto' }}>
      <Page.Wrapper alt>
        <Page.ContainerAlt>
          <Page.Container className='activity-container'>
            <ActivityCard title='Oldest user on the platform' number={`${oldest || 0} years`} hide />
            <ActivityCard title='Youngest user on the platform' number={` ${youngest || 0} years`} hide />
            <ActivityCard title='Average age of users' number={` ${averageAge || 0} years`} hide />
            <ActivityCard title='Lowest qualification' number='OND.' hide />
            <ActivityCard title='Highest qualification' number='MSc.' hide />
            <ActivityCard title='Total number of influencer referrals' number={totalNumberOfInfluencerReferrals || '0'} hide />
            <ActivityCard title='Total number of customer referrals' number={totalNumberOfCustomerReferrals || 0} hide />
          </Page.Container>
        </Page.ContainerAlt>
      </Page.Wrapper>
    </Page.Details>
  )
}
export default Users
