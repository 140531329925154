import Dashboard from '../../Pages/Dashboard'
import { connect } from 'react-redux'
import { handleDashboardAnalytics,
  handleDashboardBarChartData,
  handleDashboardLineChartData,
  handleDashboardDoughnutChartData,
  handleDashboardPieChartData,
  handleDashboardDoughnutChartWithData,
  handleDashboardPieChartWithData,
  handleDashboardContingentLineChartData } from '../../Store/Actions'

/**
 * here we handle passing redux to our component and export
 */
const mapStateToProps = state => ({
  analytics: state.Admin.dashboard.analytics,
  Dashboard: state.Admin.dashboard,
  ChartData: state.Admin.chartData
})

const mapDispatchToProp = dispatch => ({
  handleDashboardAnalytics () {
    return dispatch(handleDashboardAnalytics())
  },
  handleDashboardBarChartData () {
    return dispatch(handleDashboardBarChartData())
  },
  handleDashboardLineChartData () {
    return dispatch(handleDashboardLineChartData())
  },
  handleDashboardDoughnutChartData () {
    return dispatch(handleDashboardDoughnutChartData())
  },
  handleDashboardPieChartData () {
    return dispatch(handleDashboardPieChartData())
  },
  handleDashboardDoughnutChartWithData (month, year) {
    return dispatch(handleDashboardDoughnutChartWithData(month, year))
  },
  handleDashboardPieChartWithData (month, year) {
    return dispatch(handleDashboardPieChartWithData(month, year))
  },
  handleDashboardContingentLineChartData(){
    return dispatch(handleDashboardContingentLineChartData())
  }
})
export default connect(mapStateToProps, mapDispatchToProp)(Dashboard)
